import styled, {css} from 'styled-components';
import { Link } from 'react-router-dom';

import { ErrorLabel, StyledLoadingButtonLoading, StyledButton } from '../_styles/forms';

export const errorCss = css`
  border-color:red !important;
  border-width:2px;
  visibility:visible;
`;

export const BasicInformationTitle = styled.div`
    text-align: center;
    font-family: Proxima Nova;
    font-style: normal;
    font-size: 26px;
    line-height: 40px;
    color: #12171A;
    margin-top: 40px;
    margin-bottom: 30px;
`;

export const StyledTerms = styled.div`

  display: flex;
  align-items: center;
  justify-content:flex-start;
  margin-top: 20px;
  margin-left: 0px;

  label {
    margin-left: 10px;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    text-align: right;
    color: #273238;

    a,a:visited,a:active,a:focus{
     color: #6B8A99;
     text-decoration: none !important;
     outline: none !important;
    }
    a:hover{
      cursor:pointer;
    }

  }

`;

export const StyledSpacing = styled.div`
  font-family: Proxima Nova Bold;
  font-style: normal;
  font-size: 13px;
  line-height: 19px;
  text-align: left;
  letter-spacing: 0.9px;
  color: #405159;
  margin: 20px 0;
  font-size: 22px;
  font-family: Proxima Nova;
`;


export const StyledLoginButton = styled.button`
  background: #44C5A6;
  border-radius: 5px;
  width: 100%;
  height: 48px;
  text-transform: uppercase;
  font-family: Proxima Nova;
  font-style: normal;
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.9px;
  margin-top: 10px;
  color: #000000;
  border:none;
  &:not(:disabled):hover{
       opacity:0.8;
       cursor:pointer;
     }

  &:disabled{
    background: #C4C4C4;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
    filter: drop-shadow(0px 2px 14px rgba(39, 50, 56, 0.5));
    color: #273238;

    &:hover{
      cursor:default;
    }
  }

`;

export const StyledFacebookButton = styled.div`
  width: 100%;
  position: relative;
  &:hover{
    cursor:  ${props => props.isDisabled ? 'default' : 'pointer'};
    opacity:  ${props => props.isDisabled ? 'opacity:inherit' : '0.8'};
  }
  ${StyledLoadingButtonLoading}{
    position:absolute;
  }
  .FacebookButtonLogin{
     font-family: Helvetica, Arial, sans-serif;
     display:flex;
     justify-content:center;
     align-items: center;
     height: 48px;
     padding: 0 15px;
     background-color:#3C5A99;  /* #4080ff;
     border: 1px solid #4b7bdb;*/
     box-shadow: 0 1px 1px rgb(0 0 0 / 14%);
     border-radius: 5px;
     width: 100%;
     font-size: 13px;
     text-transform: uppercase;
     color: #fff;
     font-weight: 600;
     margin-top:0;
     i{
       font-size: 20px;
       margin-right: 10px;
     }
  }
`;

export const StyledGoogleButton = styled.div`
  font-family: 'Roboto';
  display:flex;
  justify-content:center;
  align-items: center;
  height: 48px;
  padding: 0 15px;
  background-color: white;
  width: 100%;
  border:none;
  border-radius: 5px;
  border: thin solid #888;
  margin-top: 0px;

  &:not(:disabled):hover{
    opacity:0.8;
    cursor:pointer;
  }
  &:disabled{
    &:hover{
      cursor:default;
    }
  }

  img{
    width:25px;
    height:25px;
  }

  span{
    font-family: Roboto;
    margin-left: 15px;
    font-size: 13px;
    line-height: 19px;
    text-transform: uppercase;
    color: #444;
  }

`;


export const StyledInput = styled.input`
  background: #F9F9F9;
  border: 1px solid #C7D3D9;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 15px 15px;
  width:100%;
  ${props => props.addCSS}
`;

export const StyledInputWithOverlay = styled.div`
  position:relative;

  > div{
    position:absolute;
    display:flex;
    align-items:center;
    top: 0;
    bottom: 0;
    right: 0;
    height: 50px;
    padding: 15px 0;
    padding-right: 10px;


    &:hover{
      cursor:pointer;
      opacity: 0.8;
    }
  }
`;

export const StyledInputLayout = styled.div`
  font-family: Proxima Nova Bold;
  font-style: normal;
  font-size: 13px;
  line-height: 19px;

  letter-spacing: 0.9px;

  color: #12171A;
`;

export const StyledFormMessage = styled.div`
  height: 45px;

`;

export const StyledFormRow = styled.div`
  display:flex;
  &.Two{
    > div {
      width: calc(50% - 10px);
      margin-left:20px;

      &:first-child{
        margin-left: 0;
      }
    }
  }

`;

export const StyledDayPickerIconContainer = styled.div`
    margin: 2px 0; /* border issues */
    padding-top: 6px;
    padding-right: 8px;
    position:absolute;
    right: 5px;
`;

export const StyledDayPickerInput = styled.div`
  width:100%;
  position:relative;
  display:flex;
  align-items:center;


  input{
    border: 1px solid #36454D;
    box-sizing: border-box;
    border-radius: 100px;
    height: 40px;
    width: 100%;
    text-align:center;
    font-family: Proxima Nova Semibold;
    font-style: normal;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.9px;
  }

  .DayPickerInput-OverlayWrapper{
    width:100%;
  }

  .DayPickerInput-Months{
    width:100%;
  }

`;

export const StyledDayPickerInputWrapper = styled.div`
    .DayPickerInput{
      width: 100%;
    }
    input {
      border-color: ${props => props.emptyBorder ? '#C7D3D9' : '#36454D'};
      ${props =>
          props.isError ? 'border-color:red;border-width:2px;' : ''
      }
    }
    svg {
        fill: ${props => props.emptyBorder ? '#C7D3D9' : '#36454D'};
      }

`;

export const StyledLabel = styled.div`
  font-family: Proxima Nova Semibold;
  font-style: normal;
  font-size: 15px;
  line-height: 21px;
  color: #12171A;
  margin-top: 20px;
  margin-bottom: 10px;
`;

export const RequestTypeField = styled.div`
  padding: 10px 30px;
  border-radius: 50px;
  display:flex;
  justify-content: center;
  align-items: center;
  color: #12171A;
  border: 1px solid #36454D;
  font-size: 13px;
  font-family: Proxima Nova;
`;


export const FormRadioLabel = styled.label`
  margin-bottom: 8px;
  margin-right: 8px;
  width: calc(33% - 4px);
  input[type="radio"]{
    display:none;
  }

  input[type="radio"]+${RequestTypeField}{
    background-color: transparent;
    color: #12171A;
  }

  input[type="radio"]:checked+${RequestTypeField}{
    background-color: #12171A;
    color: white;

  }




  &:hover{
    cursor: pointer;
  }

`;

export const FormRadioGroup = styled.div`
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  ${FormRadioLabel}:last-child{
    margin-right: 0;
  }
`;


export const StyledRegisterButton = styled(StyledButton)`
  background: #273238;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  width: 100%;
  height: 48px;
  text-transform: uppercase;
  font-family: Proxima Nova Bold;
  font-style: normal;
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.9px;
  margin-top: 40px;
  color: #FFFFFF;
  border:none;
  margin-bottom: 20px;
  &:hover{
       opacity:0.8;
       cursor:pointer;
     }

`;


export const StyledExtraDescriptionLink = styled(Link)`
    font-family: Proxima Nova;
    font-style: normal;
    font-size: 13px;
    line-height: 19px;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.9px;
    color: #405159;
    width: 100%;
   color: inherit;
   text-decoration: none !important;
   outline: none !important;

  &:visited,&:active{
   color: inherit;
   text-decoration: none !important;
   outline: none !important;
  }

  span{
    font-family: Proxima Nova Bold;
    font-style: normal;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.9px;
    color: #405159;
    margin-left: 5px;
  }
`;

export const StyledExtraDescription = styled.div`
  display:flex;
  margin-top: 20px;
`;

export const StyledOnboardingImage = styled.div`
  padding: 25px 0;
  margin-top: 50px;
  text-align:center;
  img {
    width: 200px;
  }
`;

export const StyledCompanyLogo = styled.div`
  font-family: Proxima Nova Semibold;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 3.18px;
  color: #405159;

`;

export const Divider = styled.div`
  /*border-bottom: 1px solid #DADADA;*/
  margin: 10px 4%;
  border-left: 2px solid #E6E6E6;
  height: 200px;
  width: 1px;
  background-color: #E6E6E6;
`;

export const StyledRegistrationSignUpColumn = styled.div`

  width: 60%;
  padding-top: 20px;

  ${ErrorLabel}{
    margin: 5px;
    height: unset;
    min-height:15px;
    line-height: unset;
    font-weight: normal;
    font-family: Proxima Nova Semibold;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const StyledRegistrationSignUpWrapper = styled.div`
  width: 100%;
  display:flex;
  align-items: flex-start;
  justify-content: center;
`;

export const Wrapper = styled.div`
  width: 360px;
  display: ${props => props.isShowing ? 'block' : 'none'};
`;

export const StyledActivationMessage = styled.div`
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 21px;
  text-align: left;
  color: #12171A;
`;

export const StyledActivationWrapper = styled.div`
  display:flex;
  height: 100%;
  overflow-y: auto;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  padding-top: 80px;
  padding-bottom: 70px;

  ${StyledActivationMessage}{
    margin: 40px 0;
  }
  form{
    width:100%;
  }

  width: 400px;
  padding: 30px;
  @media (max-width: 768px) {
      width: 100%;
      padding: 0;
    }
`;

export const StyledCountrySelection = styled.div`
  display:flex;
  flex-direction:column;
  align-items:center;

  ${FormRadioGroup}{
    width: 300px;
    display:flex;
    flex-direction: column;
    font-family: Proxima Nova Semibold;
    ${FormRadioLabel}{
      width: 100%;
      margin: 15px 0px;


      input[type="radio"]:checked+${RequestTypeField}{
        box-shadow: 0px 0px 15px rgba(0,0,0,0.05);
      }

      > div{
        font-size: 16px;
        padding-top: 12px;
        padding-bottom: 12px;
      }

    }
  }
`;

export const StyledResetWrapper = styled(StyledActivationWrapper)`
  overflow-y: auto;
`;

export const StyledIntialActions = styled.div`
  display:flex;
  justify-content:center;
    ${StyledRegisterButton}{
      width: 300px;
    }
`;

export const InitialWrapper = styled(Wrapper)`
  padding-top: 50px;
  width: 400px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;


export const BasicInformationWrapper = styled(Wrapper)`
  width: 600px;
  padding-top: 50px;

  > ${StyledSpacing} {
    text-align:center;
    margin-top: 50px;
  }

  @media (max-width: 768px) {
      width: 100%;
    }
`;

export const PageWrapper = styled.div`
  display:flex;
  height: ${props => props?.height ? props.height : '100vh'};
  overflow-y: scroll;
  justify-content: center;
  align-items: flex-start;
  background-color: white;
  margin-left:-100px;
  padding-top: 80px;
  padding-bottom: 70px;

  @media (max-width: 768px) {
      margin-left:0;
    }

`;
