import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { MembershipPage } from './MembershipPage';
import { TreatmentListPage } from './TreatmentListPage';
import { MembershipBenefitsPage } from './MembershipBenefitsPage';
import { useAuth } from '../../Authentication/useAuth';
import { PrivateRoute } from '../../Authentication/PrivateRoute';

export const Routes = () => {
    return (
        <Switch>
            <PrivateRoute exact path="/membership/treatments/:profileId" >
                <TreatmentListPage />
            </PrivateRoute>
            <PrivateRoute exact path="/membership/benefits/:profileId" >
                <MembershipBenefitsPage />
            </PrivateRoute>
            <PrivateRoute exact path="/membership" >
                <MembershipPage />
            </PrivateRoute>
        </Switch>
    )
}