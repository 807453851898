import React from 'react';
import  { Icon } from '@chakra-ui/react';

export const MinusIcon = ({width=20, height=20, color="#2E3D44", ...props}) => {

  return(
    <Icon width={`${width}px`} height={`${height}px`} viewBox="0 0 20 20" color={color}  {...props}>
        <path d="M17 10H3" stroke="currentColor"/>
    </Icon>
  )
}
