import React from 'react';
import { Box, Button, Flex, Center, HStack, VStack, Text, Heading, Spinner, useDisclosure, 
    Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalHeader, ModalBody, SimpleGrid, Image
 } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { useMembershipDetails } from '../api/getUserMembership';
import { FormattedMessage, useIntl } from 'react-intl';
import { ArrowRightIcon } from '../../_images/icons/ArrowRightIcon';


import logo from '../../_images/logoPDHPrimary.png';
import { ModalFooter } from 'react-bootstrap';
import { PaymentNeeded } from '../components/PaymentNeeded';
import { ChevronRightIcon } from '../../_images/icons/ChevronRightIcon';


const formatDate = (date) => {
    if (!date) return '';
    return new Intl.DateTimeFormat(navigator.language, {
        year: "numeric",
        month: "short",
        day: "numeric"
    }).format(new Date(date));
};

function getAge(date) {
    if (!date) return '';
    const birthDate = new Date(date);
    const today = new Date();

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    // Adjust if the current date is before the birth date in the current year
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }

    return age;
}


const TEXT_LABEL_PROPS = {
    color: '#7B8887',
    mr: ['0px', '0px'],
    pb:['10px', 0],
    letterSpacing: '0.6px',
}

const TEXT_VALUE_PROPS = {
    letterSpacing: '0.7px',
}

const StatusTag = ({status}) => {
    const { formatMessage } = useIntl();
    const statusText = {
        'quoted': formatMessage(
            {
                id: 'membership.status.quoted',
                defaultMessage: 'Quoted'
            }
        ),
        'future': formatMessage(
            {
                id: 'membership.status.future',
                defaultMessage: 'Future'
            }
        ),
        'active': formatMessage(
            {
                id: 'membership.status.active',
                defaultMessage: 'Active'
            }
        ),
        'canceled': formatMessage(
            {
                id: 'membership.status.canceled',
                defaultMessage: 'Canceled'
            }
        ),
        'expired': formatMessage(
            {
                id: 'membership.status.expired',
                defaultMessage: 'Expired'
            }
        ),
    }
    // eslint-disable-next-line no-unused-vars
    const boxProps = {
        'quoted': {
            bg: '#F5A623',
            color: '#FFF'
        },
        'future': {
            bg: '#44C5A6',
            color: '#FFF'
        },
        'active': {
            bg: '#44C5A6',
            color: '#FFF'
        },
        'canceled': {
            bg: '#D0021B',
            color: '#FFF'
        },
        'expired': {
            bg: '#D0021B',
            color: '#FFF'
        }
    }
    return (
        <Box px={'12px'} py={'5px'} borderRadius={'20px'} {...boxProps[status]}>
            <Text letterSpacing={'0.7px'}>
                {statusText[status]}
            </Text>
        </Box>
    )
}


const MemberItem = ({member}) => {
    return (
        <HStack align={'center'} spacing={'20px'}>
            <Text color={''}>
                {
                    member?.is_default ? (
                        <FormattedMessage 
                            id={'membershipPage.members.list.primary.label'}
                            defaultMessage={'Primary'}
                        />
                    )
                    : (
                        <FormattedMessage 
                            id={'membershipPage.members.list.dependent.label'}
                            defaultMessage={'Dep.'}
                        />
                    )
                }
            </Text>
            <Text fontFamily={'Proxima Nova Semibold'}>
                <FormattedMessage 
                    id={'format.fullName'}
                    defaultMessage={'{givenName} {familyName}'}
                    values={{
                        givenName: member?.first_name,
                        familyName: member?.last_name
                    }}
                />
            </Text>
        </HStack>
    )
}

const GenderText = ({gender}) => {
    const genderUpper = (gender || '').toUpperCase()
    switch(genderUpper){
        case 'MALE':
            return (
                <FormattedMessage 
                    id={'membershipPage.getQuote.dependentForm.gender.male'}
                    defaultMessage={'Male'}
                />
            )
        case 'FEMALE':
            return (
                <FormattedMessage
                    id={'membershipPage.getQuote.dependentForm.gender.female'}
                    defaultMessage='Female'
                />
            )
        default:
            return (
                <></>
            )
    }
}

const MembershipCard = ({membership, membershipNumber, status}) => {
    const history = useHistory();
    // id
    // name
    // date of birth
    // gender
    const handleCardClick = () => {
        history.push('/membership/benefits/' + membership.unique_id)
    }

    return (
        <Box
            minWidth={['100%', '400px']} 
            key={membership.unique_id}
            borderRadius={'20px'}
            bg={'#EDF3F6'}
            p={['20px', '30px']}
            py={['20px', '30px']}
            _hover={{cursor: 'pointer'}}
        >
            <SimpleGrid columns={2} spacing={'10px'} templateColumns={"2fr 7fr"}>
                <Box>
                    <Image src={logo} alt="logo" w={'45px'} />
                </Box>
                <Flex justify={'flex-end'} align={'flex-start'}> 
                    <StatusTag status={status} />
                </Flex>
            </SimpleGrid>
            <SimpleGrid mt={['10px']} columns={1} templateColumns={"1fr"}>
                <Box>
                    <Flex>
                        {membership?.is_default ? (
                        <Text {...TEXT_LABEL_PROPS}>
                            <FormattedMessage
                                id="membershipSearch.label.isPrimary.label"
                                defaultMessage='Primary'
                            />
                        </Text>
                        ): (
                            <Text {...TEXT_LABEL_PROPS}>
                                <FormattedMessage
                                    id="membershipSearch.label.isDependent.label"
                                    defaultMessage='Dependent'
                                />
                            </Text>
                        )}
                    </Flex>
                    <Flex>
                        <Text {...TEXT_LABEL_PROPS}>
                            <FormattedMessage
                                id="membershipSearch.label.fullName"
                                defaultMessage='Name'
                            />
                        </Text>
                        <Text {...TEXT_LABEL_PROPS} mr={['5px']}>
                            {": "}
                        </Text>
                        <Text {...TEXT_VALUE_PROPS} >
                            <FormattedMessage
                                id={'format.fullName'}
                                defaultMessage={'{givenName} {familyName}'}
                                values={{ givenName: membership.first_name, familyName: membership.last_name }}
                            />
                        </Text>
                    </Flex>
                    <Flex>
                        <Text {...TEXT_LABEL_PROPS}>
                            <FormattedMessage
                                id="membershipSearch.label.dateOfBirth"
                                defaultMessage='Date of Birth'
                            />
                        </Text>
                        <Text {...TEXT_LABEL_PROPS} mr={['5px']}>
                            {": "}
                        </Text>
                        <Text {...TEXT_VALUE_PROPS} >
                            {membership?.date_of_birth ? formatDate(membership.date_of_birth) : '-'}
                            {membership?.date_of_birth ? ` ( ${getAge(membership.date_of_birth)} )` : ''}
                        </Text>
                    </Flex>
                    <Flex>
                        <Text {...TEXT_LABEL_PROPS}>
                            <FormattedMessage
                                id="membershipSearch.label.gender"
                                defaultMessage='Gender'
                            />
                        </Text>
                        <Text {...TEXT_LABEL_PROPS} mr={['5px']}>
                            {": "}
                        </Text>
                        <Text {...TEXT_VALUE_PROPS} >
                            <GenderText gender={membership.gender} />
                        </Text>
                    </Flex>
                    <Flex w={['full']} justify={['flex-end']} py={['10px']}>
                        <Text {...TEXT_VALUE_PROPS} color={'#6B8A99'}>
                            {membershipNumber}
                        </Text>
                    </Flex>
                </Box>
            </SimpleGrid>
            <SimpleGrid mt={['10px']} columns={1} templateColumns={"1fr"}>
                <Box>
                    <Flex justify={['center']}>
                        <Button variant={'outline'} rightIcon={<ChevronRightIcon />} onClick={handleCardClick}>
                            <FormattedMessage
                                id="membershipPage.card.viewDetails"
                                defaultMessage='View Benefits'
                            />
                        </Button>
                    </Flex>
                </Box>
            </SimpleGrid>
        </Box>
    )
}

const MembershipList = ({members, onSelectedMember}) => {
    return (
        <VStack spacing={'20px'} align={'flex-start'} py={['0px','20px']}>
            <Text color={'#7B8887'}>
                <FormattedMessage
                    id="membershipSearch.modal.subtitle"
                    defaultMessage='Select a member to view the membership card'
                />
            </Text>
            {
                members.map((member) => (
                        <HStack align={'center'} spacing={'20px'}>
                            <Text color={''}>
                                {
                                    member?.is_default ? (
                                        <FormattedMessage 
                                            id={'membershipPage.members.list.primary.label'}
                                            defaultMessage={'Primary'}
                                        />
                                    )
                                    : (
                                        <FormattedMessage 
                                            id={'membershipPage.members.list.dependent.label'}
                                            defaultMessage={'Dep.'}
                                        />
                                    )
                                }
                            </Text>
                            <Text fontFamily={'Proxima Nova Semibold'}>
                                <FormattedMessage 
                                    id={'format.fullName'}
                                    defaultMessage={'{givenName} {familyName}'}
                                    values={{
                                        givenName: member?.first_name,
                                        familyName: member?.last_name
                                    }}
                                />
                            </Text>
                            <Button 
                                variant={'link'} 
                                size={'sm'} 
                                onClick={() => onSelectedMember(member.unique_id)} 
                                rightIcon={<ArrowRightIcon />}
                            >
                                <FormattedMessage
                                    id="membershipSearch.modal.viewCard"
                                    defaultMessage='View Card'
                                />
                            </Button>
                        </HStack>
                ))
            }
        </VStack>
    )
}


const MembershipCardModal = ({isOpen, onClose, members, membershipNumber}) => {
    const [selectedMember, setSelectedMember] = React.useState(null);
    console.log("selkectedMember ", selectedMember)
    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'xl'} isCentered>
            <ModalOverlay />
            <ModalContent py={['10px']}>
                <ModalHeader>
                    <Center>
                    <Flex justify={'space-between'} align={'center'}>
                        <Text>
                            <FormattedMessage
                                id="membershipPage.modal.title"
                                defaultMessage='Membership Details'
                            />
                        </Text>
                    </Flex>
                    </Center>
                    <ModalCloseButton />
                </ModalHeader>
                <ModalBody px={['15px', '20px']}>
                    {
                        selectedMember ? (
                            <>
                                <MembershipCard 
                                    membershipNumber={membershipNumber} 
                                    membership={members.find((item) => item?.unique_id == selectedMember)} 
                                    onClose={() => setSelectedMember(null)} 
                                />
                                <Flex w={['full']} justify={'flex-end'} py={['10px']}>
                                    <Button mt={['10px']} variant={'outline'} onClick={() => setSelectedMember(null)}>
                                        <FormattedMessage
                                            id="membershipPage.modal.card.close"
                                            defaultMessage='Close'
                                        />
                                    </Button>
                                </Flex>
                            </>
                        ) : (
                            <>
                                <MembershipList members={members} onSelectedMember={setSelectedMember} />
                                <Flex w={['full']} justify={'flex-end'} py={['10px']}>
                                    <Button mt={['10px']} variant={'outline'} onClick={onClose}>
                                        <FormattedMessage
                                            id="membershipPage.modal.card.close"
                                            defaultMessage='Close'
                                        />
                                    </Button>
                                </Flex>
                            </>
                        )
                    }
                    
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export const MembershipPage = () => {
    const { data, isLoading, isFetching} = useMembershipDetails();

    if (isLoading || isFetching){
        return (
            <Box>
                <Flex w={['full']} align={'center'} justify={'center'} p={10}>
                    <Spinner 
                        size='xl'
                        thickness='4px'
                        speed='0.65s' 
                        color='#44C5A6'
                    />
                </Flex>
            </Box>
        )
    }
    const primaryMember = data?.membership?.members?.find((item) => item.is_default) || {};
    const dependents = data?.membership?.members?.filter((item) => !item.is_default) || [];

    return (
        <Box mt={['80px', '100px']} px={['20px', '50px']} pb={['50px', 0]}>

            <PaymentNeeded />
            <Heading >
                <FormattedMessage 
                    id={'membershipPage.title'}
                    defaultMessage={'Membership'}
                />
            </Heading>
            <Box py={['10px', '20px']} pr={[0, '40px']} minW={['100%', '150px']}>
                <Text fontSize={18} fontFamily={'Proxima Nova Semibold'}>
                    <FormattedMessage 
                        id={'membershipPage.details.subtitle'}
                        defaultMessage={'Members'}
                    />
                </Text>
            </Box>
            <Flex justify={['center', 'flex-start']} mt={['10px', '0px']} direction={['column']}>
                <VStack align={['flex-start']} w={'full'} spacing={'15px'}>
                    {
                        primaryMember && (
                            <MembershipCard 
                                membership={primaryMember} 
                                membershipNumber={data?.membership?.membership_number}
                                status={data?.membership?.status}
                            />
                        )
                    }
                    { dependents.length > 0 && (
                        <>
                            {
                                dependents.map((member, index) => (
                                    <MembershipCard 
                                        key={index} 
                                        membership={member} 
                                        membershipNumber={data?.membership?.membership_number}
                                        status={data?.membership?.status}
                                    />
                                ))
                            }
                        </>
                    )}
                </VStack>
            </Flex>

        </Box>
    )
}