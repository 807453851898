import React, {useEffect, useState} from 'react';
import { PAGE_URLS, MEMBERSHIP_PAGE_URLS } from '../_config';
import { createSelector } from 'reselect';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation, useParams, useHistory } from 'react-router-dom';
import cn from 'classnames';

import { checkIfSidebar, isMembershipUser } from '../_helpers/utils';

// selectors
import {
  getIsProfileLoaded, getIsProfileLoading, getProfile, getIsLoggedInFlag
}
from '../Authentication/_selectors';
import {
  getTotalUnreadMessages
} from '../UnreadMessageCount/_selectors';
import { getTotalRequestNotifications } from '../Notifications/_selectors';

import { StyledSideNav } from './styles';
import { useAuth } from '../Authentication/useAuth';


const getPathName = createSelector(
  useLocation,
  (loc) => {
    return loc.pathname;
  }
)

const RequestNavItem = ({item, on}) => {
  const unread = useSelector(getTotalRequestNotifications);

  return (
    <Link to={item.url} className={cn({'on' : on }, item.key)}>
      <div className={'iconContainer'}>
        <img src={on ? item.iconOn : item.iconOff} alt="" />
        {(unread > 0) && <div className={'notificationDot'}></div>}
      </div>
    </Link>
  )
}

const ChatNavItem = ({item, on}) => {
  const unread = useSelector(getTotalUnreadMessages);

  const chatClick = () => {
    if (window.ChannelIO){
      window.ChannelIO('openChat');
    }
  }
  // <Link className={cn({'on' : on })} style={{textDecoration: 'none'}} to={item.url} onClick={chatClick}>

  return (
    <Link className={cn({'on' : on }, item.key)} style={{textDecoration: 'none'}} to={item.url}>
      <div className={'iconContainer'}>
        <img src={item.icon} alt="" />
        {(unread > 0) && <div className={'notificationDot'}></div>}
      </div>
    </Link>
  )
}

export const SideNavItem = ({item, pathname}) => {
  let urlOn = false;

  if (pathname){
    if (item.url === "/"){
      urlOn = pathname === "/"
    } else {
      urlOn = pathname.startsWith(item.url)
    }
  }
  if (item.key == 'chats'){
    return(
      <ChatNavItem item={item} on={urlOn} />
    )
  } else if (item.key == 'requests'){
    return (
      <RequestNavItem item={item} on={urlOn} />
    )
  }
  return (
    <Link to={item.url} className={cn({'on' : urlOn, [item.key]: true })}>
      <img src={urlOn ? item.iconOn : item.iconOff} alt="" />
    </Link>
  )
}

const getNavItemsForUser = (user) => {
  if (!user){
    return []
  } else if (isMembershipUser(user)){
    return MEMBERSHIP_PAGE_URLS 
  } else {
    return PAGE_URLS
  }
}

export const SideNav = () => {
  const auth = useAuth();
  const location = useLocation();
  const isLoggedIn = useSelector(getIsLoggedInFlag)
  const [path, setPath] = useState('');
  useEffect(()=>{
    setPath(location.pathname)
  }, [location])
  const show = checkIfSidebar(path) || isLoggedIn;
  return (
    <StyledSideNav
      show={show}
      >
      {getNavItemsForUser(auth?.user?.apps).map((elem,ind) =>
        <SideNavItem key={ind} pathname={path} item={elem} />
      )}
    </StyledSideNav>
  )
}
