import { extendTheme } from '@chakra-ui/react'

export const theme = extendTheme({
    fonts: {
      heading: `'Proxima Nova', Helvetica`,
      body: `'Proxima Nova', Helvetica, sans-serif`,
    },
    colors: {
      brand: {
        500: "#273238",
      },
      progressColor: {
        50: '#ddfffa',
        100: '#b6f6ee',
        200: '#8eefe3',
        300: '#64e8d8',
        400: '#3ce1cd',
        500: '#26c8b3',
        600: '#179b8b',
        700: "#086f64",
        800: "#00443c",
        900: "#001815",
      }
    },
    components: {
        Button: {
            baseStyle: {
                borderRadius: "30px",
                textTransform: "uppercase",
                py: "12px",
                px: "20px",
                fontSize: "16px",
                fontWeight: "normal",
            },
            variants: {
                cancel: {
                    bg: '#E95252',
                    color: "#FFFFFF",
                    _hover: {
                        bg: '#C73F3F'
                    },
                    _disabled: {
                        bg: "#C7D3D9",
                        color: "#A0B1BA"
                    }
                },
                dark: {
                    bg: "#273238",
                    color: "#F7F7F7",
                },
                solid: {
                    bg: "#44C5A6",
                    color: "#405159",
                    _hover: {
                        bg: "#1EA584"
                    },
                    _disabled: {
                        bg: "#C7D3D9",
                        color: "#A0B1BA"
                    }
                },
                outlineDefault: {
                    bg: 'transparent',
                    borderColor: "#405159",
                    borderWidth: "1px",
                    color: "#405159",
                    _hover: {
                        border: "1px solid",
                        borderColor: "#405159",
                        borderWidth: "1px",
                        bg: 'transparent'
                    },
                    _disabled: {
                        borderColor: "#C7D3D9",
                        borderWidth: "1px",
                        bg: 'transparent',
                        color: "#A0B1BA",
                    }
                }
            },
            sizes: {
                circle: {
                  // Example: 40px circle
                    h: '34px',
                    w: '34px',
                    minWidth: '34px',
                    borderRadius: 'full',
                    fontSize: 'sm',
                    px: 0, // no extra horizontal padding
                },
                md: {
                    px: "20px",
                    py: "12px",
                    minWidth: "130px"
                }
            }
        },
        FormLabel: {
          baseStyle: {
            fontFamily: 'Proxima Nova',
            fontWeight: 'normal',
            fontSize: '16px',
            color: '#7A8F99',
            marginBottom: '5px',
            textTransform: 'uppercase',
          },
        },
        Checkbox: {
            baseStyle: {
                control: {
                    borderColor: '#405159',
                    _checked: {
                        bg: '#405159',
                        borderColor: '#405159',
                    
                    }
                }
            }
        },
        Input: {
            variants: {
                outline: {
                    field: {
                        border: "1px solid #405159",
                        borderColor:  "#405159",
                        color: "#3E5159",
                        _placeholder: {
                            color: "#7A8F99"
                        }
                    }
                }
            }
        },

        Radio: {
            baseStyle: {
                control: {
                    borderColor: '#E2E8F0', // Border color
                    borderRadius: '50%',
                    _hover: {
                        borderColor: '#405159', // Border color on hover
                    },
                    _checked: {
                        bg: '#405159', // Background color when checked
                        borderColor: '#405159', // Border color when checked
                    },
                },
            },
        },
        ModalHeader: {
            baseStyle: {
                fontSize: "18px",
                letterSpacing: "1px",
                textTransform: "uppercase",
                fontWeight: "normal",
                boxShadow: '0px -2px 15px rgba(0, 0, 0, 0.08)',
                filter: 'drop-shadow(0px -2px 15px rgba(0, 0, 0, 0.08))',
                color: '#12171A',
                alignItems: 'center',
                height: '70px',
                fontWeight: 'normal',
                zIndex: 2,
            },
        }
    }
})