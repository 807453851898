import create from 'zustand';

export const STEP_PAGES = {
    PRIMARY: 'member_list',
    REVIEW: 'review',
}

export const useApplyStore = create((set) => ({
  page: STEP_PAGES.PRIMARY,
  quoteData: null,
  nonce: null,
    setPage: (page) => set({ page }),
    setNonce: (nonce) => set({ nonce }),
    setQuoteData: (data) => set({ quoteData: data}),
    setQuoteDataEmail: (email) => set({ quoteData: { email } }),
}));