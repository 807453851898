import { API_URLS } from "../../_config";
import { authHeader, handleResponse } from "../../_helpers";

export function fetchMembership(){
  const requestUrl = API_URLS.fetchMembership;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  };
  let url = requestUrl.url;
  return fetch(url, requestOptions).then(handleResponse);

}
export function fetchMembershipPaymentStatus(){
    const requestUrl = API_URLS.fetchMembershipPaymentStatus;
    const requestOptions = {
      method: requestUrl.method,
      headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };
    let url = requestUrl.url;
    return fetch(url, requestOptions).then(handleResponse);
  
  }

export function fetchMembershipBenefits(){
    const requestUrl = API_URLS.fetchMembershipBenefits;
    const requestOptions = {
      method: requestUrl.method,
      headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };
    let url = requestUrl.url;
    return fetch(url, requestOptions).then(handleResponse);
  
  }

  export function fetchMembershipTreatmentPlans(queryParams){
    const requestUrl = API_URLS.fetchMembershipTreatmentPlans;
    const requestOptions = {
      method: requestUrl.method,
      headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };
    let url = requestUrl.url + '?' + queryParams;
    return fetch(url, requestOptions).then(handleResponse);
  }


  export function fetchMembershipInvoices(queryParams){
    const requestUrl = API_URLS.fetchMembershipInvoices;
    const requestOptions = {
      method: requestUrl.method,
      headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };
    let url = requestUrl.url + '?' + queryParams;
    return fetch(url, requestOptions).then(handleResponse);
  }