import React from 'react';
import { Modal, ModalHeader, ModalOverlay, ModalContent, ModalBody, ModalCloseButton, useDisclosure, Box, Text, Stack, Center } from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useCountry } from 'src/_libs/useCountry';

export const CountryModal = ({isOpen=false}) => {
    const countryData = useCountry();
    const { isOpen : isModalOpen, onClose } = useDisclosure({ isOpen: !countryData?.country ? true : isOpen });

    return (
        <Modal isOpen={isModalOpen} onClose={onClose} isCentered >
            <ModalOverlay />
            <ModalContent minW={['100%', '700px']}>
                <ModalHeader
                    boxShadow={'0px -2px 15px 0px rgba(0, 0, 0, 0.08)'}
                    backgroundColor={'#fff'}
                    borderTopRadius={'10px'}
                >
                    <Center>
                    <Text
                        color={'#12171A'}
                        fontSize={'16px'}
                    >
                        <FormattedMessage
                            id={'membershipPage.getQuote.countrySelector.title'}
                            defaultMessage={'Select Country'}
                        />
                        </Text>
                    </Center>
                    <ModalCloseButton />
                </ModalHeader>
                <ModalBody>
                    <Box >
                        <CountrySelector 
                            selectedCountry={countryData?.country}
                            onSelect={countryData?.updateCountry}
                        />
                    </Box>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export const CountryLabel = ({country}) => {
    const countryLower = country?.toLowerCase();
    switch (countryLower) {
        case 'in':
            return "\u{1F1EE}\u{1F1F3}" + ' ' + 'India'; // 🇮🇳
        case 'th':
            return "\u{1F1F9}\u{1F1ED}" + ' ' + 'Thailand'; // 
        default:
            return ""
    }
}

export const CountrySelector = ({ selectedCountry, onSelect }) => {
    const { formatMessage } = useIntl();
    const countryOptions = [
        {
          value: "IN",
          label: formatMessage({id: 'countryName.IN', defaultMessage: 'India'}),
          flag: "\u{1F1EE}\u{1F1F3}", // 🇮🇳
        },
        {
          value: "TH",
          label: formatMessage({id: 'countryName.TH', defaultMessage: 'Thailand'}),
          flag: "\u{1F1F9}\u{1F1ED}", // 🇹🇭
        },
      ];

    const handleSelect = (value) => {
        onSelect(value);
    }

    return (
        <Stack spacing={3} direction="column" maxW="sm" margin="40px auto">
          {countryOptions.map((country) => {
            const isSelected = country.value === selectedCountry;
    
            return (
              <Box
                key={country.value}
                as="button"
                // Basic styling
                p={3}
                borderWidth="1px"
                borderRadius="md"
                display="flex"
                alignItems="center"
                cursor="pointer"
                // Conditional styling if selected
                bg={isSelected ? "blue.50" : "white"}
                borderColor={isSelected ? "blue.300" : "gray.200"}
                onClick={() => handleSelect(country.value)}
              >
                <Box as="span" fontSize="lg" mr={2}>
                  {country.flag}
                </Box>
                <Box as="span" fontWeight={isSelected ? "bold" : "normal"}>
                  {country.label}
                </Box>
              </Box>
            );
          })}
        </Stack>
    )
}