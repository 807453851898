import React from 'react';
import { Input, VStack, InputGroup, InputLeftAddon } from '@chakra-ui/react';
import { AsYouType } from 'libphonenumber-js'
import { getCountryCallingCode } from 'react-phone-number-input';

export const PhoneInput = ({ 
    placeholder, 
    value,
    isInvalid, 
    onChange, 
    defaultCountry='US',
    isDisabled=false 
}) => {
    const countryCallingCode = defaultCountry ? getCountryCallingCode(defaultCountry) : '';

    const showFormattedValue = (value) => {
        // auto add +countrcode to value, so format should still be local
        if (defaultCountry) {
            // remove the country calling code from value
            value = value.replace(`+${countryCallingCode}`, '');
            return new AsYouType(defaultCountry).input(value);
        } else {
            return new AsYouType().input(value);
        }
    }

    const handleChange = (e) => {
        onChange(`+${countryCallingCode}`+ e.target.value);
    };
  
    return (
        <VStack spacing={4}>
            <InputGroup>
                {/*<InputLeftAddon children={defaultCountry ? `+ ${getCountryCallingCode(defaultCountry)}` : ''} />      */}
                <Input 
                    type='tel'
                    placeholder={placeholder}
                    value={showFormattedValue(value)} 
                    onChange={handleChange} 
                    isInvalid={isInvalid} 
                    isDisabled={isDisabled} 
                />
            </InputGroup>
        </VStack>
    )
}