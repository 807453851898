import styled from 'styled-components';
import { Link } from 'react-router-dom';
import media from './media';
export const AppWrapper = styled.div`
`;

export const FlexColumn = styled.div`
  display:flex;
  flex-direction: column;
`;

export const FlexRow = styled.div`
  display:flex;
`;

export const StyledMain = styled.div`
  display: grid;
  grid-template-columns: ${({showSidebar}) => (showSidebar)? '80px 1fr' : '0px 1fr' };
  height: 100vh;
  background-color: #fff;
  grid-template-rows: 1fr; /* Default: one row */

  @media (max-width: 768px) {
    grid-template-columns: 1fr; /* Collapse to a single column on mobile screens */
    grid-template-rows: ${({ showSidebar }) => (showSidebar ? "1fr 80px" : "1fr")}; /* Add a row for the SideNav */
  }
`;

export const StyledContentSection = styled.div`
  > section {
    padding:20px;
    box-shadow:0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius:10px;

    > h2 {
      font-size:15px;
      line-height:21px;
      letter-spacing:1px;
      font-family:Proxima Nova Semibold;
    }
  }
`; //.content .content__section--wrap

export const StyledContentTitle = styled.div`
  font-size:30px;
  font-family: Proxima Nova;
  font-weight: 400;
  line-height:30px;
  margin-bottom:20px;
`; //.content .content__title

export const InformationalPageContent = styled.div`
  padding: 100px;
  ${media.tablet`
    padding: 25px;
    padding-top: 70px;
  `};
  strong {
    font-family: Proxima Nova Bold;
  }
  em {
    font-style: italic
      font-family: Proxima Nova Bold;
  }
  h1 {
    font-size: 28px;
    margin: 20px 0;
    font-family: Proxima Nova Bold;

  }
  h2 {
    font-size: 22px;
    margin: 15px 0;
    font-family: Proxima Nova Bold;

  }
  p {
    margin-bottom: 10px;
    line-height: 20px;
  }

  ul {
     list-style-type: disc;
     list-style-position: initial;
     margin-left: 4em;

     ${media.tablet`
       margin-left: 1em;
     `};
  }
  ol {
     list-style-type: circle;
     list-style-position: initial;
     margin-left: 4em;
    ${media.tablet`
      margin-left: 1em;
    `};
  }

  ol[type='a']{
       list-style-type: lower-alpha;
       list-style-position: initial;
    }


  li{
    margin-bottom: 10px;
    margin-left: 15px;

  }

`;

export const StyledContent = styled.div`
  padding: 100px 0 20px 60px;

  @media (max-width: 768px) {
    padding: 100px 0px 100px 0px;
  }
`; //.content

export const Wrapper = styled.div`
  background-color:#EFEFEF;
  display: flex;
  flex-direction: column;
  align-items:center;
`;

export const UnstyledLink = styled(Link)`
  color: inherit;

  &:hover{
    color: inherit;
  }
`;

export const InitialsAvatar = styled.div`
  width: 65px;
  height: 65px;
  border-radius: 50%;
  background: #405159;
  font-family: Proxima Nova;
  font-size: 25px;
  color: #FFFFFF;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Button = styled.button`
  outline: none;
  color: white;
  opacity: 1;
  background: transparent;
  background-color: transparent;
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-image: none;
  padding: 0;
  text-shadow: 0px 0px 4px black;
  background-position: center center;
  background-repeat: no-repeat;
  pointer-events: auto;
  cursor: pointer;
  z-index: 2;
  border: none;
  padding: 5px 15px;
  color: white;

  &:disabled{
    opacity: 0.7;
  }

  &:hover{
    &:enabled{
      cursor: pointer;
      opacity: 0.7;
    }
    &:not(enabled){
      cursor:default;
      cursor-events:none;
    }
  }
`;
