

import { useQuery } from 'react-query';
import { queryConfig } from '../../_helpers/react-query';

import { fetchMembershipTreatmentPlans as getQuery } from './services';
import { QUERY_KEY_NAMES } from './constants';

export const QUERY_KEY_NAME = QUERY_KEY_NAMES.GET_MEMBER_TREATMENTPLAN_COLLECTION;

const newQueryConfig = {
    ...queryConfig,
    queries: {
        ...queryConfig.queries,
    }
}

export const queryRequest = async (queryParams) => {
    let paramsObj = {}
    for (const [key, val] of Object.entries(queryParams)){
      if (val){
        paramsObj[key] = val
      }
    }
    const searchParams = new URLSearchParams(paramsObj)
    return getQuery(searchParams)
}

export const useUserMembershipTreatmentCollection = ({profile, offset=0, pg_sz=10}) => {
    return useQuery({
        ...newQueryConfig,
        enable: !!profile,
        queryKey: [QUERY_KEY_NAME, {profile, offset, pg_sz}],
        queryFn: () => queryRequest({profile, offset, pg_sz}), // in brackets because im parsing the object in queryRequest
      });
}