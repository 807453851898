import create from 'zustand';

export const STEP_PAGES = {
    PRIMARY: 'init',
    MEMBER_LIST: 'member_list',
    AREA: 'location',
    PLAN_LIST: 'plan_list',
}

export const useQuotesStore = create((set) => ({
  page: STEP_PAGES.PRIMARY,
  plan: null,
  dependents: null,
    setPlan: (plan) => set({ plan }),
    setDependents: (dependents) => set({ dependents }),
    setPhoneNumber: (phoneNumber) => set({ phoneNumber }),
    setPage: (page) => set({ page }),
}));