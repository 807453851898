import React from 'react';
import { Flex, Box, Text, Tabs, TabList, Tab } from '@chakra-ui/react';
import { CheckIcon } from 'src/_images/icons/CheckIcon';
import { useIntl } from 'react-intl';
import { useLocation, useHistory } from 'react-router-dom';
import { ContainerComponent } from './common';

function getStepIndex(urlPath) {
    if (urlPath.startsWith('/preonb/apply')) {
        return 1;
    } else if (urlPath.startsWith('/preonb/payment')) {
        return 2;   
    } else if (urlPath.startsWith('/preonb/quotes')) {
        return 0; 
    } else if (urlPath.startsWith('/preonb')) {
        return 0;
    } 
    return 0;
}

export const MemberFormTabs = ({pageId}) => {
    const { formatMessage } = useIntl();
    const location = useLocation();
    const history = useHistory();
    const tabs = [
        { label: formatMessage({id: 'membershipPage.getQuote.steps.1.label', defaultMessage: 'Quote'}) },
        { label: formatMessage({id: 'membershipPage.getQuote.steps.2.label', defaultMessage: 'Apply'}) },
        { label: formatMessage({id: 'membershipPage.getQuote.steps.3.label', defaultMessage: 'Payment'}) },
        ];
    
    const activeColor = '#527499';    // Blue 500
    const defaultColor = '#E2E8F0';   // Gray 300
    const completedColor = '#44C5A6'; // 44C5A6
    const circleSize = 30;
    const currentStep = getStepIndex(location.pathname);

    const handleTabClick = (index) => {
        switch (index) {
            case 0:
                history.replace('/preonb/quotes', {from: location})
                return;
            case 1:
                history.replace('/preonb/apply', {from: location})
                return;
            default:
              history.replace('/preonb/quotes', {from: location})
              return;
        }
    }

    return (
        <ContainerComponent>
          <Tabs isFitted index={currentStep}>
              <TabList>
                  {tabs.map((tab, index) => {
                      const isActive = index === currentStep;
                      const isCompleted = index < currentStep;
                      const circleBg = isActive ? activeColor : isCompleted ? completedColor : defaultColor;
                      const circleColor = (isActive || isCompleted) ? '#FFFFFF' : '#000000';
                      const textColor = (isActive || isCompleted) ? 'black' : 'gray.500';
                      const borderColor = isCompleted ? completedColor : isActive ?  activeColor: 'gray.100';
                      return (
                          <Tab key={index}
                            sx={{
                                borderColor: borderColor
                            }}
                            isSelected={isActive}
                            onClick={isCompleted ? (index) => handleTabClick(index) : null}
                          >
                              <Flex 
                                  key={index} 
                                  direction={["column"]}
                                  flex="1"
                                  align={['center']}
                              >
                                  <Box position="relative" zIndex="1">
                                      <Flex
                                          justify={"center"}
                                          align="center"
                                          bg={circleBg}
                                          color={circleColor}
                                          borderRadius="50%"
                                          w={`${circleSize}px`}
                                          h={`${circleSize}px`}
                                          fontWeight="bold"
                                      >
                                          {isCompleted ? <CheckIcon color={'white'} /> : index + 1}
                                      </Flex>
                                  </Box>
                                  <Text 
                                      mt={2} 
                                      fontSize="sm" 
                                      fontWeight={isActive ? 'bold' : 'normal'} 
                                      color={textColor}
                                  >
                                      {tab.label}
                                  </Text>
                              </Flex>
                          </Tab>
                      )})}
              </TabList>
          </Tabs>
        </ContainerComponent>
    )  
    }

export const MemberFormSteps = ({pageId}) => {
    const { formatMessage } = useIntl();
    // Fixed hex colors
    const activeColor = '#527499';    // Blue 500
    const defaultColor = '#E2E8F0';   // Gray 300
    const completedColor = '#44C5A6'; // 44C5A6

    const steps = [
        { label: formatMessage({id: 'membershipPage.getQuote.steps.1.label', defaultMessage: 'Quote'}) },
        { label: formatMessage({id: 'membershipPage.getQuote.steps.2.label', defaultMessage: 'Apply'}) },
        { label: formatMessage({id: 'membershipPage.getQuote.steps.3.label', defaultMessage: 'Payment'}) },
      ];


    const currentStep = getStepIndex(pageId);

    const circleSize = 30
  
    return (
      <Flex justify="space-between" align="center" w="100%" position="relative" mt={[4]}>
        {steps.map((step, index) => {
          const isActive = index === currentStep;
          const isCompleted = index < currentStep;
          const circleBg = isActive ? activeColor : isCompleted ? completedColor : defaultColor;
          const circleColor = (isActive || isCompleted) ? '#FFFFFF' : '#000000';
          const textColor = (isActive || isCompleted) ? 'black' : 'gray.500';
          return (
            <Flex 
                key={index} 
                direction="column" 
                align={index == steps.length - 1 ? "flex-end" : index == 0 ? "flex-start" : "center"} 
                flex="1"
            >
              <Box position="relative" zIndex="1">
                <Flex
                  justify={"center"}
                  align="center"
                  bg={circleBg}
                  color={circleColor}
                  borderRadius="50%"
                  w={`${circleSize}px`}
                  h={`${circleSize}px`}
                  fontWeight="bold"
                >
                    {isCompleted ? <CheckIcon color={'white'} /> : index + 1}
                </Flex>
              </Box>
              <Text 
                mt={2} 
                fontSize="sm" 
                fontWeight={isActive ? 'bold' : 'normal'} 
                color={textColor}
              >
                {step.label}
              </Text>
            </Flex>
          );
        })}
  
        {/* Connecting lines */}
        <Box
          position="absolute"
          top={`${circleSize / 2}px`}
          left="0"
          right="0"
          zIndex="0"
        >
          <Flex justify="space-between" align="center">
            {steps.map((_, index) => {
              if (index === 0) return null; // No line before first step
              const isCurrent = index === currentStep;
              const isCompleted = index < currentStep;
              return (
                <Box 
                  key={index}
                  flex="1"
                  height="2px"
                 justifySelf={index == steps.length - 2 ? "flex-end" : index == 0 ? "flex-start" : "center"} 
                  bg={isCurrent ? activeColor : isCompleted ? completedColor : defaultColor}
                  mx={[0]}
                />
              );
            })}
          </Flex>
        </Box>
      </Flex>
    );

}
