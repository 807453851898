import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Box, Container, Stack, HStack, Text, Flex, Button, Radio, RadioGroup } from '@chakra-ui/react';
import { PAYMENT_STEP_PAGES } from '../state/payment';
import { useSearchParamsObject } from 'src/_libs/useQueryParams';
import { useCreatePayment } from '../api/createPayment';
import { ContentContainer, ButtonStack } from './common';

export const PaymentStepReview = ({ quoteData, paymentUrl, setCurrentStep, isShowing}) => {
    const { formatMessage } = useIntl();

    const handlePrev = () => {
        setCurrentStep(PAYMENT_STEP_PAGES.PRIMARY, 'back');
    }

    const handleClickNext = () => {
        window.location.href = paymentUrl
    }

    return (
        <Box display={isShowing ? 'box' : 'none'}>
            <ContentContainer>
                {/* Plan Details */}
                <Box mt={2}>
                    <Stack spacing={2}>
                        <Text fontSize={[18]} mb={2}>
                            <FormattedMessage 
                                id="membershipPage.payment.paymentReview.text" 
                                defaultMessage="Please follow our payment provider's directions on next page for your account. We will also send you an email." 
                            />
                        </Text>
                        <Text fontSize="md" fontWeight="bold">
                            {quoteData?.email}
                        </Text>
                    </Stack>
                </Box>
            </ContentContainer>
            <Box mt={[6]}>
                <ButtonStack
                    handleClickPrevious={handlePrev}
                    handleClickNext={handleClickNext}
                    isDisabledNext={!paymentUrl}
                    textPrevious={formatMessage({id: 'membershipPage.payment.review.previous', defaultMessage: 'Previous'})}
                    textNext={formatMessage({id: 'membershipPage.payment.review.next', defaultMessage: 'Go to Payment'})}
                />
            </Box>
        </Box>
    )
}