import React from 'react';

export const DoubleChevronArrowDown = (props) => {
  const height = props?.height || 12;
  const width = props?.width || 12;
  const color = props?.color || '#405159';
  return(
    <svg width={width} height={height} color={color} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.43678 1.28173L6.21839 6.28173L11 1.28173" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M1.43678 6L6.21839 11L11 6" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}
