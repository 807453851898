import { API_DATE_FORMAT } from 'src/_config';
import { mapValues } from 'lodash';
import moment from 'moment';
import { isValidPhoneNumber } from 'libphonenumber-js'
import { defineMessages } from 'react-intl';


const errorMessages = defineMessages({
    invalid_phone_number: {
        id: 'forms.error.invalid_phone_number',
        defaultMessage: 'Invalid phone number'
    },
    value_required: {
        id: 'forms.error.value_required',
        defaultMessage: 'Required'
    },
    invalid_number: {
        id: 'forms.error.invalid_number',
        defaultMessage: 'Invalid number'
    },
    invalid_number_min: {
        id: 'forms.error.invalid_number_min',
        defaultMessage: 'Should be greater than {min}'
    }
})
/*
 * Password Creation
 */
export function generatePassword() {
    const lowerCaseLetters = "abcdefghijklmnopqrstuvwxyz";
    const upperCaseLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const specialCharacters = "!@#$%^&|?";
    const minLength = 8;
    const maxLength = 12;
    const length = Math.floor(Math.random() * (maxLength - minLength + 1)) + minLength;

    const getRandomChar = (chars) => chars[Math.floor(Math.random() * chars.length)];

    let password = '';
    // Ensure one character from each required set
    password += getRandomChar(lowerCaseLetters);
    password += getRandomChar(upperCaseLetters);
    password += getRandomChar(specialCharacters);

    // Fill the remaining 5 characters randomly from all sets
    const allCharacters = lowerCaseLetters + upperCaseLetters + specialCharacters;
    for (let i = 3; i < length; i++) {
        password += getRandomChar(allCharacters);
    }

    // Shuffle the password to ensure randomness of character positions
    password = password.split('').sort(() => 0.5 - Math.random()).join('');
    return password;
}

export const validateConfirmPassword = (password, confirmPassword) => {
    if (password !== confirmPassword) {
      return "Passwords do not match.";
    }
    return undefined;
  };
  
export const validatePassword = (password, email) => {
    const errors = [];
  
    if (password == null || password === "") {
        return "Password is required.";
    }

    // 1. Minimum Length
    if (password.length < 8) {
      errors.push("Password must be at least 8 characters long.");
    }
  
    // 2. Common Passwords
    const commonPasswords = ["password", "123456", "qwerty", "abc123"];
    if (commonPasswords.includes(password.toLowerCase())) {
      errors.push("Password is too common.");
    }
  
    // 3. Numeric Passwords
    if (/^\d+$/.test(password)) {
      errors.push("Password cannot be entirely numeric.");
    }
  
    // 4. Similarity to User Email
    if (email) {
      const emailParts = email.split(/[@._-]/).filter(Boolean);
      for (const part of emailParts) {
        if (password.toLowerCase().includes(part.toLowerCase())) {
          errors.push("Password is too similar to your email.");
          break;
        }
      }
    }
  
    return errors.length ? errors.join(" ") : undefined;
  };
/*
 * Single value conversion
 */
function convertSingleToAPIValue(value){
    if (Array.isArray(value)) {
        return value.map(item => {
            return convertSingleToAPIValue(item);
        }); // Recursive call for each item if obj is an array
    }
    if (moment.isMoment(value)){
        return value.format(API_DATE_FORMAT)
    }
    if (moment.isDate(value)){
        return moment(value).format(API_DATE_FORMAT)
    }
    if (value == null){
        return value
    }
    if (Object.prototype.hasOwnProperty.call(value, "value")){
        return value.value;
    } 
    if (Object.prototype.hasOwnProperty.call(value, "id")){
        return value.id
    }
    return value // return the object as is
}

/*
 * Convert values to API values
 */

export function convertToAPIValues(values){
    return mapValues(values, (obj) => {
        return convertSingleToAPIValue(obj)
    })
}

/**
 * 
 * @param {*} meta from <Field /> component meta final-form
 * @returns 
 */
export function isFinalFormMetaInvalid(meta, error=false){
return error ? true : meta?.touched && (meta?.error || meta?.submitError );
}

/**
 * 
 * @param {*} meta from <Field /> component meta in final-form
 * @returns 
 */
export function getFinalFormMetaError(meta, error=null){
    return meta?.error || meta?.submitError || error;
}

export const hasErrorMessage = (error) => {
    // check if error message exists in errorMessages
    return errorMessages[error] ? errorMessages[error] : null;
}

/**
 * 
 * @param {*} value validate in final-form.
 * @returns undefined if value is valid, error message if value is invalid.
 */
export const mustBePhoneNumber = (value, ...props) => {
    if (!value) {
        return undefined;
    }
    return isValidPhoneNumber(value) ? undefined : props?.message ? props?.message : 'invalid_phone_number';
}

/**
 * 
 * @param {*} value validate in final-form.
 * @returns undefined if value is valid, error message if value is invalid.
 */
export const required = (value, ...props) =>
    !value || (value && value.length == 0) ? props?.message ? props?.message : 'value_required' : undefined;

/**
 * 
 * @param {*} value validate in final-form.
 * @returns undefined if value is valid, error message if value is invalid.
 */
export const mustBeNumber = (value, ...props) => (isNaN(value) ? props?.message ? props?.message : errorMessages.invalidNumber : undefined);

/**
 * 
 * @param {*} value validate in final-form.
 * @returns undefined if value is valid, error message if value is invalid.
 */
export const minValue = (min) => (value, ...props) =>
    isNaN(value) || value >= min ? undefined : props?.message ? props?.message : errorMessages.invalidNumberMin;

/**
 * 
 * @param {*} validators args of validators to be checked in the form
 * @returns list of errors if found, or undefined
 */
export const composeValidators =
(...validators) =>
(value) =>
    validators.reduce((error, validator) => error || validator(value), undefined);
  