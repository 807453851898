import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from './useAuth';
import { Flex, Spinner } from '@chakra-ui/react';
import storage from '../_helpers/storage';

export const PrivateRoute = ({ children, ...rest }) => {
    const auth = useAuth();

    /*
    const storageUser = storage.get('user');

    console.log("auth: ", auth)

    if (!storageUser?.token){
      return <Redirect to={'/login'} />
    }*/

   // TODO redirect to /preonb

    const loggedInBefore = storage.getLoggedIn();
    return (
            <Route 
                {...rest} 
                render={props => {
                if (auth.isLoading){
                    return (
                    <Flex justify={'center'} w={'100vw'} h={'100vh'} align={'center'} pt={[11, 20]}>
                        <Spinner size="xl" />
                    </Flex>
                    )
                } else if (!auth?.user) {
                    if (loggedInBefore != null){
                        return <Redirect to={'/login'} />
                    } else {
                        return <Redirect to={'/preonb'} />
                    }
                } else if (rest?.appName){
                    // the user has to have the app in their list of apps
                    if ((auth.user?.apps || []).some(item => (rest.appName || []).includes(item))){
                        return children;
                    } else {
                        return <Redirect to={'/'} />
                    }
                } else if (auth.user){
                    return children
                } else {
                    if (loggedInBefore != null){
                        return <Redirect to={'/login'} />
                    } else {
                        return <Redirect to={'/preonb'} />
                    }
                }
                }}
            />   
    )
}