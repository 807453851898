import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Modal, ModalBody, ModalContent, ModalFooter, ModalCloseButton, ModalHeader, ModalOverlay, Text, Button, Flex,
    FormControl, FormLabel, Input, FormErrorMessage, VStack
} from "@chakra-ui/react";
import { useRequestSimpleCode } from "../api/requestCode";
import { useConfirmSimpleCode } from "../api/confirmCode";

export const CodeConfirmModal = ({email, phone, nonce, isOpen, onClose, onSuccess, country, type="email"}) => {
    const { formatMessage } = useIntl();
    const [ emailCode, setEmailCode ] = React.useState('');
    const [ isError, setIsError ] = React.useState(false);
    const requestCodeMutation = useRequestSimpleCode();
    const confirmCodeMutation = useConfirmSimpleCode();

    const onResendCode = () => {
        setIsError(false);
        const numOfSeconds = 15;
        // if submittedAt timestamp is les than 10- seconds ago, then don't resend code
        if (requestCodeMutation.submittedAt){
            const currentTime = new Date().getTime();
            const timeDiff = currentTime - requestCodeMutation.submittedAt;
            if (timeDiff < numOfSeconds * 1000) {
                return;
            }
        }
        requestCodeMutation.mutate({email, nonce, country });
        // start a countdown of 10 seconds to shwo on the UT
    }

    const handleSubmit = async (values) => {
        setIsError(false);
        try {
            await  confirmCodeMutation.mutateAsync({nonce, code: emailCode, email, type});
            onSuccess();
        } catch (error) {
            setIsError(true);
            console.error('Error confirming code', error);
        }
}

    return (

        <Modal isOpen={isOpen} onClose={onClose}  closeOnOverlayClick={false} isCentered>
            <ModalOverlay />
                <ModalContent p={['20px 30px']}>
                    <ModalHeader>
                        <Text>
                            <FormattedMessage
                                id={'passwordRegisterPage.codeModal.title'}
                                defaultMessage={'Enter Code'}
                            />
                        </Text>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                            <Text pb={[4]}>
                                <FormattedMessage
                                    id={'passwordRegisterPage.codeModal.description'}
                                    defaultMessage={'We have sent a code to your email {email}. Please enter the code below.'}
                                    values={{email}}
                                />
                            </Text>
                            <VStack>
                                <FormControl id="codeEmail" isInvalid={isError}>
                                    <FormLabel>
                                        <FormattedMessage
                                            id={'passwordRegisterPage.code.label'}
                                            defaultMessage={'Code'}
                                        />
                                    </FormLabel>
                                    <Input 
                                        placeholder={formatMessage({id: 'passwordRegisterPage.code.placeholder', defaultMessage: 'Code'})}
                                        value={emailCode}
                                        onChange={(e) => setEmailCode(e.target.value)}
                                        isInvalid={isError}
                                    />
                                    {
                                        isError && (
                                            <FormErrorMessage>
                                                <FormattedMessage
                                                    id={'passwordRegisterPage.codeModal.code.error'}
                                                    defaultMessage={'Invalid code. Please try again.'}
                                                />
                                            </FormErrorMessage>
                                        )
                                    }
                                </FormControl>
                            </VStack>
                            <Flex pt={[2]} alignItems={['center']}>
                                <Text mr={[3]} fontSize={[14]} color={'#405159'}>
                                    <FormattedMessage
                                        id={'passwordRegisterPage.codeModal.resend.text'}
                                        defaultMessage={'Didn\'t receive the code?'}
                                    />
                                </Text>
                                <Button 
                                    type='button'
                                    size={['sm']} 
                                    variant='link' 
                                    onClick={onResendCode}
                                    isDisabled={confirmCodeMutation.isLoading || requestCodeMutation.isLoading}
                                >
                                    <FormattedMessage
                                        id={'passwordRegisterPage.codeModal.resend.link'}
                                        defaultMessage={'Resend'}
                                    />
                                </Button>
                            </Flex>
                    </ModalBody>
                    <ModalFooter justify={['flex-end']}>
                        <Button type='button' onClick={handleSubmit} isLoading={confirmCodeMutation.isLoading || requestCodeMutation.isLoading }>
                            <FormattedMessage
                                id={'passwordRegisterPage.codeModal.submit'}
                                defaultMessage={'Submit'}
                            />
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
    )
}