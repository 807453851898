import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Container, Stack, HStack, Text, Flex, Button, Radio, RadioGroup } from '@chakra-ui/react';
import CenteredSpinner from 'src/_components/CenteredSpinner';
import { PAYMENT_STEP_PAGES, usePaymentStore } from '../state/payment';
import { useSearchParamsObject } from 'src/_libs/useQueryParams';
import { useCreatePayment } from '../api/createPayment';
import { ContentContainer, ButtonStack } from './common';

const PaymentItemRadio = ({children, value}) => {
    return (
        <Flex as={'label'} justify="space-between" align="center" border="1px solid #E2E8F0" borderRadius="md" p={4}>
            <HStack spacing={4} w={['full']}>
                <Radio 
                    value={value} 
                />
                <Flex w={['full']} grow={[1]} justify={['space-between']}>
                    {children}
                </Flex>
            </HStack>
        </Flex>
    )

}
    

export const ThPaymentForm = ({setPaymentUrl, handlePrev}) => {
    const [ paymentMethod, setPaymentMethod ] = React.useState(null);
    const { memberQuote } = useSearchParamsObject();
    const { formatMessage } = useIntl();
    const createPaymentMutation = useCreatePayment();


    const onPaymentMethodChange = async (value) => {
        setPaymentMethod(value);
    }

    const handlePaymentClick = async () => {
        try{
            const resp = await createPaymentMutation.mutateAsync({
                payment_method: paymentMethod,
                subscription_member: memberQuote
            });
            setPaymentUrl(resp.checkout_url);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <Box>
            <ContentContainer>
                <Stack direction={['column', 'row']} spacing={['10px', '20px']}>
                    <RadioGroup onChange={onPaymentMethodChange} value={paymentMethod}>
                            <Stack spacing={4}>
                                <PaymentItemRadio value={'credit_card'}>
                                    <Text  >
                                        <FormattedMessage

                                            id={'membershipPage.getQuote.payment.cardOption'}
                                            defaultMessage={'Pay with Card'}
                                        />
                                    </Text>
                                </PaymentItemRadio>
                                <PaymentItemRadio value={'qr_code'}>
                                    <Text >
                                        <FormattedMessage
                                            id={'membershipPage.getQuote.payment.qrCode'}
                                            defaultMessage={'Pay with QR Code'}
                                        />
                                    </Text>
                                </PaymentItemRadio>
                            </Stack>
                    </RadioGroup>
                </Stack>
            </ContentContainer>
            <Box mt={[6]}>
                <ButtonStack
                    handleClickPrevious={handlePrev}
                    isDisabledNext={!paymentMethod || !memberQuote}
                    handleClickNext={handlePaymentClick}
                    isLoadingNext={createPaymentMutation.isLoading}
                    textPrevious={formatMessage({id: 'membershipPage.payment.paymentmethod.previous', defaultMessage: 'Previous'})}
                    textNext={formatMessage({id: 'membershipPage.payment.paymentmethod.next', defaultMessage: 'Next'})}
                />
            </Box>
        </Box>
    )
}

export const PaymentStepPaymentMethod = ({country, setPaymentUrl, isShowing}) => {
    const history = useHistory();
    const location = useLocation();

    const handlePrev = () => {
        history.replace(`/preonb/apply`, {from: location})
    }

    if (country === 'TH') {
        return (
            <Box display={isShowing ? 'box' : 'none'}>
                <ThPaymentForm 
                    setPaymentUrl={setPaymentUrl}
                    handlePrev={handlePrev}
                />
            </Box>
        )
    } else {
        return (<Box></Box>)
    }
}