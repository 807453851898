import React from 'react';
import { useHistory } from 'react-router-dom';
import { Stack, Box, Badge, Text, Divider, VStack, HStack, Button, Select } from '@chakra-ui/react';
import { Field } from 'react-final-form';
import { useSubscriptionPlanCollection } from '../api/getSubscriptionPlans';
import CenteredSpinner from 'src/_components/CenteredSpinner';
import { formatCurrencyAmount, getCurrencySymbol } from 'src/Membership/utils';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSearchParamsObject } from 'src/_libs/useQueryParams';
import { useCountry } from 'src/_libs/useCountry';
import { useQuotesStore, STEP_PAGES } from '../state/quotes';
import { ContentContainer, ButtonStack } from './common';
import { required } from 'src/_helpers/forms';


const getBillingCycleText = (billing_cycle, formatMessage) => {
    switch (billing_cycle) {
        case 'annual':
            return formatMessage({ id: 'membershipPage.plansList.annual', defaultMessage: 'year' });
        case 'monthly':
            return formatMessage({ id: 'membershipPage.plansList.monthly', defaultMessage: 'month' });
        default:
            return '';
    }
}

const PlanPricingCard = ({plan, onSelect, isSelected, billing_cycle="annual"}) => {
    const { formatMessage } = useIntl();
    const price = (plan.prices || [])?.find(price => price.billing_cycle === billing_cycle);

    const handleClick = () => {
        onSelect(plan.code);
    }

    return (
        <Box
          borderRadius="md"
          padding="20px"
          boxShadow={isSelected ? 'sm' : 'md'}
          border={isSelected ? '2px solid #527499' : '2px solid transparent'}
          textAlign="center"
          backgroundColor="white"
        >
          <Badge bg={'#527499'} mb={2} color={'white'}>
            {plan.name}
          </Badge>
          <Text fontSize="2xl" fontWeight="bold">
            {`${getCurrencySymbol(plan.currency)} ${formatCurrencyAmount(price.amount)} / ${getBillingCycleText(price.billing_cycle, formatMessage)}`}
          </Text>
            <Text color="gray.500" fontSize={[20]} mb={4}>
                {plan.description}
            </Text>
            <Divider mb={4} />
            <VStack spacing={2} align="stretch">
                {(plan.features || []).map((feature, idx) => (
                <HStack key={idx} justifyContent="flex-start">
                    <Text color="gray.600" fontSize="sm">
                        {feature}
                    </Text>
                </HStack>
                ))}
            </VStack>
          <Button
            mt={2}
            colorScheme="teal"
            size="md"
            onClick={() => handleClick(price.code)}
          >
            <FormattedMessage id="membershipPage.plansList.choosePlan" defaultMessage="Choose" />
            &nbsp;
            {plan.name}
          </Button>
        </Box>
    )
}

const PlanField = ({plans, inputValue, onChange}) => {

    const handleChange = (value) => {
        onChange(value)
    }

    return (
        <Stack direction={['column', 'row']} spacing={['20px']} mt={4}>
            {(plans || []).map((plan) => (
                <PlanPricingCard key={plan?.code} plan={plan} isSelected={plan.code == inputValue} onSelect={handleChange} />
            ))}
        </Stack>
    )

}

export const QuoteStepPlanPricing = ({ submitting, values, handleSubmit, setCurrentStep, isShowing=false}) => {
    const { country: countryCode, updateCountry, isLoading: isCountryLoading } = useCountry();
    const { data, isLoading, isFetching } = useSubscriptionPlanCollection({ countryCode });
    const { country : queryCountry } = useSearchParamsObject();
    const { formatMessage } = useIntl();

    // Override country if query parameter exists
    React.useEffect(() => {
        if (queryCountry) {
            updateCountry(queryCountry); // Update the country context
        }
    }, [queryCountry, updateCountry]);

    if (isCountryLoading || isLoading || isFetching) {
        return (<Box><CenteredSpinner /></Box>)
    }

    const handleNext = () => {
        handleSubmit()
    }

    const handlePrev = () => {
        setCurrentStep(STEP_PAGES.AREA, 'back');
    }


    return (
        <Box display={isShowing ? 'box' : 'none'}>
            <ContentContainer>
                <Field name="plan_code" validate={required}>
                    {({input, meta}) => (
                            <PlanField 
                                plans={data?.plans}
                                inputValue={input.value}
                                onChange={input.onChange}
                            />
                        )
                    }
                </Field>
            </ContentContainer>
            <Box mt={[6]}>
                <ButtonStack
                    handleClickPrevious={handlePrev}
                    handleClickNext={handleNext}
                    isDisabledNext={!values.plan_code}
                    isLoadingNext={submitting}
                    textPrevious={formatMessage({id: 'membershipPage.getQuote.pricing.previous', defaultMessage: 'Previous'})}
                    textNext={formatMessage({id: 'membershipPage.getQuote.pricing.next', defaultMessage: 'Next'})}
                />
            </Box>
        </Box>
    );
}