import { useState, useEffect } from "react";
import { getMany } from "idb-keyval";

const useIDBKeyVal = (keys) => {
  const [values, setValues] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchValues = async () => {
      try {
        // Fetch values using getMany
        const fetchedValues = await getMany(keys);

        // Map keys to their corresponding values
        const result = keys.reduce((acc, key, index) => {
          acc[key] = fetchedValues[index];
          return acc;
        }, {});

        setValues(result);
      } catch (error) {
        console.error("Error fetching data from idb:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchValues();
  }, [keys]);

  return { values, isLoading };
};

export default useIDBKeyVal;