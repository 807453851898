import React from 'react';
import { HStack, Icon, IconButton, Select } from '@chakra-ui/react';
import { BackChevronIcon } from '../../_images/icons/BackChevronIcon';
import { ChevronRightIcon } from '../../_images/icons/ChevronRightIcon';

export const Pagination = ({canPreviousPage, canNextPage, previousPage, nextPage, setPageIndex, pageIndex, total, isLoading}) => {
    if (total < 2) return <></>

    return (
        <HStack spacing={['10px', 3]} >
            <IconButton 
                isLoading={isLoading}
                icon={<BackChevronIcon width={20} height={17} color={'#273238'} />} 
                isDisabled={!canPreviousPage}
                onClick={previousPage}
                borderRadius={'5px'}
            />
            <Select 
                onChange={e => {
                    const page = e.target.value ? Number(e.target.value) : 0
                    setPageIndex(page)
                }}
                isDisabled={total < 2}
            >
                {Array.from({length: total}, (_, i) => (
                    <option key={i} value={i} selected={pageIndex == i}>
                        {i + 1}
                    </option>
                ))}
            </Select>
            <IconButton 
                isLoading={isLoading}
                icon={<ChevronRightIcon width={20} height={20} color={'#273238'} />} 
                isDisabled={!canNextPage}
                borderRadius={'5px'}
                onClick={nextPage}
            />
        </HStack>
    
    )
}