import { createContext, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get, set, del } from 'idb-keyval';
import storage, { idbStorage } from "../_helpers/storage";

// redux state
import { actions } from './_actions';
import { services as api } from "./_services";
import { getIsLoggedInFlag, getIsLoggedInLoading, getIsProfileLoading, getProfile } from "./_selectors";
import { constants } from "./_constants";

import { USER_TYPES } from "src/_config";
import { FORM_ERROR } from "final-form";

const authContext = createContext();

async function handleTokenResponse(data) {
  const { token, active } = data;
  if (active) {
    storage.set('user', data)
    await set('user', data);
    return token;
  }
}

export function ProvideAuth({children}) {
  const auth = useProvideAuth();
  return (
    <authContext.Provider value={auth}>
      {children}  
    </authContext.Provider>
  )
}

const useProvideAuth = () => {
    const dispatch = useDispatch();
    const [ user ] = useState(storage.get('user'));
    const [ isLoading, setIsLoading ] = useState(true);
    const [ profile, setProfile ] = useState(null);


    // Fetch profile on initial load
    useEffect(() => {
      const fetchProfile = async () => {
        setIsLoading(true);
        const userProfile = await loadUserProfile();
        if (userProfile) {
          setProfile(userProfile);
        }
        setIsLoading(false);
      };
      console.log(user)
      if (user?.token) {
        fetchProfile();
      } else {
        setIsLoading(false);
      }
      //fetchProfile();
    }, [user]); // Empty dependency array ensures this runs only on mount

    async function loadUserProfile() {
      const user = await get('user');
      if (user) {
        try {
          // DEPRECATED also set redux state for profile fetch
          dispatch({type: constants.USER_PROFILE_REQUEST});
          const data = await api.getProfile();

          // DEPRECATED also set redux state for profile success
          dispatch({type: constants.USER_PROFILE_SUCCESS, data});

          await idbStorage.setIsDentalType(data?.is_dental_type);
          await idbStorage.setIsOrphanage(data?.is_orphanage);

          // set log in storage as timestamp
          storage.setLoggedIn(1);
          //if (resp?.chat?.uuid){
          //  setUserId(analytics, resp?.chat?.uuid);
          //}
          
          return data;
        } catch (error) {
          console.log('auth.loadUser', error);
          return null;
        }
      }
      return null;
    }

    const login = async (email, password, redirectURL) => {
      setIsLoading(true);
      const response = await api.login(email, password, redirectURL);
      await handleTokenResponse(response);
      try {
        const user = await loadUserProfile();
        setProfile(user);
        // 
        setIsLoading(false);
        return user;
      } catch (error) {
        setIsLoading(false);
        return null;
      }

    }

    const registerNewPassword = async (data) => {
      setIsLoading(true);
      const response = await api.registerPassword(data);
      if (response?.user_type != USER_TYPES.ENDUSER && response?.user_type != USER_TYPES.ORPHANAGE  && response?.user_type != USER_TYPES.DENTAL){
        // have to throw here and on the then reject
        throw {[FORM_ERROR] : 'wrong_user_type'}
      } else if (response && response.token) {
        localStorage.setItem('user', JSON.stringify(response));
      }
      await handleTokenResponse(response);
      try {
        const user = await loadUserProfile();
        setProfile(user);
        // 
        setIsLoading(false);
        return user;
      } catch (error) {
        setIsLoading(false);
        return null;
      }

    }

    const logout = async () => {
      await api.logout()
      storage.remove('user');
      await del('user');
      window.location.assign(window.location.origin);
    }

    return {
      login,
      logout,
      registerNewPassword,
      isLoading,
      user: profile,
    }

}

export function useAuth() {
  return useContext(authContext);
}
