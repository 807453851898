import React from 'react';
import { Box } from '@chakra-ui/react';
import { Field } from 'react-final-form';
import { useIntl } from 'react-intl';
import { useCountry } from 'src/_libs/useCountry';
import { useQuotesStore, STEP_PAGES } from '../state/quotes';
import { mustBePhoneNumber } from 'src/_helpers/forms';
import { InputComponent } from 'src/_components/Forms/InputComponent';
import { ButtonStack, ContentContainer } from './common';
import { isFinalFormMetaInvalid, getFinalFormMetaError, required } from 'src/_helpers/forms';


export const QuoteStepLocation = ({values, setCurrentStep, isShowing}) => {
    const { formatMessage } = useIntl();

    const handleNext = () => {
        setCurrentStep(STEP_PAGES.PLAN_LIST)
    }

    const handlePrev = () => {
        setCurrentStep(STEP_PAGES.MEMBER_LIST, 'back')
    }

    // tahi phone +66-955-575-622
    // +66-955-595-263
    return (
        <Box display={isShowing ? 'box' : 'none'}>
            <ContentContainer>
                <Field name="location" validate={required}>
                    {({input, meta}) => (
                            <InputComponent 
                                {...input}
                                label={formatMessage({id: 'membershipPage.getQuote.init.location.label', defaultMessage: 'Area'})}
                                placeholder={formatMessage({id: 'membershipPage.getQuote.init.location.placeholder', defaultMessage: 'area'})}
                                isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                error={getFinalFormMetaError(meta)}
                            />
                    )}
                </Field>
            </ContentContainer>
            <Box mt={[6]}>
                <ButtonStack
                    handleClickPrevious={handlePrev}
                    handleClickNext={handleNext}
                    isDisabledNext={!values.location}
                    textPrevious={formatMessage({id: 'membershipPage.getQuote.location.previous', defaultMessage: 'Previous'})}
                    textNext={formatMessage({id: 'membershipPage.getQuote.location.next', defaultMessage: 'Next'})}
                />
            </Box>
        </Box>
    )
}