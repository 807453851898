import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { CountryProvider } from 'src/_libs/useCountry';
import { PasswordRegisterPage } from './PasswordRegisterPage';

const BASE_PATH = '/a';
  
export const Routes = () => {
    return (
      <CountryProvider>
          <Switch>
              <Route exact path={[`${BASE_PATH}/password-register`]}>
                  <PasswordRegisterPage />
              </Route>
          </Switch>
      </CountryProvider>
    )
}