import { get, set, del } from 'idb-keyval';
import { remove } from 'lodash';

const storagePrefix = `pdh_`;
export const STORAGE_KEYS = {
    SCAN_RECORD: 'scan_record',
    SCAN_PROFILE: 'scan_profile',
    SCAN_DENTIST: 'scan_dentist',
    CHECKUP_REQUEST: 'checkup_request',
    IS_DENTAL_TYPE: `${storagePrefix}is_dental_type`,
    IS_ORPHANAGE: `${storagePrefix}is_orphanage`,
}

// Function to get country from browser settings
export const getBrowserCountry = () => {
  const locale = navigator.language || navigator.languages[0] || 'en-US';
  const countryCode = locale.split('-')[1] || 'US'; // Default to 'US' if no country code
  return countryCode.toUpperCase();
};

export const idbStorage = {
  set: async (key, value) => {
      await set(`${storagePrefix}${key}`, value);
  },
  get: async (key) => {
      try {
          return await get(`${storagePrefix}${key}`);
      } catch (err) {
          return '';
      }
  },
  remove: async (key) => {
      await del(`${storagePrefix}${key}`);
  },
  setCountry: async (value) => {
      await set(`${storagePrefix}country`, value);
  },
  getCountry: async () => {
      try {
          const storedCountry =  await get(`${storagePrefix}country`) || '';
          return storedCountry || getBrowserCountry();
      } catch (err) {
          return getBrowserCountry();
      }
  },
  setRegister: async (value) => {
      await set(`${storagePrefix}register`, value);
  },
  getRegister: async () => {
      try {
          return await get(`${storagePrefix}register`) || {};
      } catch (err) {
          return {};
      }
  },
  clearRegister: async () => {
      await del(`${storagePrefix}register`);
  },
  clearScanDetails: async () => {
      const keys = [
          `${storagePrefix}${STORAGE_KEYS.SCAN_RECORD}`,
          `${storagePrefix}${STORAGE_KEYS.SCAN_PROFILE}`,
          `${storagePrefix}${STORAGE_KEYS.SCAN_DENTIST}`,
          `${storagePrefix}${STORAGE_KEYS.CHECKUP_REQUEST}`,
      ];
      await Promise.all(keys.map((key) => del(key)));
  },
  clear: async (key) => {
      await del(`${storagePrefix}${key}`);
  },
  getCountry: async () => {
      try {
          return await get(`${storagePrefix}country`) || '';
      } catch (err) {
          return '';
      }
  },
  setCountry: async (value) => {
      await set(`${storagePrefix}country`, value);
  },
  clearCountry: async () => {
      await del(`${storagePrefix}country`);
  },
  setChartFlipped: async (value) => {
      await set(`${storagePrefix}chart_flipped`, value);
  },
  getChartFlipped: async () => {
      try {
          return await get(`${storagePrefix}chart_flipped`) || '';
      } catch (err) {
          return '';
      }
  },
  setLineLoginType: async (loginType) => {
      await set(`${storagePrefix}linelogintype`, loginType);
  },
  clearLineLoginType: async () => {
      await del(`${storagePrefix}linelogintype`);
  },
  getLineLoginType: async () => {
      try {
          return await get(`${storagePrefix}linelogintype`) || '';
      } catch (err) {
          return '';
      }
  },
  setSocialAppId: async (appId) => {
      await set(`${storagePrefix}socialappid`, appId);
  },
  getSocialAppId: async () => {
      try {
          return await get(`${storagePrefix}socialappid`) || '';
      } catch (err) {
          return '';
      }
  },
  clearSocialAppId: async () => {
      await del(`${storagePrefix}socialappid`);
  },
  setCheckupRequest: async (value) => {
      await set(`${storagePrefix}${STORAGE_KEYS.CHECKUP_REQUEST}`, value);
  },
  getCheckupRequest: async () => {
      try {
          return await get(`${storagePrefix}${STORAGE_KEYS.CHECKUP_REQUEST}`) || {};
      } catch (err) {
          return {};
      }
  },
  clearCheckupRequest: async () => {
      await del(`${storagePrefix}${STORAGE_KEYS.CHECKUP_REQUEST}`);
  },
  setScanRecord: async (value) => {
      await set(`${storagePrefix}${STORAGE_KEYS.SCAN_RECORD}`, value);
  },
  getScanRecord: async () => {
      try {
          return await get(`${storagePrefix}${STORAGE_KEYS.SCAN_RECORD}`) || '';
      } catch (err) {
          return '';
      }
  },
  clearScanRecord: async () => {
      await del(`${storagePrefix}${STORAGE_KEYS.SCAN_RECORD}`);
  },
  setScanProfile: async (value) => {
      await set(`${storagePrefix}${STORAGE_KEYS.SCAN_PROFILE}`, value);
  },
  getScanProfile: async () => {
      try {
          return await get(`${storagePrefix}${STORAGE_KEYS.SCAN_PROFILE}`) || {};
      } catch (err) {
          return {};
      }
  },
  clearScanProfile: async () => {
      await del(`${storagePrefix}${STORAGE_KEYS.SCAN_PROFILE}`);
  },
  setScanDentist: async (value) => {
      await set(`${storagePrefix}${STORAGE_KEYS.SCAN_DENTIST}`, value);
  },
  getScanDentist: async () => {
      try {
          return await get(`${storagePrefix}${STORAGE_KEYS.SCAN_DENTIST}`) || '';
      } catch (err) {
          return '';
      }
  },
  clearScanDentist: async () => {
      await del(`${storagePrefix}${STORAGE_KEYS.SCAN_DENTIST}`);
  },
  setIsDentalType: async (key) => {
      await set(`${storagePrefix}is_dental_type`, key);
  },
  getIsDentalType: async () => {
      try {
          return (await get(`${storagePrefix}is_dental_type`)) === true;
      } catch (err) {
          return false;
      }
  },
  setIsOrphanage: async (key) => {
      await set(`${storagePrefix}is_orphanage`, key);
  },
  getIsOrphanage: async () => {
      try {
          return (await get(`${storagePrefix}is_orphanage`)) === true;
      } catch (err) {
          return false;
      }
  },
  setTourOff: async (key) => {
      await set(`${storagePrefix}start_tour_${key}`, false);
  },
  getTourOn: async (key) => {
      try {
          return (await get(`${storagePrefix}start_tour_${key}`)) !== false;
      } catch (err) {
          return true;
      }
  },
};

const storage = {
    set: (key, value) => {
      window.localStorage.setItem(`${storagePrefix}${key}`, JSON.stringify(value));
    },
    get: (key) => {
      try {
        return JSON.parse(window.localStorage.getItem(`${storagePrefix}${key}`));
      } catch (err) {
        return '';
      }
    },
    remove: (key) => {
      window.localStorage.removeItem(`${storagePrefix}${key}`);
    },
    setRegister: (value) => {
      window.localStorage.setItem(`${storagePrefix}register`, JSON.stringify(value));
    },
    getRegister: () => {
      try {
        return JSON.parse(window.localStorage.getItem(`${storagePrefix}register`));
      } catch (err) {
        return {};
      }
    },
    clearRegister: () => {
      window.localStorage.removeItem(`${storagePrefix}register`);
    },
    clearScanDetails: () => {
        window.localStorage.removeItem(`${storagePrefix}${STORAGE_KEYS.SCAN_RECORD}`);
        window.localStorage.removeItem(`${storagePrefix}${STORAGE_KEYS.SCAN_PROFILE}`);
        window.localStorage.removeItem(`${storagePrefix}${STORAGE_KEYS.SCAN_DENTIST}`);
        window.localStorage.removeItem(`${storagePrefix}${STORAGE_KEYS.CHECKUP_REQUEST}`);
    },
    clear: (key) => {
      window.localStorage.removeItem(`${storagePrefix}${key}`);
    },
    getCountry: () => {
      try {
        return JSON.parse(window.localStorage.getItem(`${storagePrefix}country`));
      } catch (err) {
        return '';
      }
    }, 
    setCountry: (value) => {
      window.localStorage.setItem(`${storagePrefix}country`, JSON.stringify(value));
    },
    clearCountry: () => {
      window.localStorage.removeItem(`${storagePrefix}country`);
    },
    setChartFlipped: (value) => {
      window.localStorage.setItem(`${storagePrefix}chart_flipped`, JSON.stringify(value));
    },
    getChartFlipped: () => {
      try {
        return JSON.parse(window.localStorage.getItem(`${storagePrefix}chart_flipped`));
      } catch (err) {
        return '';
      }
    },
    setLineLoginType: (loginType) => {
      window.localStorage.setItem(`${storagePrefix}linelogintype`, JSON.stringify(loginType));
    },
    clearLineLoginType: () => {
      window.localStorage.removeItem(`${storagePrefix}linelogintype`);
    },
    getLineLoginType: () => {
      try {
        return JSON.parse(window.localStorage.getItem(`${storagePrefix}linelogintype`));
      } catch (err) {
        return '';
      }
    },
    setSocialAppId: (appId) => {
      window.localStorage.setItem(`${storagePrefix}socialappid`, JSON.stringify(appId));
    },
    getSocialAppId: () => {
      try {
        return JSON.parse(window.localStorage.getItem(`${storagePrefix}socialappid`));
      } catch (err) {
        return '';
      }
    },
    clearSocialAppId: () => {
      window.localStorage.removeItem(`${storagePrefix}socialappid`);
    },
    setCheckupRequest: (value) => {
        window.localStorage.setItem(`${storagePrefix}${STORAGE_KEYS.CHECKUP_REQUEST}`, JSON.stringify(value));
    },
    getCheckupRequest: (key) => {
      try {
        return JSON.parse(window.localStorage.getItem(`${storagePrefix}${STORAGE_KEYS.CHECKUP_REQUEST}`));
      } catch (err) {
        return {};
      }
    },
    clearCheckupRequest: (key) => {
      window.localStorage.removeItem(`${storagePrefix}${STORAGE_KEYS.CHECKUP_REQUEST}`);
    },
    setScanRecord: (value) => {
        window.localStorage.setItem(`${storagePrefix}${STORAGE_KEYS.SCAN_RECORD}`, JSON.stringify(value));
  
    },
    getScanRecord: (key) => {
      try {
        return JSON.parse(window.localStorage.getItem(`${storagePrefix}${STORAGE_KEYS.SCAN_RECORD}`));
      } catch (err) {
        return '';
      }
    },
    clearScanRecord: (key) => {
      window.localStorage.removeItem(`${storagePrefix}${STORAGE_KEYS.SCAN_RECORD}`);
    },
    setScanProfile: (value) => {
        window.localStorage.setItem(`${storagePrefix}${STORAGE_KEYS.SCAN_PROFILE}`, JSON.stringify(value));

    },
    getScanProfile: (key) => {
      try {
        return JSON.parse(window.localStorage.getItem(`${storagePrefix}${STORAGE_KEYS.SCAN_PROFILE}`));
      } catch (err) {
        return {};
      }
    },
    clearScanProfile: (key) => {
      window.localStorage.removeItem(`${storagePrefix}${STORAGE_KEYS.SCAN_PROFILE}`);
    },
    setScanDentist: (value) => {
        window.localStorage.setItem(`${storagePrefix}${STORAGE_KEYS.SCAN_DENTIST}`, JSON.stringify(value));
    },
    getScanDentist: (key) => {
      try {
        return JSON.parse(window.localStorage.getItem(`${storagePrefix}${STORAGE_KEYS.SCAN_DENTIST}`));
      } catch (err) {
        return '';
      }
    },
    clearScanDentist: (key) => {
    window.localStorage.removeItem(`${storagePrefix}${STORAGE_KEYS.SCAN_DENTIST}`);
    },
    setIsDentalType: (key) => {
      window.localStorage.setItem(`${storagePrefix}is_dental_type`, JSON.stringify(key));
    },
    getIsDentalType: () => {
      // orphage will determine visibility of UI and functions in the app
      // mainly dentist select
      try {
        return JSON.parse(window.localStorage.getItem(`${storagePrefix}is_dental_type`)) === true;
      } catch (err) {
        return false;
      }
    },
    setIsOrphanage: (key) => {
      window.localStorage.setItem(`${storagePrefix}is_orphanage`, JSON.stringify(key));
    },
    getIsOrphanage: () => {
      // orphage will determine visibility of UI and functions in the app
      try {
        return JSON.parse(window.localStorage.getItem(`${storagePrefix}is_orphanage`)) === true;
      } catch (err) {
        return false;
      }
    },
    setTourOff: (key) => {
      window.localStorage.setItem(`${storagePrefix}start_tour_${key}`, JSON.stringify(false));
    },
    getTourOn: (key) => {
      try {
        return JSON.parse(window.localStorage.getItem(`${storagePrefix}start_tour_${key}`)) !== false;
      } catch (err) {
        return true;
      }
    },
    setLoggedIn: (key) => {
      window.localStorage.setItem(`${storagePrefix}log`, JSON.stringify(1));
    },
    getLoggedIn: () => {
      try {
        return JSON.parse(window.localStorage.getItem(`${storagePrefix}log`));
      } catch (err) {
        return null;
      }
    },
  };
  
  export default storage;
  