import React from "react";
import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";
import { MaximumBenefit } from "../components/MaximumBenefit";
import { TreatmentList } from "../components/TreatmentList";
import { BackChevronIcon } from "../../_images/icons/BackChevronIcon";
import { PaymentNeeded } from "../components/PaymentNeeded";
import { formatDate } from "../utils";
import { useMembershipDetails } from "../api/getUserMembership";

const BreadCrumbs = () => {
    const COLOR = '#718096'

    const history = useHistory();

    const handleBack = () => {
        history.push('/membership')
    }

    return (
        <Flex onClick={handleBack} align={['center']}>
            <BackChevronIcon color={COLOR} />
            <Text ml={['8px']} color={COLOR}>
                <FormattedMessage
                    id={'membershipPage.treatmentList.back'}
                    defaultMessage={'Back to Members'}
                />
            </Text>
        </Flex>
    )
}

const TEXT_LABEL_PROPS = {
    color: '#7B8887',
    mr: ['0px', '0px'],
    pb:['10px', 0],
    letterSpacing: '0.6px',
}

const TEXT_VALUE_PROPS = {
    letterSpacing: '0.7px',
}

export const MembershipBenefitsPage = () => {
    const { profileId } = useParams();
    const { data } = useMembershipDetails();
    return (
        <Box mt={['80px', '100px']} px={['20px', '50px']} pb={['50px', 0]}>
            <PaymentNeeded />
            <Box py={['10px', '20px']} pr={[0, '40px']} minW={['100%', '150px']}>
                <BreadCrumbs />
            </Box>

            <Heading >
                <FormattedMessage 
                    id={'membershipPage.membershipDetails.title'}
                    defaultMessage={'Membership Details'}
                />
            </Heading>


            <Box py={['10px', '20px']} pr={[0, '40px']} minW={['100%', '150px']}>
                <Text fontSize={18} fontFamily={'Proxima Nova Semibold'}>
                    <FormattedMessage 
                        id={'membershipPage.membershipDetails.maximumBenefit.subtitle.details'}
                        defaultMessage={'Plan Information'}
                    />
                </Text>
            </Box>
            <Flex justify={['center', 'flex-start']} mt={['0px', '0px']} direction={['column']} pb={['20px']}>
                <Flex>
                    <Text {...TEXT_LABEL_PROPS}>
                        <FormattedMessage
                            id={'membershipPage.membershipDetails.maximumBenefit.label.planName'}
                            defaultMessage={'Plan Name'}
                        />
                    </Text>
                    <Text {...TEXT_VALUE_PROPS} ml={[2]}>
                        {data?.membership?.plan_name}
                    </Text>
                
                </Flex>
                <Flex>
                    <Text {...TEXT_LABEL_PROPS}>
                        <FormattedMessage
                            id={'membershipPage.membershipDetails.maximumBenefit.label.memberSince'}
                            defaultMessage={'Member Since'}
                        />
                    </Text>
                    <Text {...TEXT_VALUE_PROPS} ml={[2]}>
                        {`${formatDate(data?.membership?.start_date)}`}
                    </Text>
                </Flex>
                <Flex>
                    <Text {...TEXT_LABEL_PROPS}>
                        <FormattedMessage
                            id={'membershipPage.membershipDetails.maximumBenefit.label.totalMembers'}
                            defaultMessage={'Total Members'}
                        />
                    </Text>
                    <Text {...TEXT_VALUE_PROPS} ml={[2]}>
                        {(data?.membership?.members || []).length}
                    </Text>
                </Flex>
            </Flex>

            <Box py={['10px', '20px']} pr={[0, '40px']} minW={['100%', '150px']}>
                <Text fontSize={18} fontFamily={'Proxima Nova Semibold'}>
                    <FormattedMessage 
                        id={'membershipPage.membershipDetails.maximumBenefit.subtitle.benefits'}
                        defaultMessage={'Maximum Benefit'}
                    />
                </Text>
            </Box>

            <Flex justify={['center', 'flex-start']} mt={['0px', '0px']} direction={['column']} pb={['20px']}>
                <MaximumBenefit />
            </Flex>

            <Box py={['10px', '20px']} pr={[0, '40px']} minW={['100%', '150px']}>
                <Text fontSize={18} fontFamily={'Proxima Nova Semibold'}>
                    <FormattedMessage 
                        id={'membershipPage.membershipDetails.treatmentList.subtitle'}
                        defaultMessage={'Recent Treatments'}
                    />
                </Text>
            </Box>

            <Flex justify={['center', 'flex-start']} mt={['0px', '0px']} direction={['column']}>
                <TreatmentList profileId={profileId} isRecentsOnly={true} />
            </Flex>
        </Box>
    )
}