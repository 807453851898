import React, { useEffect, useState } from 'react';
import { Container, Box, Stack, Flex, Button, Input, Select, Radio, RadioGroup, useToast, Collapse, Fade } from '@chakra-ui/react';
import { useForm, Controller } from 'react-hook-form';
import useSteps from 'src/_components/useSteps';
import { useUrlSearchParams } from 'src/_helpers/useUrlSearchParams';
import { FormattedMessage } from 'react-intl';

const InitialStep = ({ setIsForChild }) => {
    const handleSelection = (value) => {
        setIsForChild(value === 'yes');
    };

    return (
        <Box>
        <RadioGroup onChange={handleSelection} defaultValue="no">
            <Stack direction="row">
            <Radio value="yes">Yes, this is only for a child</Radio>
            <Radio value="no">No, it's not only for a child</Radio>
            </Stack>
        </RadioGroup>
        </Box>
    );
};


const ChildFormStep = ({ control, setValue, getValues }) => {
  const searchParams = useUrlSearchParams();
  const isQueryReport = searchParams.get('query_report');
  const [showReportField, setShowReportField] = useState(isQueryReport);

  return (
    <Box>
      <Controller name="child.name" control={control} render={({ field }) => (
        <Fade in>
          <Input placeholder="Child Name" {...field} />
        </Fade>
      )} />
      <Controller name="child.birthdate" control={control} render={({ field }) => (
        <Fade in>
          <Input placeholder="Child Birthdate" type="date" {...field} />
        </Fade>
      )} />
      <Controller name="child.gender" control={control} render={({ field }) => (
        <Fade in>
          <Select placeholder="Child Gender" {...field}>
            <option value="male">Male</option>
            <option value="female">Female</option>
          </Select>
        </Fade>
      )} />

      <Collapse in={showReportField}>
        <Controller name="child.report" control={control} render={({ field }) => (
          <Input placeholder="Report" {...field} />
        )} />
      </Collapse>
    </Box>
  );
};

const ScreeningFormStep = ({ control }) => {
  const [showScreeningField, setShowScreeningField] = useState(false);

  return (
    <Box>
      <Controller name="child.hasScreening" control={control} render={({ field }) => (
        <Select placeholder="Did the child receive a screening?" {...field} onChange={(e) => {
          field.onChange(e);
          setShowScreeningField(e.target.value === 'yes');
        }}>
          <option value="yes">Yes</option>
          <option value="no">No</option>
        </Select>
      )} />
      <Collapse in={showScreeningField}>
        <Controller name="child.screeningId" control={control} render={({ field }) => (
          <Input placeholder="Screening ID" {...field} />
        )} />
      </Collapse>
    </Box>
  );
};

const DependentFormStep = ({ control }) => {
  return (
    <Box>
      <Controller name="dependent.name" control={control} render={({ field }) => (
        <Input placeholder="Dependent Name" {...field} />
      )} />
      <Controller name="dependent.birthdate" control={control} render={({ field }) => (
        <Input placeholder="Dependent Birthdate" type="date" {...field} />
      )} />
      <Controller name="dependent.gender" control={control} render={({ field }) => (
        <Select placeholder="Dependent Gender" {...field}>
          <option value="male">Male</option>
          <option value="female">Female</option>
        </Select>
      )} />
      <Controller name="dependent.relationship" control={control} render={({ field }) => (
        <Input placeholder="Relationship to Child" {...field} />
      )} />
    </Box>
  );
};

const AdditionalDependentsFormStep = ({ control, append }) => {
  const addDependent = () => {
    append({ name: '', birthdate: '', gender: '', relationship: '' });
  };

  return (
    <Box>
      <Button onClick={addDependent}>Add Dependent</Button>
    </Box>
  );
};


const Navigation = ({next, prev, current, total}) => { 

    const handleNext = () => {
        console.log("next   ")
        next();
    }

    return (
      <Flex justify="space-between" mt={4}>
        { (current != 1) && ( 
            <Button type="button" onClick={prev}>
                <FormattedMessage
                    id="quoteForm.back"
                    defaultMessage="Back"
                />
            </Button>
        )}
        { (current == total) ? ( 
            <Button type="button" onClick={next} colorScheme="teal">
                <FormattedMessage
                    id="quoteForm.submit"
                    defaultMessage="Get Price"
                />
            </Button>
        ) : (
            <Button type="button" onClick={handleNext} colorScheme="teal">
                <FormattedMessage
                    id="quoteForm.next"
                    defaultMessage="Next"
                />
            </Button>
        )}
      </Flex>
    );
  };
  

export const QuoteForm = () => {
    const { control, handleSubmit, setValue, getValues, reset } = useForm();
    const current = useSteps((state) => state.current);
    const next = useSteps((state) => state.next);
    const prev = useSteps((state) => state.prev);
    const jump = useSteps((state) => state.jump);
    const total = useSteps((state) => state.total);
    const isFirst = useSteps((state) => state.isFirst);
    const isLast = useSteps((state) => state.isLast);
    const setTotal = useSteps((state) => state.setTotal);

    const [isForChild, setIsForChild] = useState(null);
    const toast = useToast();


    useEffect(() => {
        // Set the total steps dynamically based on available steps or other logic
        setTotal(5); // Assuming we have 4 steps in this example
    }, [setTotal]);

    // Define steps as an array of components to render based on currentStep
    const steps = [
        <InitialStep setIsForChild={setIsForChild} />,
        <ChildFormStep control={control} setValue={setValue} getValues={getValues} />,
        <ScreeningFormStep control={control} />,
        <DependentFormStep control={control} />,
        <AdditionalDependentsFormStep control={control} />
    ];

    // is this for child?

    // for child, then enter child form details

    // did child receive screening?

    // if yes, enter screening ID

    // enter your details

    // add additional dependents

    // go back to summarize step

    

    console.log("total ", total)
    console.log("current ", current)

    const onSubmit = (data) => {
        console.log('Form Submitted:', data);
        toast({ title: 'Form submitted successfully', status: 'success' });
    };

    return (
        <Container>
            <form onSubmit={handleSubmit(onSubmit)}>
                {steps[current]}

                <Navigation
                    prev={prev}
                    next={next}
                    jump={jump}
                    total={total}
                    current={current}
                    isFirst={isFirst}
                    isLast={isLast}
                />
            </form>
        </Container>
  );
};