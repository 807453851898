
import React from 'react';

export const CheckIcon = (props) => {
  const height = props?.height || 20;
  const width = props?.width || 20;
  const color = props?.color || '#2E3D44';
  return(
    <svg width={width} height={height} color={color} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 11L7.5 15.5L18 4" stroke="currentColor"/>
    </svg>
  )
}
