import React from 'react';
import { Button, Stack, Heading, Container } from '@chakra-ui/react';

const CONTAINER_PROPS = {
    maxW: ['full', 'xl']
}

export const ContainerComponent = ({ children }) => {
    return (
        <Container {...CONTAINER_PROPS}>
            {children}
        </Container>
    )
}

export const HeadingContainer = ({ children }) => {
    return (
        <Container {...CONTAINER_PROPS} >
            <Heading pb={[2, 4]}>
                {children}
            </Heading>
        </Container>
    )
}

export const ButtonStack = ({ handleClickPrevious, isDisabledPrevious, textPrevious, handleClickNext, isLoadingNext, isDisabledNext, textNext, isSubmit=false }) => {
    return (
        <Container {...CONTAINER_PROPS}>
            <Stack w={['full']} justify={['flex-end', 'center']} pt={['15px', 8]} direction={['column', 'row']} spacing={['15px']}>
                {
                    handleClickPrevious && (
                        <Button
                            type="button"
                            variant={"outline"}
                            onClick={handleClickPrevious}
                            isDisabled={isDisabledPrevious}
                        >
                            {textPrevious}
                        </Button>
                    )
                }
                <Button
                    type={isSubmit ? 'submit' : 'button'}
                    isLoading={isLoadingNext}
                    isDisabled={isDisabledNext}
                    onClick={handleClickNext}
                >
                    {textNext}
                </Button>
            </Stack>
        </Container>
    )
}

export const ContentContainer = ({ children }) => {
    return (
        <Container {...CONTAINER_PROPS}>
            <Stack w={['full']} spacing={['15px']} direction={['column']}>
                {children}
            </Stack>
        </Container>
    )
}