import React from 'react';
import { Box, Divider } from '@chakra-ui/react';
import { nanoid } from 'nanoid';
import { useHistory, useLocation } from 'react-router-dom';
import { ContentContainer, ButtonStack } from './common';
import { Field } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormControl, FormLabel, Input, Text, Heading, Select, VStack, SimpleGrid, useDisclosure } from '@chakra-ui/react';
import { useApplyStore, STEP_PAGES } from '../state/apply';
import CenteredSpinner from 'src/_components/CenteredSpinner';
import { CodeConfirmModal } from './CodeConfirmModal';
import { useCountry } from 'src/_libs/useCountry';
import { useRequestSimpleCode } from '../api/requestCode';


const GRID_PROPS = {
    width: ['full'],
    gap: ['10px']
}

const RelationshipText = ({relationship}) => {
    const relationshipUpper = relationship.toUpperCase();
    switch (relationshipUpper) {
        case 'SPOUSE':
            return (
                <FormattedMessage id={'membershipPage.dependentStep.relationship.spouse'} defaultMessage={'Spouse'} />
            )
        case 'CHILD':
            return (
                <FormattedMessage id={'membershipPage.dependentStep.relationship.child'} defaultMessage={'Child'} />
            )
        case 'PARENT':
            return (
                <FormattedMessage id={'membershipPage.dependentStep.relationship.parent'} defaultMessage={'Parent'} />
            )
        case 'SELF':
            return (
                <FormattedMessage id={'membershipPage.dependentStep.relationship.self'} defaultMessage={'Self'} />
            )
        default:
            return (
                <FormattedMessage id={'membershipPage.dependentStep.relationship.other'} defaultMessage={'Other'} />
            )
        }
}

const getRelationshipText = (relationship, formatMessage) => {
    const relationshipUpper = relationship.toUpperCase();
    switch (relationshipUpper) {
        case 'SPOUSE':
            return formatMessage({
                id: 'membershipPage.dependentStep.relationship.spouse', defaultMessage: 'Spouse'
            })
        case 'CHILD':
            return formatMessage({
                id: 'membershipPage.dependentStep.relationship.child', defaultMessage: 'Child'
            })
        case 'PARENT':
            return formatMessage({
                id: 'membershipPage.dependentStep.relationship.parent', defaultMessage: 'Parent'
            })
        case 'SELF':
            return formatMessage({
                id: 'membershipPage.dependentStep.relationship.self', defaultMessage: 'Self'
            })
        default:
            return formatMessage({
                id: 'membershipPage.dependentStep.relationship.other', defaultMessage: 'Other'
            })
    }
}


const SingleDependentForm = ({relationship, dependent, index, handleChange}) => {
    const { formatMessage } = useIntl();

    const onChange = (newValue) => {
        handleChange(relationship, dependent.id, newValue);
    }


    return (
        <Box>
            <VStack w={['full']} spacing={['15px']} py={['10px']}>
                <Divider />
                <Heading fontSize={18} textAlign={'left'} alignSelf={['flex-start']} >
                    <RelationshipText relationship={relationship} />&nbsp;{ index ? index + 1 : '' }
                </Heading>
                <SimpleGrid {...GRID_PROPS} columns={[2]} w={['full']}>
                    <FormControl>
                        <FormLabel>
                            <FormattedMessage id={'membershipPage.getQuote.dependentForm.firstName.label'} defaultMessage={'First Name'} />
                        </FormLabel>
                        <Input 
                            id={'first_name'}
                            name={'first_name'}
                            value={dependent?.first_name }
                            onChange={(e) => onChange({first_name: e.target.value})}
                            placeholder={formatMessage({
                                id: 'membershipPage.getQuote.dependentForm.firstName.placeholder', defaultMessage: 'First Name'
                            })}
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>
                            <FormattedMessage id={'membershipPage.getQuote.dependentForm.lastName.label'} defaultMessage={'Last Name'} />
                        </FormLabel>
                        <Input 
                            id={'last_name'}
                            name={'last_name'}
                            value={dependent?.last_name}
                            onChange={(e) => onChange( {last_name: e.target.value})}
                            placeholder={formatMessage({
                                id: 'membershipPage.getQuote.dependentForm.lastName.placeholder', defaultMessage: 'Last Name'
                            })}
                        />
                    </FormControl>
                </SimpleGrid>
                <SimpleGrid {...GRID_PROPS} w={['full']} columns={[2]}>
                    <FormControl>
                        <FormLabel>
                            <FormattedMessage id={'membershipPage.getQuote.dependentForm.date_of_birth.label'} defaultMessage={'Date of Birth'} />
                        </FormLabel>
                        <Input 
                            name={'date_of_birth'}
                            value={dependent?.date_of_birth}
                            type={'date'}
                            max={new Date().toISOString().split('T')[0]}
                            onChange={(e) => onChange( {date_of_birth: e.target.value})}
                            placeholder={formatMessage({
                                id: 'membershipPage.getQuote.dependentForm.date_of_birth.placeholder', defaultMessage: 'Date of Birth'
                            })}
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>
                            <FormattedMessage id={'membershipPage.getQuote.dependentForm.gender.label'} defaultMessage={'Gender'} />
                        </FormLabel>
                        <Select
                            id={'gender'}
                            name={'gender'}
                            value={dependent?.gender}
                            placeholder={formatMessage({
                                id: 'membershipPage.getQuote.dependentForm.gender.placeholder', defaultMessage: 'Gender'
                            })}
                            onChange={(e) => onChange({gender: e.target.value})}
                        >
                            <option value="MALE">
                                {formatMessage({id: 'membershipPage.getQuote.dependentForm.gender.male', defaultMessage: 'Male'})}
                            </option>
                            <option value="FEMALE">
                                {formatMessage({id: 'membershipPage.getQuote.dependentForm.gender.female', defaultMessage: 'Female'})}
                            </option>
                        </Select>
                    </FormControl>
                </SimpleGrid>
            </VStack>
        </Box>
    )
}

const DependentsField = ({inputValue, onChange}) => {

    const handleChange = (relationship, id, newValue) => {

        // go through the current value of the relationship and update the value
        const updatedDependents = {
            ...inputValue,
            [relationship]: inputValue[relationship].map((item) => {
                if (item.id === id){
                    return {
                        ...item,
                        ...newValue
                    }
                }
                return item;
            })
        }
        onChange(updatedDependents);
    }

    return (
        <>
            {
                inputValue['SPOUSE'].map((item) => {
                    return (
                        <Box key={item.id}>
                            <SingleDependentForm 
                                relationship={'SPOUSE'} 
                                dependent={item} 
                                handleChange={handleChange} 
                            />
                        </Box>
                    )
                })
            }
            {
                inputValue['CHILD'].map((item, idx) => {
                    return (
                        <Box key={item.id}>
                            <SingleDependentForm 
                                index={idx}
                                relationship={'CHILD'} 
                                dependent={item} 
                                handleChange={handleChange} 
                            />
                        </Box>
                    )
                })
            }
            {
                inputValue['PARENT'].map((item, idx) => {
                    return (
                        <Box key={item.id}>
                            <SingleDependentForm 
                                index={idx}
                                relationship={'PARENT'} 
                                dependent={item} 
                                handleChange={handleChange} 
                            />
                        </Box>
                    )
                })
            }
        </>
    )

}

const PrimaryMemberForm = () => {
    const { formatMessage } = useIntl();
    return (
        <Box>
            <VStack w={['full']} spacing={['15px']}>
                <Divider />
                <Heading fontSize={18} textAlign={'left'} alignSelf={['flex-start']}>
                    <RelationshipText relationship={'self'} />
                </Heading>
                <SimpleGrid {...GRID_PROPS} columns={[1]}>
                    <Field name="email">
                        {({input, meta}) => (
                            <FormControl>
                                <FormLabel>
                                    <FormattedMessage id={'membershipPage.memberForm.email'} defaultMessage={'Email'} />
                                </FormLabel>
                                <Input 
                                    {...input}
                                    placeholder={formatMessage({
                                        id: 'membershipPage.memberForm.email', defaultMessage: 'Email'
                                    })}
                                />
                            </FormControl>
                        )}
                    </Field>
                </SimpleGrid>
                <SimpleGrid {...GRID_PROPS} columns={[2]}>
                    <Field name="first_name">
                        {({input, meta}) => (
                                <FormControl>
                                    <FormLabel>
                                        <FormattedMessage id={'membershipPage.memberForm.firstName'} defaultMessage={'First Name'} />
                                    </FormLabel>
                                    <Input 
                                        {...input}
                                        placeholder={formatMessage({
                                            id: 'membershipPage.memberForm.firstName', defaultMessage: 'First Name'
                                        })}
                                    />
                                </FormControl>
                        )}
                    </Field>
                    <Field name="last_name">
                        {({input, meta}) => (
                                <FormControl>
                                    <FormLabel>
                                        <FormattedMessage id={'membershipPage.memberForm.lastName'} defaultMessage={'Last Name'} />
                                    </FormLabel>
                                    <Input 
                                        {...input}
                                        placeholder={formatMessage({
                                            id: 'membershipPage.memberForm.lastName', defaultMessage: 'Last Name'
                                        })}
                                    />
                                </FormControl>
                        )}
                    </Field>
                </SimpleGrid>
                <SimpleGrid {...GRID_PROPS} columns={[2]}>
                    <Field name="date_of_birth">
                        {({input, meta}) => (
                                <FormControl>
                                    <FormLabel>
                                        <FormattedMessage id={'membershipPage.memberForm.date_of_birth'} defaultMessage={'Date of Birth'} />
                                    </FormLabel>
                                    <Input 
                                        {...input}
                                        type={'date'}
                                        max={new Date().toISOString().split('T')[0]}
                                        placeholder={formatMessage({
                                            id: 'membershipPage.memberForm.date_of_birth', defaultMessage: 'Date of Birth'
                                        })}
                                    />
                                </FormControl>
                        )}
                    </Field>
                    <Field name="gender">
                        {({input, meta}) => (
                                <FormControl>
                                    <FormLabel>
                                        <FormattedMessage id={'membershipPage.memberForm.gender'} defaultMessage={'Gender'} />
                                    </FormLabel>
                                    <Select
                                        id={'gender'}
                                        placeholder={formatMessage({
                                            id: 'membershipPage.memberForm.gender', defaultMessage: 'Gender'
                                        })}
                                        value={input.value}
                                        onChange={(e) => input.onChange(e.target.value)}
                                    >
                                        <option value="MALE">
                                            {
                                                formatMessage({
                                                    id: 'membershipPage.memberForm.gender.male',
                                                    defaultMessage: 'Male'
                                                })
                                            }
                                        </option>
                                        <option value="FEMALE">
                                            {
                                                formatMessage({
                                                    id: 'membershipPage.memberForm.gender.female',
                                                    defaultMessage: 'Female'
                                                })
                                            }
                                        </option>
                                    </Select>
                                </FormControl>
                        )}
                    </Field>
                </SimpleGrid>
            </VStack>
        </Box>
    )
}

function getDependentsCount(dependent){
    if (!dependent) return 0;
    return parseInt(dependent);
}

function createInitialDependentField(dependents){
    const val = {
        SPOUSE: [],
        CHILD: [],
        PARENT: []
    };
    if (getDependentsCount(dependents?.SPOUSE) > 0){
        val.SPOUSE = [{
            id: nanoid(),
            relationship: 'SPOUSE'
        }]
    }
    if (getDependentsCount(dependents?.CHILD) > 0){ 
        // for count value of dependents?.child, create an object in list
        // with id as nanoid
        Array.from({length: getDependentsCount(dependents?.CHILD)}, () => (
            val['CHILD'].push({
                id: nanoid(),
                relationship: 'CHILD'
            })
        ))
    }
    if (getDependentsCount(dependents?.parent) > 0){
        Array.from({length: getDependentsCount(dependents?.PARENT)}, () => (
            val['PARENT'].push({
                id: nanoid(),
                relationship: 'PARENT'
            })
        ))
    }
    return val;
}

// check fields and check that code was set
const shouldBeDisabled = (values) => {
    // all fields are rquired
    if (!values.email || !values.first_name || !values.last_name || !values.date_of_birth || !values.gender){
        return true;
    }
    for (const item of values.dependents['SPOUSE']) {
        if (!item.first_name || !item.last_name || !item.date_of_birth || !item.gender) {
          return true;
        }
    }
    for (const item of values.dependents['CHILD']) {
        if (!item.first_name || !item.last_name || !item.date_of_birth || !item.gender) {
          return true;
        }
    }
    for (const item of values.dependents['PARENT']) {
        if (!item.first_name || !item.last_name || !item.date_of_birth || !item.gender) {
          return true;
        }
    }
    return false;
}
    

export const ApplyStepMembers = ({setCurrentStep, isShowing, values}) => {
    const { formatMessage } = useIntl();
    const [ isEmailConfirmed, setEmailConfirmed ] = React.useState(false);
    const nonce = useApplyStore(state => state.nonce);
    const setNonce = useApplyStore(state => state.setNonce);
    const history = useHistory();
    const { country } = useCountry();
    const location = useLocation();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [quoteData, setQuoteData] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const requestCodeMutation = useRequestSimpleCode();


    React.useEffect(() => {
        const fetchQuoteData = async () => {
            setLoading(true);
            const data = await useApplyStore.getState().quoteData;
            setQuoteData(data);
            setNonce(nanoid());
            setLoading(false);
        };

        fetchQuoteData();
        
    }, []);

    const initialDependentValue = React.useMemo(() => {
        const val = createInitialDependentField(quoteData?.data?.dependents);
        return val;
    }
    , [quoteData?.data]);

    const handlePrev = () => {
        history.replace('/preonb/quotes/plan_list', {from: location})
    }


    const handleNext = () => {
        const isError = shouldBeDisabled(values);
        if (isError) return null;

        // if email is confirmed, then go to the next step
        if (isEmailConfirmed == values?.email) {
            setCurrentStep(STEP_PAGES.REVIEW);
            return;
        }

        // request here
        const numOfSeconds = 15;
        // if submittedAt timestamp is les than 10- seconds ago, then don't resend code
        if (requestCodeMutation.submittedAt){
            const currentTime = new Date().getTime();
            const timeDiff = currentTime - requestCodeMutation.submittedAt;
            if (timeDiff < numOfSeconds * 1000) {
                onOpen();
                return;
            }
        }
        // otherwise, request a code
        requestCodeMutation.mutate({email: values?.email, country, nonce });
        onOpen();
    }

    const onConfirmCode = () => {
        const isError = shouldBeDisabled(values);
        if (isError) return null;
        setEmailConfirmed(values?.email);
        onClose();
        setCurrentStep(STEP_PAGES.REVIEW);
    }

    if (loading) return  <Box><CenteredSpinner /></Box>

    return (
        <Box display={isShowing ? 'box' : 'none'}>
            <CodeConfirmModal 
                email={values?.email}
                phone={values?.phone}
                nonce={nonce}
                isOpen={isOpen}
                onClose={onClose}
                onSuccess={onConfirmCode}
                country={country}
                
            />
            <ContentContainer>
                <PrimaryMemberForm />
                <Field name="dependents" initialValue={initialDependentValue}>
                    {({input, meta}) => (
                        <DependentsField 
                            inputValue={input.value} 
                            onChange={input.onChange}
                        />
                    )}
                </Field>
            </ContentContainer>
            <Box mt={[6]}>
                <ButtonStack
                    handleClickPrevious={handlePrev}
                    handleClickNext={handleNext}
                    isDisabledNext={shouldBeDisabled(values)}
                    textPrevious={formatMessage({id: 'membershipPage.getQuote.members.previous', defaultMessage: 'Previous'})}
                    textNext={formatMessage({id: 'membershipPage.getQuote.members.next', defaultMessage: 'Next'})}
                />
            </Box>
        </Box>
    )
}