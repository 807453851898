import iconHome from '../_images/navIcon_1.png';
import iconChat from '../_images/navIcon_2.png';
import iconReport from '../_images/navIcon_3.png';
import iconSettings from '../_images/navIcon_4.png';

import iconHomeOn from '../_images/HomeIconOn.svg';
import iconHomeOff from '../_images/HomeIconOff.svg';
import iconReportOn from '../_images/ReportsIconOn.svg';
import iconReportOff from '../_images/ReportsIconOff.svg';
import iconSettingsOn from '../_images/AccountIconOn.svg';
import iconSettingsOff from '../_images/AccountIconOff.svg';
import { method } from 'lodash';

const window_location = window.location

var BASE_URL = process.env.REACT_APP_BASE_URL;
var BASE_API_URL = process.env.REACT_APP_API_URL;
var REACT_APP_PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL;

var APPLE_WEBSERVICEURL = process.env.REACT_APP_APPLE_WEBSERVICEURL;
var APPLE_WEBSITEPUSHID = process.env.REACT_APP_APPLE_WEBSITEPUSHID;

var FIREBASE_PUSH_KEY = process.env.REACT_APP_FIREBASE_PUSH_KEY
console.log("firebase project id", process.env.REACT_APP_FIREBASE_PROJECT_ID)
var FIREBASE_CONFIG = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
}


var DEVICE_NAME = 'PLVR_U1';
var DEVICE_NAME_DESKTOP = 'PLVR_U1W';
var DEVICE_NAME2 = 'USB Camera';

var GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
var FACEBOOK_CLIENT_ID = process.env.REACT_APP_FACEBOOK_CLIENT_ID;

var OPENTOK_API_KEY = process.env.REACT_APP_OPENTOK_API_KEY;
var US_STRIPE_KEY = process.env.REACT_APP_US_STRIPE_KEY;
var JP_STRIPE_KEY = process.env.REACT_APP_JP_STRIPE_KEY;
const API_DATE_FORMAT = "YYYY-MM-DD"
const API_DATETIME_FORMAT = "YYYY-MM-DD HH:mm"

const API_URLS = {

  countries: { method: 'GET', url: `${BASE_API_URL}/u/country-list`},
  regions: { method: 'GET', url: `${BASE_API_URL}/u/region-list/{country}`},

  appFeatures: { method : 'GET', url: `${BASE_API_URL}/a/app_features/{appName}/{country}`},
  selectOptions: { method: 'GET', url: `${BASE_API_URL}/u/select-options/`},
  socialApps: { method: 'GET', url: `${BASE_API_URL}/u/social_apps/{country}`},

  login: {method: 'POST', url: `${BASE_API_URL}/auth/login/`},
  logout: {method: 'POST', url: `${BASE_API_URL}/auth/logout/`},
  loginLineSocial: {method: 'POST', url: `${BASE_API_URL}/u/user/login/social`},
  loginSocial: {method: 'POST', url: `${BASE_API_URL}/auth/login/social/`},
  resetPassword: {method: 'POST', url: `${BASE_API_URL}/auth/password/reset/confirm/`},
  register: {method: 'POST', url: `${BASE_API_URL}/u/user/register`},
  registerSocial: {method: 'POST', url: `${BASE_API_URL}/u/user/register/social`},
  activateEmail: {method: 'POST', url: `${BASE_API_URL}/u/user/activate`},
  requestCodeActivation: {method: 'POST', url: `${BASE_API_URL}/u/user/activate/request`},
  resetPassword: {method: 'POST', url: `${BASE_API_URL}/auth/password/code/reset/confirm/`},
  requestCodeResetPassword: {method: 'POST', url: `${BASE_API_URL}/auth/password/code/reset/`},
  createNewPassword: {method: 'POST', url: `${BASE_API_URL}/auth/password/create/`},
  registerPassword: {method: 'POST', url: `${BASE_API_URL}/auth/password/code/register_password`},
  requestCodeRegisterPassword: {method: 'POST', url: `${BASE_API_URL}/auth/password/code/register_password/request`},

  uploadAvatar: {method: 'POST', url: `${BASE_API_URL}/u/user/profile/photo`},
  getProfile: {method: 'GET', url: `${BASE_API_URL}/u/user/chat/initialization`},
  getProfiles: {method: 'GET', url: `${BASE_API_URL}/u/user/profiles`},
  createProfile: {method: 'POST', url: `${BASE_API_URL}/u/user/profiles`},
  getProfileWithId: {method: 'GET', url: `${BASE_API_URL}/u/user/profiles/{profileId}`},
  updateProfileWithId: {method: 'POST', url: `${BASE_API_URL}/u/user/profiles/{profileId}`},
  removeProfile: {method: 'POST', url: `${BASE_API_URL}/u/user/profiles/{profileId}/remove`},

  getBulkReports: {method: 'GET', url: `${BASE_API_URL}/u/user/bulk/reports`},
  getFavoriteDentist: {method: 'GET', url: `${BASE_API_URL}/u/user/favorite_dentist`},
  removeFavoriteDentist: {method: 'POST', url: `${BASE_API_URL}/u/user/favorite_dentist/{favorite_id}/remove`},
  getPaymentSettings: {method: 'GET', url: `${BASE_API_URL}/u/user/payments`},
  getStripePortal: {method: 'POST', url: `${BASE_API_URL}/u/stripe/customerportal`},
  fetchOmisePayments: {method: 'GET', url: `${BASE_API_URL}/u/user/cards/omise`},
  addOmisePayments: {method: 'POST', url: `${BASE_API_URL}/u/user/cards/omise/add`},
  createOmisePayments: {method: 'POST', url: `${BASE_API_URL}/u/user/cards/omise/pay`},
  removeOmisePayments: {method: 'POST', url: `${BASE_API_URL}/u/user/cards/omise/remove`},
  changeDefaultOmisePayments: {method: 'POST', url: `${BASE_API_URL}/u/user/cards/omise/changedefault`},
  getChatInitial: {method: 'GET', url: `${BASE_API_URL}/u/user/chat/initialization`},
  checkVideoChatToken: {method: 'GET', url: `${BASE_API_URL}/u/user/videochat/checktoken`},
  fetchUserInformation: {method: 'GET', url: `${BASE_API_URL}/u/user/chat/profile/{q_type}/{unique_id}`},
  fetchLatestCheckupwithDentist: {method: 'GET', url: `${BASE_API_URL}/u/user/requests/dentist/{q_type}/{unique_id}`},
  fetchVideoChatList: {method: 'GET', url: `${BASE_API_URL}/u/user/requests/videochats`},
  fetchLatestRequest: {method: 'GET', url: `${BASE_API_URL}/u/user/requests/latest`},
  fetchRequests: {method: 'GET', url: `${BASE_API_URL}/u/user/requests`},
  fetchRecords: {method: 'GET', url: `${BASE_API_URL}/u/user/records`},
  fetchPartialScan: {method: 'GET', url: `${BASE_API_URL}/u/user/record/partialscan/{recordId}`},
  fetchRecordDetail: {method: 'GET', url: `${BASE_API_URL}/u/user/record/{recordId}`},
  fetchRecordSimple: {method: 'GET', url: `${BASE_API_URL}/u/user/record/{recordId}/simple`},
  fetchReport: {method: 'GET', url: `${BASE_API_URL}/u/user/request/{requestId}`},
  createRecordId: {method: 'POST', url: `${BASE_API_URL}/u/user/records`},
  createCheckupRequest: {method: 'POST', url: `${BASE_API_URL}/u/user/requests`},
  uploadToPartialRecord: {method: 'POST', url: `${BASE_API_URL}/u/user/record/partialscan/{recordId}/upload`},
  uploadToRecord: {method: 'POST', url: `${BASE_API_URL}/u/user/record/upload`},
  fetchPaymentPrices: {method: 'GET', url: `${BASE_API_URL}/u/user/payment/prices` },
  fetchUserMemberships: {method: 'GET', url: `${BASE_API_URL}/u/user/memberships` },
  fetchVideoChatToken: {method: 'POST', url: `${BASE_API_URL}/u/user/request/videochat/token` } ,

  requestInspection: {method: 'POST', url: `${BASE_API_URL}/u/user/report/{reportId}/inspect` },
  generateUserReport: {method: 'POST', url: `${BASE_API_URL}/u/user/request/{requestId}/generate` },
  checkUserReportStatus: {method: 'GET', url: `${BASE_API_URL}/u/user/request/{requestId}/generate/status` },
  fetchRequestDates: {method: 'GET', url: `${BASE_API_URL}/u/user/requests/dates` },
  createRequestGenerative: {method: 'POST', url: `${BASE_API_URL}/u/user/requests/generate` },
  createRequestOrphanage: {method: 'POST', url: `${BASE_API_URL}/u/user/requests/orphanage` },

  fetchUploadDetections: {method: 'GET', url: `${BASE_API_URL}/u/user/upload/{uploadId}/detections` } ,

  getSearchRegions: {method: 'GET', url: `${BASE_API_URL}/u/clinics/regions` },
  searchClinics: {method: 'GET', url: `${BASE_API_URL}/u/clinics/search/d` },
  getDentistDetails: {method: 'GET', url: `${BASE_API_URL}/u/clinics/dentist/{dentistId}/profile` },
  getDentistReviews: {method: 'GET', url: `${BASE_API_URL}/u/clinics/dentist/{dentistId}/reviews` },

  updateProfile: {method: 'POST', url: `${BASE_API_URL}/u/user/profile`},
  changePassword:  {method: 'POST', url: `${BASE_API_URL}/auth/password/change/`},

  fetchMembership: {method: 'GET', url: `${BASE_API_URL}/m/user/membership` }, 
  fetchMembershipBenefits: {method: 'GET', url: `${BASE_API_URL}/m/user/membership/benefits` }, 
  fetchMembershipPaymentStatus: {method: 'GET', url: `${BASE_API_URL}/m/user/membership/payment_status` },
  fetchMembershipTreatmentPlans: {method: 'GET', url: `${BASE_API_URL}/m/user/membership/treatments` }, 
  fetchMembershipInvoices: {method: 'GET', url: `${BASE_API_URL}/m/user/membership/invoices` },

  fetchSubscriptionPlans: {method: 'GET', url: `${BASE_API_URL}/m/subscription/plans/{country_code}` },
  fetchMembershipQuote: {method: 'GET', url: `${BASE_API_URL}/m/user/membership/quotes` },
  createMembershipQuote: {method: 'POST', url: `${BASE_API_URL}/m/user/membership/quotes` }, 
  createMembership: {method: 'POST', url: `${BASE_API_URL}/m/user/memberships` }, 
  createMembershipPayment: {method: 'POST', url: `${BASE_API_URL}/m/user/memberships/payment` }, 

  requestSimpleCode: {method: 'POST', url: `${BASE_API_URL}/auth/email/code/request` },
  confirmSimpleCode: {method: 'POST', url: `${BASE_API_URL}/auth/email/code/confirm` },
}

const URLS = {
  requests: {url: "/requests"},
  reportDetail: {url: "/requests/{requestId}"},
  chats: {url: "/chats"},
  videoChat: {url: "/videochat", sideBar:false},
  chatDetail: {url: "/chats/{chatId}"},
  settings: {url: "/settings"},
  settingDetail: {url: "/settings/{settingsId}"},
  profile: {url: "/settings/profile"},
  details: {url: "/settings/profile"},
  profilesdetail: {url: "/settings/profiles?uid={profileId}"},
  profileslist: {url: "/settings/profileslist"},
  dependents: {url: "/settings/profileslist"},
  payments: {url: "/settings/payments"},
  membership_treatment_history: {url: "/settings/membership_treatment_history"},
  membership_payment_history: {url: "/settings/membership_payment_history"},
  favoriteDentist: {url: "/settings/favoriteDentist"},
  notifications: {url: "/settings/notifications"},
  authentication: {url: "/settings/authentication"},
  recordshistory: {url: "/settings/recordshistory"},
  downloadreports: {url: "/settings/downloadreports"},
  openSource: {url: "/opensource/web", sideBar: false},
  openSourceAndroid: {url: "/opensource/anrdoid", sideBar: false},
  helpCenter: {url: "/helpcenter", sideBar: false},
  helpCenter_th: {url: "https://www.pdhprotocols.com/faqs", sideBar: false, external: true},
  helpCenter_jp: {url: "https://www.pdhprotocols.com/faqs", sideBar: false, external: true},
  helpCenter_us: {url: "https://www.pdhprotocols.com/faqs", sideBar: false, external: true},
  terms: {url: "/policies/terms", sideBar: false},
  terms_th: {url: "https://www.pdhprotocols.com/terms", sideBar: false, external: true},
  terms_jp: {url: "https://www.pdhprotocols.com/terms", sideBar: false, external: true},
  terms_us: {url: "https://www.pdhprotocols.com/terms", sideBar: false, external: true},
  privacy: {url: "/policies/privacy", sideBar: false},
  privacy_th: {url: "https://www.pdhprotocols.com/privacy-policy", sideBar: false, external: true},
  privacy_jp: {url: "https://www.pdhprotocols.com/privacy-policy", sideBar: false, external: true},
  privacy_us: {url: "https://www.pdhprotocols.com/privacy-policy", sideBar: false, external: true},
  webViewPrivacy: {url: "/webview/privacy", sideBar: false},
  webViewTerms: {url: "/webview/terms", sideBar: false},
  contactUs: {url: "mailto:support@pdhprotocols.com", sideBar: false},
  login: {url:"/login", sideBar:false},
  reset: {url:"/reset", sideBar:false},
  register: {url: "/register", sideBar:false}
}

const PAGE_URLS = [
  {url: "/", title: "Home", icon: iconHome, iconOn: iconHomeOn, iconOff: iconHomeOff, key: 'home'},
  //{url: "/chats", title: "Chats", icon: iconChat, key: 'chats'},
  {url: "/requests", title: "Reports", icon: iconReport, iconOn: iconReportOn, iconOff: iconReportOff, key: 'requests'},
  {url: "/settings", title: "Settings", icon: iconSettings, iconOn: iconSettingsOn, iconOff: iconSettingsOff, key: 'settings'},
  //{url: "/clinics", title: "Clinics", icon: iconReport, key: 'clinics'}
]

const MEMBERSHIP_PAGE_URLS = [
  {url: "/", title: "Home", icon: iconHome, iconOn: iconHomeOn, iconOff: iconHomeOff, key: 'home'},
  //{url: "/chats", title: "Chats", icon: iconChat, key: 'chats'},
  //{url: "/requests", title: "Reports", icon: iconReport, iconOn: iconReportOn, iconOff: iconReportOff, key: 'requests'},
  {url: "/settings", title: "Settings", icon: iconSettings, iconOn: iconSettingsOn, iconOff: iconSettingsOff, key: 'settings'},
  //{url: "/clinics", title: "Clinics", icon: iconReport, key: 'clinics'}
]


const LANGUAGE_LIST = [
  {key: 'en', label: 'English'},
  {key: 'ja', label: '日本'},
  {key: 'th', label: 'ไทย'}
]

const COLORS_STATUS = {
  PENDING: '#FFA05C',
  COMPLETED: '#35BE9D',
  COMPLETE: '#35BE9D',
  PAYMENT_REQUIRED: '#E95252',
  INSPECTING: '#367FFF'
}

const SETTINGS_LIST = [
  {
    subHeader: 'Profile',
    subHeaderKey: 'user_profile',
    items: [
      {label: 'Personal Details', key: 'details'},
      {label: 'Household', key: 'dependents'},
      //{label: 'My Dentist', key: 'favoriteDentist'},
      {label: 'Uploads', key: 'recordshistory'},
      //{label: 'Payments', key: 'payments'},
    ]
  },
  {
    subHeader: 'User Settings',
    subHeaderKey: 'user_settings',
    items: [
      //{label: 'Notifications', key: 'notifications'},
      {label: 'Authentication & Login', key: 'authentication'},
      {label: 'Language', key: 'switchLanguage'}
    ]
  },
  {
    subHeader: 'About Us',
    subHeaderKey: 'about_us',
    items: [
      {label: 'Privacy', key: 'privacy', external:true},
      {label: 'Terms', key: 'terms', external:true},
    ]
  },
  {
    subHeader: 'Support',
    subHeaderKey: 'support',
    items: [
      {label: 'Help Center', key: 'helpCenter', external:true},
      {label: 'Contact Us', key: 'contactUs', external:true}
    ]
  }
]

const DENTAL_USER_SETTINGS_LIST = [
  {
    subHeader: 'Profile',
    subHeaderKey: 'user_profile',
    items: [
      {label: 'Personal Details', key: 'details'},
      {label: 'Household', key: 'dependents'},
      //{label: 'My Dentist', key: 'favoriteDentist'},
      {label: 'Download Report', key: 'downloadreports'},
      {label: 'Uploads', key: 'recordshistory'},
      //{label: 'Payments', key: 'payments'},
    ]
  },
  {
    subHeader: 'User Settings',
    subHeaderKey: 'user_settings',
    items: [
      //{label: 'Notifications', key: 'notifications'},
      {label: 'Authentication & Login', key: 'authentication'},
      {label: 'Language', key: 'switchLanguage'}
    ]
  },
  {
    subHeader: 'About Us',
    subHeaderKey: 'about_us',
    items: [
      {label: 'Privacy', key: 'privacy', external:true},
      {label: 'Terms', key: 'terms', external:true},
    ]
  },
  {
    subHeader: 'Support',
    subHeaderKey: 'support',
    items: [
      {label: 'Help Center', key: 'helpCenter', external:true},
      {label: 'Contact Us', key: 'contactUs', external:true}
    ]
  }
]

const ORPH_USER_SETTINGS_LIST = [
  {
    subHeader: 'Profile',
    subHeaderKey: 'user_profile',
    items: [
      {label: 'Personal Details', key: 'details'},
      {label: 'Household', key: 'dependents'},
      //{label: 'My Dentist', key: 'favoriteDentist'},
      {label: 'Uploads', key: 'recordshistory'},
      {label: 'Download Report', key: 'downloadreports'},
      //{label: 'Payments', key: 'payments'},
    ]
  },
  {
    subHeader: 'User Settings',
    subHeaderKey: 'user_settings',
    items: [
      //{label: 'Notifications', key: 'notifications'},
      {label: 'Authentication & Login', key: 'authentication'},
      {label: 'Language', key: 'switchLanguage'}
    ]
  },
  {
    subHeader: 'About Us',
    subHeaderKey: 'about_us',
    items: [
      {label: 'Privacy', key: 'privacy', external:true},
      {label: 'Terms', key: 'terms', external:true},
    ]
  },
  {
    subHeader: 'Support',
    subHeaderKey: 'support',
    items: [
      {label: 'Help Center', key: 'helpCenter', external:true},
      {label: 'Contact Us', key: 'contactUs', external:true}
    ]
  }
]

const MEMBERSHIP_USER_SETTINGS_LIST = [

  {
    subHeader: 'Profile',
    subHeaderKey: 'user_profile',
    items: [
      {label: 'Personal Details', key: 'details'},
      //{label: 'Household', key: 'dependents'},
      //{label: 'My Dentist', key: 'favoriteDentist'},
      //{label: 'Uploads', key: 'recordshistory'},
      //{label: 'Download Report', key: 'downloadreports'},
      //{label: 'Payments', key: 'payments'},
      {label: 'Treatment History', key: 'membership_treatment_history'},
      {label: 'Payment History', key: 'membership_payment_history'},
    ]
  },
  {
    subHeader: 'User Settings',
    subHeaderKey: 'user_settings',
    items: [
      //{label: 'Notifications', key: 'notifications'},
      {label: 'Authentication & Login', key: 'authentication'},
      {label: 'Language', key: 'switchLanguage'}
    ]
  },
  {
    subHeader: 'About Us',
    subHeaderKey: 'about_us',
    items: [
      {label: 'Privacy', key: 'privacy', external:true},
      {label: 'Terms', key: 'terms', external:true},
    ]
  },
  {
    subHeader: 'Support',
    subHeaderKey: 'support',
    items: [
      {label: 'Help Center', key: 'helpCenter', external:true},
      {label: 'Contact Us', key: 'contactUs', external:true}
    ]
  }
]


const CURRENCY_FRACTIONDIGITS = {
  "USD": 2,
  "THB": 0,
  "JPY": 0
}

const COLORS_CATEGORIES = {
  'ORTHO': '#00574F',
  'COLOR': '#367FFF',
  'HABITS': '#DAFF00',
  'CARIES': '#DC4E41',
  'TOOTH': '#DC4E41',
  'GUMS': '#FFA500',
  'OTHERS': '#A9A7A7',
  'MULTIPLE': '#405159'
}

const REPORTCHART_TYPES = [
  { label: 'Straightening', value: 'ORTHO', color: '#00574F' },
  { label: 'Habits', value: 'HABITS', color: '#DAFF00' },
  { label: 'Gums', value: 'GUMS', color: '#FFA500' },
  { label: 'Cavities', value: 'CARIES', color: '#DC4E41' },
  { label: 'Tooth', value: 'TOOTH', color: '#DC4E41' },
  { label: 'Color', value: 'COLOR', color: '#367FFF' },
  { label: 'General', value: 'OTHERS', color: '#A9A7A7' },
]

const GENERATIVE_REPORTCHART_TYPES = [
  { label: 'Gums', value: 'GUMS', color: '#FFA500' },
  { label: 'Color', value: 'COLOR', color: '#367FFF' },
]


// RECORD_TYPES.FULLSCAN
// RECORD_TYPES.SELF
const RECORD_TYPES = {
  FULLSCAN: 'FULLSCAN',
  SELF: 'SELF',
  PARTIALSC: 'PARTIALSC',
  DETECTIONSC: 'DETECTIONSC',
  SINGLE: 'SINGLE',
  ATTACHMENT: 'ATTACHMENT'
}

const REQUEST_TYPES = {
  REPORT: 'REPORT',
  VIDEOCHAT: 'VIDEOCHAT'
}

const LOCATIONS = [
  {'value': 'CALIFORNIA', 'label': 'California'},
  {'value': 'NEW_YORK', 'label': 'New York'},
  {'value': 'OTHER', 'label': 'Other'}
]

const FULLSCAN_STATES = {
  START: 'START',
  SCANNING: 'SCANNING',
  FINISHED: 'FINISHED'
}

const MAP_CENTERS = {
  th: {
    lat: 13.747145208086701,
    lng: 100.50224734643409
  },
  jp: {
    lat: 35.6814034,
    lng: 139.7413768
  },
  us: {
    lat: 34.0453855,
    lng: -118.2996791
  },
  us_ca: {
    lat: 34.0453855,
    lng: -118.2996791
  },
  us_ny: {
    lat:40.7437902,
    lng: -74.001601
  }
}

const MOMENT_DATE_FORMAT = "L"
const TIME_FORMAT = 'h:mm a';
const DETECTION_VIDEO_TIME_LIMIT = 15;
const VIDEO_TIME_LIMIT = 30;

const URLS_OLD = {
  home: {url:'/', title: 'Home' },
  login: {url:'/login', title: 'Login', sidebar: false, navbar: false},
  noMatch: {url:'/noMatch', title: 'Not Found', sidebar:false}
}

const USER_TYPES = {
  ENDUSER: 'ENDU',
  ORPHANAGE: 'ORPH',
  DENTAL: 'DENT',
  BEFOREDENT: 'BEFO',
  STAFFDENTIST: 'BDEN',
  SYSTEM: 'SYST'
}

const GENDERS = [
  {'value': 'MALE', 'label': 'MALE'},
  {'value': 'FEMALE', 'label': 'FEMALE'},
  {'value': 'OTHER', 'label': 'OTHER'}
]

const TOOTH_NUMBERING_SYSTEM = {
  'palmer': {
    "t11": "11",
    "t12": "12",
    "t13": "13",
    "t14": "14",
    "t15": "15",
    "t16": "16",
    "t17": "17",
    "t18": "18",

    "t21": "21",
    "t22": "22",
    "t23": "23",
    "t24": "24",
    "t25": "25",
    "t26": "26",
    "t27": "27",
    "t28": "28",

    "t31": "31",
    "t32": "32",
    "t33": "33",
    "t34": "34",
    "t35": "35",
    "t36": "36",
    "t37": "37",
    "t38": "38",

    "t41": "41",
    "t42": "42",
    "t43": "43",
    "t44": "44",
    "t45": "45",
    "t46": "46",
    "t47": "47",
    "t48": "48"
  },
  'international':{
    "t11": "8",
    "t12": "7",
    "t13": "6",
    "t14": "5",
    "t15": "4",
    "t16": "3",
    "t17": "2",
    "t18": "1",

    "t21": "9",
    "t22": "10",
    "t23": "11",
    "t24": "12",
    "t25": "13",
    "t26": "14",
    "t27": "15",
    "t28": "16",

    "t31": "24",
    "t32": "23",
    "t33": "22",
    "t34": "21",
    "t35": "20",
    "t36": "19",
    "t37": "18",
    "t38": "17",

    "t41": "25",
    "t42": "26",
    "t43": "27",
    "t44": "28",
    "t45": "29",
    "t46": "30",
    "t47": "31",
    "t48": "32"
  }
}

const REGEX_ONLY_TOOTH = /t[0-9]{1,2}(?:(?=(o|bm|bd|ld|lm|l|b){1}$))/g;
const REGEX_ONLY_REGION = /(o|bm|bd|ld|lm|l|b){1}/g;

export {
  BASE_URL,
  API_URLS,
  REACT_APP_PUBLIC_URL,
  FIREBASE_CONFIG,
  DEVICE_NAME,
  DEVICE_NAME_DESKTOP,
  DEVICE_NAME2,
  GOOGLE_CLIENT_ID,
  FACEBOOK_CLIENT_ID,
  CURRENCY_FRACTIONDIGITS,
  OPENTOK_API_KEY,
  US_STRIPE_KEY,
  JP_STRIPE_KEY,
  FIREBASE_PUSH_KEY,
  URLS,
  PAGE_URLS,
  MEMBERSHIP_PAGE_URLS,
  TOOTH_NUMBERING_SYSTEM,
  MOMENT_DATE_FORMAT,
  USER_TYPES,
  RECORD_TYPES,
  REPORTCHART_TYPES,
  GENERATIVE_REPORTCHART_TYPES,
  REGEX_ONLY_TOOTH,
  REGEX_ONLY_REGION,
  REQUEST_TYPES,
  LOCATIONS,
  FULLSCAN_STATES,
  COLORS_CATEGORIES,
  COLORS_STATUS,
  MAP_CENTERS,
  LANGUAGE_LIST,
  MEMBERSHIP_USER_SETTINGS_LIST,
  DENTAL_USER_SETTINGS_LIST,
  ORPH_USER_SETTINGS_LIST,
  SETTINGS_LIST,
  GENDERS,
  TIME_FORMAT,
  API_DATE_FORMAT,
  API_DATETIME_FORMAT,
  APPLE_WEBSERVICEURL,
  APPLE_WEBSITEPUSHID,
  DETECTION_VIDEO_TIME_LIMIT,
  VIDEO_TIME_LIMIT
}
