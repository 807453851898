import { useMutation } from 'react-query';
import { queryConfig, queryClient } from '../../_helpers/react-query';

import { confirmSimpleCode as save } from './services';

import { QUERY_KEY_NAMES } from './constants';

const saveQuery = async ({ data }) => {
    return save(data);
}

export const useConfirmSimpleCode = () => {
    return useMutation({
        mutationKey: QUERY_KEY_NAMES.CONFIRM_SIMPLE_CODE,
        mutationFn: (data) => {        
            return saveQuery({ data });
        },
    });
}