import React from 'react';
import { Box, Heading } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { PlanPricingList } from '../components/PlanPricingList';
import { CountryProvider } from 'src/_libs/useCountry';
import { HeadingContainer } from '../components/common';

export const PlanPricingPage = () => { 

    return (
        <Box mt={['80px', '100px']} px={['20px', '50px']} pb={['50px', 0]}>
            <HeadingContainer >
                <FormattedMessage 
                    id={'membershipPage.plansList.title'}
                    defaultMessage={'Pricing Plans'}
                />
            </HeadingContainer>
            <CountryProvider>
                <PlanPricingList />
            </CountryProvider>
        </Box>
    )
}
