import create from 'zustand';

export const PAYMENT_STEP_PAGES = {
    PRIMARY: 'payment_option',
    BILLING_CYCLE: 'billing_cycle',
    REVIEW: 'review',
}

export const usePaymentStore = create((set) => ({
  page: PAYMENT_STEP_PAGES.BILLING_CYCLE,
  amount: null,
  nonce: null,
  billingCycle: null,
  paymentMethod: null,
    setPaymentMethod: (paymentMethod) => set({ paymentMethod }),
    setBillingCycle: (billingCycle) => set({ billingCycle }),
    setAmount: (amount) => set({ amount }),
    setNonce: (nonce) => set({ nonce }),
    setPage: (page) => set({ page }),
}));