import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { GetQuotePage } from './GetQuotePage';
import { PlanPricingPage } from './PlanPricingPage';
import { useAuth } from '../../Authentication/useAuth';
import { CountryProvider } from 'src/_libs/useCountry';
import { ApplicationPages } from './ApplicationPages';
import { PaymentPages } from './PaymentPages';

  
export const Routes = () => {
    return (
      <CountryProvider>
          <Switch>
              <Route exact path={"/plans"}>
                  <PlanPricingPage />
              </Route>
              <Route exact path={["/preonb/payment/:pageId", "/preonb/payment"]}>
                  <PaymentPages />
              </Route>
              <Route exact path={["/preonb/apply/:pageId", "/preonb/apply"]}>
                  <ApplicationPages />
              </Route>
              <Route exact path={["/preonb/quotes/:pageId", "/preonb/quotes", "/preonb"]}>
                  <GetQuotePage />
              </Route>
          </Switch>
      </CountryProvider>
    )
}