import React from 'react';

export const ChevronRightIcon = (props) => {
  const height = props?.height || 14;
  const width = props?.width || 14;
  const color = props?.color || '#273238';
  return(
    <svg width={width} height={height} color={color} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.90577 1.74965L10.2666 7.11048L4.82177 12.5553L4.07919 11.8121L8.78143 7.11048L4.16319 2.49223L4.90577 1.74965Z" 
      fill="currentColor"/>
    </svg>
  )
}



