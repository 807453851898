import React, { useEffect } from 'react';
import { Box, Flex, Image, Button, VStack, Center, Text , useDisclosure,
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { InputComponent } from 'src/_components/Forms/InputComponent';
import { Form, Field } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { isFinalFormMetaInvalid, getFinalFormMetaError, required, validateConfirmPassword, validatePassword } from 'src/_helpers/forms';
import pdhLogo from 'src/_images/logoPDHPrimary.png';
import { useRequestEmailCode } from '../api/requestEmailCode';
import { useRegisterPassword } from '../api/registerPassword';
import { useAuth } from 'src/Authentication/useAuth';
import { constants as authConstants } from 'src/Authentication/_constants';
import { useDispatch } from 'react-redux';
import storage from 'src/_helpers/storage';

const validateBeforeCode = (values) => {
    const errors = {};
    if (required(values.email)){
        errors.email = required(values.email);
    }
    if (validatePassword(values.new_password1, values.email)){
        errors.new_password1 = validatePassword(values.new_password1, values.email);
    }
    if (validateConfirmPassword(values.new_password1, values.new_password2)){
        errors.new_password1 = validateConfirmPassword(values.new_password1, values.new_password2);
    }
    if (Object.keys(errors).length > 0) { 
        return errors;
    }
    return null;
};

const CodeModal = ({email, isOpen, onClose, handleSubmit, isSubmitting}) => {
    const { formatMessage } = useIntl();
    const requestCodeMutation = useRequestEmailCode();

    const onResendCode = () => {
        const numOfSeconds = 15;
        // if submittedAt timestamp is les than 10- seconds ago, then don't resend code
        if (requestCodeMutation.submittedAt){
            const currentTime = new Date().getTime();
            const timeDiff = currentTime - requestCodeMutation.submittedAt;
            if (timeDiff < numOfSeconds * 1000) {
                return;
            }
        }
        requestCodeMutation.mutate({email });
        // start a countdown of 10 seconds to shwo on the UT
    }

    return (

        <Modal isOpen={isOpen} onClose={onClose}  closeOnOverlayClick={false} isCentered>
            <ModalOverlay />
            <ModalContent p={['20px 30px']}>
                <ModalHeader>
                    <Text>
                        <FormattedMessage
                            id={'passwordRegisterPage.codeModal.title'}
                            defaultMessage={'Enter Code'}
                        />
                    </Text>
                </ModalHeader>
                <ModalBody>
                        <Text pb={[4]}>
                            <FormattedMessage
                                id={'passwordRegisterPage.codeModal.description'}
                                defaultMessage={'We have sent a code to your email {email}. Please enter the code below.'}
                                values={{email}}
                            />
                        </Text>
                        <Field name="code" validate={required}>
                            {({input, meta}) => (
                                <InputComponent 
                                    {...input}
                                    stackProps={{w: ['full']}}
                                    label={formatMessage({id: 'passwordRegisterPage.code.label', defaultMessage: 'Code'})}
                                    placeholder={formatMessage({id: 'passwordRegisterPage.code.placeholder', defaultMessage: 'Code'})}
                                    isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                    error={getFinalFormMetaError(meta)}
                                />
                            )}
                        </Field>
                        <Flex pt={[2]} alignItems={['center']}>
                            <Text mr={[3]} fontSize={[14]} color={'#405159'}>
                                <FormattedMessage
                                    id={'passwordRegisterPage.codeModal.resend.text'}
                                    defaultMessage={'Didn\'t receive the code?'}
                                />
                            </Text>
                            <Button 
                                type='button'
                                size={['sm']} 
                                variant='link' 
                                onClick={onResendCode}
                                isDisabled={requestCodeMutation.isLoading}
                            >
                                <FormattedMessage
                                    id={'passwordRegisterPage.codeModal.resend.link'}
                                    defaultMessage={'Resend'}
                                />
                            </Button>
                        </Flex>
                </ModalBody>
                <ModalFooter justify={['flex-end']}>
                    <Button type='button' onClick={handleSubmit} isLoading={isSubmitting}>
                        <FormattedMessage
                            id={'passwordRegisterPage.codeModal.submit'}
                            defaultMessage={'Submit'}
                        />
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export const PasswordRegisterPage = () => {
    const { formatMessage } = useIntl();
    const [ errors, setErrors ] = React.useState({});
    const formRef = React.useRef();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const history = useHistory();
    const requestCodeMutation = useRequestEmailCode();
    const dispatch = useDispatch();
    const auth = useAuth();

    useEffect(() => {
        return () => {
        }
    }, [])

    const initialValues = {
        email: storage.get('register_email') || '',
    }

    const handleLogoClick = () => {
        history.push('/')
    }
    

    const handleRequestCode = async (values) => {
        setErrors({});
        try{
            const resp = await requestCodeMutation.mutateAsync({email: values?.email})
            storage.remove('register_email');
            onOpen();
            return null;
        } catch (err){
            setErrors(err);
            return {
                ...err
            }
        }
    }

    const registerPassword = async (values) => {
        setErrors({});
        const errors = validateBeforeCode(values);
        if (errors){
            return errors;
        }
        try{
            const registrationResp = await auth.registerNewPassword(values);
            dispatch( { type: authConstants.LOGIN_SUCCESS, data: registrationResp});
            console.log('registrationResp', registrationResp)
            if (history.location.state != null && history.location.state.from != null){
              history.push(history.location.state.from.pathname)
            } else {
              history.push('/');
            }
        } catch (err){
            if (err?.email || err?.new_password1 || err?.new_password2){
                onClose();
            } 
            return {
                ...err
            }
        }
    }

    return (
        <Flex
            bg={['#F5F5F5']}
            height={['100vh']}
            w={['full']}
            justifyContent={['center']}
            alignItems={['center']}
            px={[0, 0]}
            position={['relative']}
        >
            <Box
                bg={['white']}
                borderRadius={['30px']}
                padding={['40px 50px', '50px 60px']}
                w={['100%', '450px']}
                position={['relative']}
            >
                <Box 
                    w={['100%', '450px']}
                    position={['absolute']}
                    top={['-100px', '-100px']}
                    right={['0']}
                    left-={['0']}
                    textAlign={['center']}
                    onClick={handleLogoClick}
                    _hover={{cursor: 'pointer'}}
                >
                    <Center grow={1} justify={'center'}>
                        <Image src={pdhLogo} alt="logo" w={['78px']} mx={'auto'} />
                    </Center>
                </Box>
                <Form
                    onSubmit={registerPassword}
                    initialValues={initialValues}
                    mutators={{ 
                        setFieldValue: ([field, value], state, { changeValue }) => {
                            changeValue(state, field, () => value)
                        },
                        setFieldError: (field, error) => (state, { changeValue }) => {
                            state.fields[field] = error;
                            state.fields[field].touched = true;
                        },
                    }}
                    render = {({
                            submitting, handleSubmit, values, form
                        }) => {
                            formRef.current = form;
                            return (
                                <Box as={'form'} id={'createPasswordForm'} onSubmit={handleSubmit} mt={['0px']} w={['full']} >
                                    <Text fontSize={['30px']} textAlign={['center']}>
                                        <FormattedMessage 
                                            id={'passwordRegisterPage.title'}
                                            defaultMessage={'Create a Password'}
                                        />
                                    </Text>
                                    <CodeModal 
                                        email={values.email}
                                        isOpen={isOpen} 
                                        onClose={onClose} 
                                        handleSubmit={() => form.submit()}
                                        isSubmitting={submitting}
                                    />
                                    <VStack spacing={4} mt={[4]} w={['full']}>
                                        <Field name="email" validate={required}>
                                            {({input, meta}) => {
                                                return (
                                                    <InputComponent 
                                                        {...input}
                                                        stackProps={{w: ['full']}}
                                                        label={formatMessage({id: 'passwordRegisterPage.email.label', defaultMessage: 'Email'})}
                                                        placeholder={formatMessage({id: 'passwordRegisterPage.email.placeholder', defaultMessage: 'Email'})}
                                                        isInvalid={isFinalFormMetaInvalid(meta, errors[input?.name]) ? true : false}
                                                        error={getFinalFormMetaError(meta, errors[input?.name])}
                                                    />
                                                )
                                            }}
                                        </Field>
                                        <Field name="new_password1" 
                                            validate={(value) => validatePassword(value, values.email)}
                                        >
                                            {({input, meta}) => {
                                                return (
                                                    <InputComponent 
                                                        {...input}
                                                        type='password'
                                                        stackProps={{w: ['full']}}
                                                        label={formatMessage({id: 'passwordRegisterPage.new_password1.label', defaultMessage: 'Password'})}
                                                        placeholder={formatMessage({id: 'passwordRegisterPage.new_password1.placeholder', defaultMessage: 'Password'})}
                                                        isInvalid={isFinalFormMetaInvalid(meta, errors[input?.name]) ? true : false}
                                                        error={getFinalFormMetaError(meta, errors[input?.name])}
                                                    />
                                                )  
                                            }}
                                        </Field>
                                        <Field name="new_password2"
                                            validate={(value) =>
                                                validateConfirmPassword(values.new_password1, value)
                                            }
                                        >
                                            {({input, meta}) => {
                                                return (
                                                    <InputComponent 
                                                        {...input}
                                                        type='password'
                                                        stackProps={{w: ['full']}}
                                                        label={formatMessage({id: 'passwordRegisterPage.new_password2.label', defaultMessage: 'Confirm Password'})}
                                                        placeholder={formatMessage({id: 'passwordRegisterPage.new_password2.placeholder', defaultMessage: 'Confirm Password'})}
                                                        isInvalid={isFinalFormMetaInvalid(meta, errors[input?.name]) ? true : false}
                                                        error={getFinalFormMetaError(meta, errors[input?.name])}
                                                    />
                                                )
                                            }}
                                        </Field>
                                        <Text 
                                            fontSize={['12px']} 
                                            color={['#405159']}
                                            whiteSpace={'pre'}
                                            alignSelf={['flex-start']}
                                        >
                                            <FormattedMessage 
                                                id={'registration.form.password.requirements'}
                                            />
                                        </Text>
                                    </VStack>
                                    <Box mt={8}>
                                        <Button 
                                            onClick={() => handleRequestCode(values)}
                                            type="button" 
                                            w={['full']}
                                            isLoading={requestCodeMutation.isLoading}
                                        >
                                            <FormattedMessage 
                                                id={'passwordRegisterPage.submit'}
                                                defaultMessage={'Create Password'}
                                            />
                                        </Button>
                                    </Box>
                                </Box>
                            )

                        }
                    }
                />
            </Box>
        </Flex>
    )
}