import { API_DATE_FORMAT, URLS } from '../_config';
import moment from 'moment';
import { mapValues } from 'lodash';
import { getDistance } from 'geolib';

export const isMembershipUser = (apps) => {
  if ((apps || []).includes('membership')){
      return true
  } else { //includes('open_requests') 
      return false
  } 
}

export const isScreeningUser = (apps) => {
  if ((apps || []).includes('screenings')){ //includes('open_requests') 
      return true
  } else {
      return false
  }
}

export const formatName = (formatString, givenName="", familyName="") => {
  if (givenName && familyName){
    return formatString.replace('{givenName}', givenName).replace('{familyName}', familyName);
  } else if (givenName){
    return givenName;
  } else if (familyName){
    return familyName;
  } else {
    return ""
  }
}

export const extractInitials = (name) => {
    name = String(name).trim();

    if (!name) {
        return '';
    }

    // Split the name into words
    const words = name.split(/\s+/);

    // Get the first word
    const firstWord = words[0];

    // Get the last word (which could be the same as the first word if there's only one word)
    const lastWord = words[words.length - 1];

    // Get the first letter of the first word and the first letter of the last word
    const initials = `${firstWord.charAt(0)}${lastWord.charAt(0)}`;

    return initials;
}

export function sleep(ms) {
return new Promise(resolve => setTimeout(resolve, ms));
}

function distanceToString(loc1, loc2){
  var dist = getDistance(loc1, loc2);
  if (dist > 1000){
    return `${dist/1000} km`;
  } else {
    return `${dist} m`;
  }
}

function toFixed(value, precision) {
    var power = Math.pow(10, precision || 0);
    return String(Math.round(value * power) / power);
}

export const bytesToString = (bytes) => {
  if (!bytes){
    return "0";
  }
  let size = parseFloat(bytes);
  let convAmount = 1024.0;
  if (size/convAmount/convAmount/convAmount > 1.0){
      return toFixed(size/convAmount/convAmount/convAmount, 1) + " GB";
  } else if (size/convAmount/convAmount > 1.0){
      return toFixed(size/convAmount/convAmount, 1) + " MB";
  } else if (size/convAmount > 1.0){
      return toFixed(size/convAmount, 1) + " kb";
  } else {
      return toFixed(size, 1) + " bytes";
  }
}

export const checkIfSidebar = (pathname) => {
  let nonSidebar = Object.values(URLS)
    .filter(item => item.sideBar === false)
    .map(item =>  item.url);
  if (nonSidebar.indexOf(pathname) != -1){
    return false;
  } return true;
}

export const convertToAPIValues = (values) => {
  return mapValues(values, (obj)=>{
    if (moment.isMoment(obj)){
      return obj.format(API_DATE_FORMAT)
    }
    if (moment.isDate(obj)){
      return moment(obj).format(API_DATE_FORMAT)
    }
    if (obj == null){
      return obj
    }
    if(obj.value === undefined){
      return obj
    } else {
      return obj.value
    }
  })
}

export const convertStripeAmountToText = (amount, currency) => {
  if (currency.toLowerCase() == "jpy"){
    return amount
  } else {
    return amount/100
  }
}

export const isEmpty = (val, isInt) => (
  (isInt && val < 1) || val == null || val == "" || val == undefined
);

export const required = value => (value ? undefined : "required");
export const mustBeNumber = value => (isNaN(value) ? "number" : undefined);
export const minValue = min => value =>
  isNaN(value) || value >= min ? undefined : `>${min}`;
export const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined);
