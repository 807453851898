import React from 'react';
import { Input,Flex, Button,  InputGroup, InputLeftElement, InputRightElement, Textarea, FormControl, FormLabel, VStack } from '@chakra-ui/react';
import { PhoneInput } from './PhoneInput';
import { FormattedMessage } from 'react-intl';
import { FormattedFormErrorMessage } from './FormattedErrorMessage';

export const InputComponent = ({ label, placeholder, name, type="text", value, isInvalid, error, onChange, stackProps={}, defaultCountry=null, inputLeftElement=null, inputRightElement=null, isDisabled=false, onPaste=null, max=null }) => {
    const [ show , setShow ] = React.useState(false);
    if (type == "textarea"){
        return (
            <VStack {...stackProps}>
                <FormControl id={name} isInvalid={isInvalid}>
                    <FormLabel>{label}</FormLabel>
                    <Textarea placeholder={placeholder} type={type} value={value} onChange={onChange} isInvalid={isInvalid} isDisabled={isDisabled} onPaste={onPaste} />
                    <FormattedFormErrorMessage error={error} />
                </FormControl>
            </VStack>
        )
    } else if (type == "phonenumber"){ 
        return (
            <VStack {...stackProps}>
                <FormControl id={name} isInvalid={isInvalid}>
                    <FormLabel>{label}</FormLabel>
                    <PhoneInput 
                        placeholder={placeholder} 
                        value={value} 
                        onChange={onChange} 
                        onPaste={onPaste} 
                        defaultCountry={defaultCountry}
                        isInvalid={isInvalid} 
                        isDisabled={isDisabled} />
                    <FormattedFormErrorMessage error={error} />
                </FormControl>
            </VStack>
        )
    } else if (type == "password"){
        return (
            <VStack {...stackProps}>
                <FormControl id={name} isInvalid={isInvalid}>
                    <FormLabel>{label}</FormLabel>
                    <InputGroup>
                        <Input placeholder={placeholder}  max={max} 
                            type={show ? "text" : "password"} 
                            value={value} onChange={onChange} isInvalid={isInvalid} isDisabled={isDisabled}  onPaste={onPaste} />

                        <InputRightElement mr={['20px']} >
                            <Flex onClick={() => setShow(!show)} >
                                <Button size={"sm"} variant={"ghost"}>
                                    {show ? (
                                        <FormattedMessage
                                            id={'passwordRegisterPage.password.hide'}
                                            defaultMessage={'Hide'}
                                        />
                                    ): (
                                        <FormattedMessage
                                            id={'passwordRegisterPage.password.show'}
                                            defaultMessage={'Show'}
                                        />
                                    )}
                                </Button>
                            </Flex>
                        </InputRightElement>
                    </InputGroup>
                    <FormattedFormErrorMessage error={error} />
                </FormControl>
            </VStack>
        )
    } else {
        if (inputRightElement && inputLeftElement){
            return (
                <VStack {...stackProps}>
                    <FormControl id={name} isInvalid={isInvalid}>
                        <FormLabel>{label}</FormLabel>
                        <InputGroup>
                            <InputLeftElement
                                pointerEvents='none'
                                color='gray.300'
                            >
                                {inputLeftElement}
                            </InputLeftElement>
                            <Input placeholder={placeholder}  max={max} type={type} value={value} onChange={onChange} isInvalid={isInvalid} isDisabled={isDisabled}  onPaste={onPaste}  />

                            <InputRightElement
                                pointerEvents='none'
                                color='gray.300'
                            >
                                {inputRightElement}
                            </InputRightElement>
                        </InputGroup>
                        <FormattedFormErrorMessage error={error} />
                    </FormControl>
                </VStack>
            )
        } else if (inputRightElement){  
            return (
                <VStack {...stackProps}>
                    <FormControl id={name} isInvalid={isInvalid}>
                        <FormLabel>{label}</FormLabel>
                        <InputGroup>
                            <Input placeholder={placeholder}  max={max} type={type} value={value} onChange={onChange} isInvalid={isInvalid} isDisabled={isDisabled}  onPaste={onPaste} />

                            <InputRightElement
                                pointerEvents='none'
                                color='gray.300'
                            >
                                {inputRightElement}
                            </InputRightElement>
                        </InputGroup>
                        <FormattedFormErrorMessage error={error} />
                    </FormControl>
                </VStack>
            )

        } else if (inputLeftElement){  
            return (
                <VStack {...stackProps}>
                    <FormControl id={name} isInvalid={isInvalid}>
                        <FormLabel>{label}</FormLabel>
                        <InputGroup>
                            <InputLeftElement
                                pointerEvents='none'
                                color='gray.300'
                            >
                                {inputLeftElement}
                            </InputLeftElement>
                            <Input placeholder={placeholder}  max={max} type={type} value={value} onChange={onChange} isInvalid={isInvalid} isDisabled={isDisabled}  onPaste={onPaste} />

                        </InputGroup>
                        <FormattedFormErrorMessage error={error} />
                    </FormControl>
                </VStack>
            )

        } else {
            return (
                <VStack {...stackProps}>
                    <FormControl id={name} isInvalid={isInvalid}>
                        <FormLabel>{label}</FormLabel>
                        <Input placeholder={placeholder} max={max} type={type} value={value} onChange={onChange} isInvalid={isInvalid} isDisabled={isDisabled}  onPaste={onPaste} />
                        <FormattedFormErrorMessage error={error} />
                    </FormControl>
                </VStack>
            )
        }
    }
}