import create from 'zustand';

const useSteps = create((set) => ({
  current: 0,
  total: 4, // Set the default number of steps here or make it configurable
  setTotal: (steps) => set({ total: steps }),

  next: () =>
    set((state) => ({
        current: Math.min(state.current + 1, state.total - 1),
    })),

  prev: () =>
    set((state) => ({
        current: Math.max(state.current - 1, 0),
    })),

  jump: (step) =>
    set((state) => ({
        current: step >= 0 && step < state.total ? step : state.current,
    })),

  isFirst: (state) => state.current === 0,
  isLast: (state) => (state) => state.current === state.total - 1,
}));

export default useSteps;