import React, { useEffect } from 'react';
import styled from 'styled-components';
import { StyledMain } from '../_styles/common';
import { NavigationBar } from '../NavigationBar';
import { NavBar } from './NavBar';
import { SideNav } from '../SideNav';
import { Scrollbars } from 'react-custom-scrollbars';
import { Container, Box } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

const StyledMainPublic = styled.div`
  display:grid;
  grid-template-columns: 1fr;
  height: 100vh;
  background-color: #F7F7F7;
`;

export const MainLayoutPublic = ({ children, hasSideBar=false, noNavBar=false }) => {
  const scrollRef = React.useRef(null);
  const location = useLocation();
  
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollToTop();
    }
  }, [location]); // Runs every time the route changes


  return (
    <div>
      {!noNavBar && <NavigationBar />}
      <StyledMainPublic showSidebar={hasSideBar}>
        <Scrollbars ref={scrollRef} autoHide style={{height: '100%'}}>
          {children}
        </Scrollbars>
      </StyledMainPublic>
    </div>
  );
};

export const MainLayout = ({ children, hasSideBar, noNavBar }) => {
  const scrollRef = React.useRef(null);
  const location = useLocation();

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollToTop();
    }
  }, [location]); // Runs every time the route changes

  return (
    <div>
      {!noNavBar && <NavigationBar />}
      <StyledMain showSidebar={hasSideBar}>
        { hasSideBar ? <SideNav /> : <Box />}
        <Scrollbars ref={scrollRef} autoHide style={{height: '100%'}}>
          {children}
        </Scrollbars>
      </StyledMain>
    </div>
  );
};

export const ChakraLayout = ({ children }) => {
  return (
    <Box minH="100vh" display="flex" flexDirection="column">
      <NavBar /> 
      <Container maxW="container.lg" pt={4}>
        {children}
      </Container>
    </Box>
  );
};
