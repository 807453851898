import React from 'react';
import { useHistory } from 'react-router-dom';
import { Stack, Box, Badge, Text, Divider, VStack, HStack, Button, Select } from '@chakra-ui/react';
import { useSubscriptionPlanCollection } from '../api/getSubscriptionPlans';
import CenteredSpinner from 'src/_components/CenteredSpinner';
import { formatCurrencyAmount, getCurrencySymbol } from 'src/Membership/utils';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSearchParamsObject } from 'src/_libs/useQueryParams';
import { useCountry } from 'src/_libs/useCountry';
import { usePlanStore } from '../state/plans';


const getBillingCycleText = (billing_cycle, formatMessage) => {
    switch (billing_cycle) {
        case 'annual':
            return formatMessage({ id: 'membershipPage.plansList.annual', defaultMessage: 'year' });
        case 'monthly':
            return formatMessage({ id: 'membershipPage.plansList.monthly', defaultMessage: 'month' });
        default:
            return '';
    }
}

const PlanPricingCard = ({plan, billing_cycle="annual"}) => {
    const { formatMessage } = useIntl();
    const setPlan = usePlanStore(state => state.setPlan);
    const history = useHistory();
    const price = (plan.prices || [])?.find(price => price.billing_cycle === billing_cycle);

    const handleClick = () => {
        setPlan({"code": plan.code ,"billing_cycle": billing_cycle});
        history.push(`/quotes?plan=${plan.code}&billing_cycle=${billing_cycle}`);
    }

    return (
        <Box
          borderWidth="1px"
          borderRadius="md"
          padding="20px"
          boxShadow="md"
          textAlign="center"
          backgroundColor="white"
        >
          <Badge bg={'#527499'} mb={2} color={'white'}>
            {plan.name}
          </Badge>
          <Text fontSize="2xl" fontWeight="bold">
            {`${getCurrencySymbol(plan.currency)} ${formatCurrencyAmount(price.amount)} / ${getBillingCycleText(price.billing_cycle, formatMessage)}`}
          </Text>
            <Text color="gray.500" fontSize={[20]} mb={4}>
                {plan.description}
            </Text>
            <Divider mb={4} />
            <VStack spacing={2} align="stretch">
                {(plan.features || []).map((feature, idx) => (
                <HStack key={idx} justifyContent="flex-start">
                    <Text color="gray.600" fontSize="sm">
                        {feature}
                    </Text>
                </HStack>
                ))}
            </VStack>
          <Button
            mt={2}
            colorScheme="teal"
            size="md"
            onClick={() => handleClick(price.code)}
          >
            <FormattedMessage id="membershipPage.plansList.choosePlan" defaultMessage="Choose" />
            &nbsp;
            {plan.name}
          </Button>
        </Box>
    )
}

const CountrySelector = ({ country, onSelect }) => {
    const { formatMessage } = useIntl();
    return (
        <Box>
            <Select
                mt={4}
                value={country}
                onChange={(e) => onSelect(e.target.value)}
                placeholder={formatMessage({ id: 'membershipPage.plansList.selectCountry', defaultMessage: 'Select Country' })}
            >
                <option value="IN">{formatMessage({id: 'countryName.IN', defaultMessage: 'India'})}</option>
                <option value="TH">{formatMessage({id: 'countryName.TH', defaultMessage: 'Thailand'})}</option>
            </Select>
        </Box>
    )
}

export const PlanPricingList = ({values, isShowing=false}) => {
    const { country: countryCode, updateCountry, isLoading: isCountryLoading } = useCountry();
    const { data, isLoading, isFetching } = useSubscriptionPlanCollection({ countryCode });
    const { country : queryCountry } = useSearchParamsObject();

    // Override country if query parameter exists
    React.useEffect(() => {
        if (queryCountry) {
            updateCountry(queryCountry); // Update the country context
        }
    }, [queryCountry, updateCountry]);

console.log(values)
    if (isCountryLoading || isLoading || isFetching) {
        return (<Box><CenteredSpinner /></Box>)
    }

    return (
        <Box display={isShowing ? 'box' : 'none'} py={['10px', '20px']} pr={[0, '40px']} pt={['20px']} minW={['100%', '150px']}>
            <Stack direction={['column', 'row']} spacing={['20px']} mt={4}>
                {(data?.plans || []).map((plan) => (
                    <PlanPricingCard key={plan?.code} plan={plan} />
                ))}
            </Stack>
        </Box>
    );
}