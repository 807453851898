import React, { createContext, useContext, useEffect, useState } from 'react';
import { idbStorage } from 'src/_helpers/storage';

const CountryContext = createContext();

export const CountryProvider = ({ children }) => {
    const [ country, setCountry ] = useState(null);
    const [isLoading, setIsLoading] = useState(true); // Add isLoading state

    useEffect(() => {
        const fetchCountry = async () => {
            setIsLoading(true);
            let determinedCountry = await idbStorage.getCountry();
      
            // If we don't have a country stored, attempt to guess from browser locale
            if (!determinedCountry) {
              const locale = navigator.language || navigator.userLanguage; 
              // Example logic: if user’s locale is “th-TH”, default to “TH”; if “en-IN”, default to “IN”, etc.
              // Adjust logic to match your actual use-cases or available countries:
              if (locale.toLowerCase().includes('th')) {
                determinedCountry = 'TH';
              } else if (locale.toLowerCase().includes('in')) {
                determinedCountry = 'IN';
              } else {
                // If you have a general fallback, set it here (e.g., "US")
                determinedCountry = null;
              }
            }
      
            // Update state
            setCountry(determinedCountry);
            setIsLoading(false);
          };
        fetchCountry();
      }, []);

    const updateCountry = async (countryCode) => {
        setIsLoading(true); // Set loading to true when updating
        await idbStorage.setCountry(countryCode);
        setCountry(countryCode);
        setIsLoading(false); // Set loading to false when update completes
    }

    return (
        <CountryContext.Provider value={{ country, updateCountry, isLoading }}>
            {children}
        </CountryContext.Provider>
    );
};

export const useCountry = () => useContext(CountryContext);