import React from 'react';
import { Box, Flex, Text, Button } from '@chakra-ui/react';
import { Field } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useCountry } from 'src/_libs/useCountry';
import { useQuotesStore, STEP_PAGES } from '../state/quotes';
import { mustBePhoneNumber } from 'src/_helpers/forms';
import { InputComponent } from 'src/_components/Forms/InputComponent';
import { ButtonStack, ContentContainer } from './common';
import { isFinalFormMetaInvalid, getFinalFormMetaError, required } from 'src/_helpers/forms';
import { useAppFeatures } from 'src/Settings/api/getAppFeatures';
import CenteredSpinner from 'src/_components/CenteredSpinner';
import { CountryLabel } from './CountrySelector';

const CountrySelection = () => {
    const { country, updateCountry } = useCountry();

    const handleClick = () => {
        updateCountry(null)
    }

    return (
        <Box>
            <Flex>
                <Text>
                    <FormattedMessage 
                        id="membershipPage.getQuote.countrySelector.label"
                        defaultMessage="Country"
                    />
                    {`: `}
                    <CountryLabel country={country} />
                </Text>
                <Button ml={[2,4]} variant="link" size={["sm"]} onClick={handleClick}>
                    <FormattedMessage 
                        id="membershipPage.getQuote.init.changeCountry"
                        defaultMessage="Change"
                    />
                </Button>

            </Flex>
        </Box>
    )
}

export const QuoteStepPhone = ({values, setCurrentStep, isShowing}) => {
    const { formatMessage } = useIntl();
    const { country } = useCountry();
    const { data: appFeatures, isLoading: isLoadingAppFeatures } = useAppFeatures({appName: 'memberships', country});

    const handleNext = () => {
        setCurrentStep(STEP_PAGES.MEMBER_LIST)
    }

    // tahi phone +66-955-575-622
    // +66-955-595-263
    if (isLoadingAppFeatures) {
        return (
            <Box display={isShowing ? 'box' : 'none'}>
                <ContentContainer>
                    <CenteredSpinner /> 
                </ContentContainer>
            </Box>
        )
    }

    if (appFeatures?.features?.is_phone_enabled) {
        return (
            <Box display={isShowing ? 'box' : 'none'}>
                <ContentContainer>
                    <CountrySelection />
                    <Field name="phone" validate={mustBePhoneNumber}>
                        {({input, meta}) => (
                                <InputComponent 
                                    {...input}
                                    label={formatMessage({id: 'membershipPage.getQuote.init.phone.label', defaultMessage: 'Phone number'})}
                                    placeholder={formatMessage({id: 'membershipPage.getQuote.init.phone.placeholder', defaultMessage: 'Phone number'})}
                                    type="phonenumber"
                                    isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                    error={getFinalFormMetaError(meta)}
                                    defaultCountry={country}
                                />
                        )}
                    </Field>
                </ContentContainer>
                <Box mt={[6]}>
                    <ButtonStack
                        handleClickNext={handleNext}
                        isDisabledNext={!values.phone || mustBePhoneNumber(values.phone)}
                        textNext={formatMessage({id: 'membershipPage.getQuote.init.next', defaultMessage: 'Next'})}
                    />
                </Box>
            </Box>
        )
    } else {
        return (
            <Box display={isShowing ? 'box' : 'none'}>
                <ContentContainer>
                    <CountrySelection />
                    <Field name="email" validate={required}>
                        {({input, meta}) => (
                                <InputComponent 
                                    {...input}
                                    label={formatMessage({id: 'membershipPage.getQuote.init.email.label', defaultMessage: 'Email'})}
                                    placeholder={formatMessage({id: 'membershipPage.getQuote.init.email.placeholder', defaultMessage: 'Email'})}
                                    isInvalid={isFinalFormMetaInvalid(meta) ? true : false}
                                    error={getFinalFormMetaError(meta)}
                                    defaultCountry={country}
                                />
                        )}
                    </Field>
                </ContentContainer>
                <Box mt={[6]}>
                    <ButtonStack
                        handleClickNext={handleNext}
                        isDisabledNext={!values.email}
                        textNext={formatMessage({id: 'membershipPage.getQuote.init.next', defaultMessage: 'Next'})}
                    />
                </Box>
            </Box>
        )
    }
}