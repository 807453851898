import React from "react";
import { FormattedMessage } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import { Box, Flex, Text } from "@chakra-ui/react";
import { BackChevronIcon } from "../../_images/icons/BackChevronIcon";
import { TreatmentList } from "../components/TreatmentList";

const BreadCrumbs = () => {
    const COLOR = '#718096'

    const history = useHistory();

    const handleBack = () => {
        history.push('/membership')
    }

    return (
        <Flex onClick={handleBack} align={['center']}>
            <BackChevronIcon color={COLOR} />
            <Text ml={['8px']} color={COLOR}>
                <FormattedMessage
                    id={'membershipPage.treatmentList.back'}
                    defaultMessage={'Back to Members'}
                />
            </Text>
        </Flex>
    )
}

export const TreatmentListPage = () => {
    const { profileId } = useParams();
    return (
        <Box mt={['80px', '100px']} px={['20px', '50px']} pb={['50px', 0]}>
            <Box py={['10px', '20px']} pr={[0, '40px']} minW={['100%', '150px']}>
                <BreadCrumbs />
            </Box>

            <Flex justify={['center', 'flex-start']} mt={['10px', '0px']} direction={['column']}>
                <TreatmentList profileId={profileId} />
            </Flex>
        </Box>
    )
}