import 'intersection-observer';
import React, {useEffect, useState} from 'react';
import { get } from 'idb-keyval';
import { ChakraProvider, Flex, Box, Spinner , Heading, Button } from '@chakra-ui/react'
import { BrowserRouter, Route, Redirect, Switch, Link, useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { getToken } from './_helpers';
import { Provider } from 'react-redux'
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import Modal from 'react-modal';
import { APPLE_WEBSITEPUSHID, APPLE_WEBSERVICEURL} from './_config';
import { toast } from 'react-toastify';
import {
  isMobile,
  isSafari,
  browserVersion
} from "react-device-detect";

import { PubNubProvider, usePubNub } from "pubnub-react";
import { queryClient } from './_helpers/react-query';
import { createPubNubListener } from "pubnub-redux";
import { store, pubnub } from './store';
import { checkIfSidebar, isMembershipUser, isScreeningUser } from './_helpers/utils';
import { checkSenderIsUser, checkNotificationRead, getNotificationChannelName } from './_helpers/pubnubUtils';

import { actions as notificationActions } from './Notifications/_actions';
import { actions as unreadMessageCountActions } from './UnreadMessageCount/_actions';
import { actions as chatActions} from './Chats/_actions';
import { actions as settingsActions } from './Settings/_actions';
import { actions as modalActions} from './Modals/_actions';
import { actions } from './Authentication/_actions';

import { Routes as MemberOnboardingRoutes } from './MemberOnboarding/routes';
import { Routes as OnboardingRoutes } from './Onboarding/routes';

import {
  getIsMembershipsLoaded,
  getCurrentConversationId,
  getUUID,
  getChatsList,
  getChatsListLoaded,
  getChatListMembershipLastRead
} from './Chats/_selectors';
import{
  getNotificationsAllLoading,
  getNotificationsByRequestId,
} from './Notifications/_selectors';
import {
  getIsChatLoaded,
  getChatState,
  getIsLoggedInFlag,
  getIsProfileLoaded,
  getProfile
} from './Authentication/_selectors'

import {
  AllModals
} from './Modals'

import { MainLayout, MainLayoutPublic, ChakraLayout } from './_components/MainLayout';
import IntlProvider from './_components/IntlProvider/IntlProvider';

import { ProvideAuth, useAuth } from './Authentication/useAuth';

import { Dashboard } from './Dashboard';
import { ReportDetail } from './Reports';
import { Registration } from './Registration';
import { LoginPage } from './LoginPage';
import { PasswordResetPage, PasswordCreatePage } from './PasswordResetPage';
import { DeleteAccountPage, PrivacyPage, TermsPage, OpenSourceAndroidPage, OpenSourceWebPage, HelpCenterPage } from './InformationalPages';
//import { HelpCenterPage } from './HelpCenterPage';
import { ScanningPage } from './ScanningPage';
import { Notifications } from './Notifications';
import { PartialScan } from './PartialScans';
import { ToothSelectChart } from './ToothSelectChart';
import { Settings } from './Settings';
import { Chats } from './Chats';
import { ChannelService } from './ChannelIO';
import { VideoChatPage } from './VideoChats';
import { ToastContainer } from './Toasts'
import { CompatibilityPage } from './Compatibility'

import { ClinicSearchPage } from './Clinics';

import './App.css';

import { StyledToastContainer } from './_styles/toast';
import { StyledMain, AppWrapper } from './_styles/common';
import { theme } from './_styles/chakraTheme';
import { Routes as MembershipRoutes } from './Membership/routes';
import { Routes as GetQuoteRoutes } from './Quotes/routes';
import { BackChevronIcon } from './_images/icons/BackChevronIcon';

import { PrivateRoute } from './Authentication/PrivateRoute';
import storage from './_helpers/storage';
// routes requiring a logged in user

const HomeRedirect = () => {
  const auth = useAuth(); 
  if (!auth?.user){
    return <Redirect to={'/login'} />
  }
  if (isMembershipUser(auth?.user?.apps)){
      return <Redirect to={'/membership'} />
  } else if (isScreeningUser(auth?.user?.apps)){ //includes('open_requests') 
      return <Redirect to={'/dashboard'} />
  } else {
      return <Redirect to="/notFound" />
  }
}

const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on route change
  }, [location]);

  return null; // This component doesn't render anything
};

const leaveApplication = () => {
  // This is required to show the current user leave immediately rather than
  // wating for the timeout period
  pubnub.unsubscribeAll();
  ChannelService.shutdown();
};

// required for react-modal to function
Modal.setAppElement('#root')


const MobileAppContainer = () => {
    return (
      <>
        <Switch>
          
        </Switch>
      </>
    )
}

const AppContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const isChatLoaded = useSelector(getIsChatLoaded);

  const userUuid = useSelector(getUUID);
  const currentConversationId = useSelector(getCurrentConversationId);
  const chatState = useSelector(getChatState);
  const loggedinFlag = useSelector(getIsLoggedInFlag);
  const isProfileLoaded = useSelector(getIsProfileLoaded);
  const userProfile = useSelector(getProfile);
  const chatsList = useSelector(getChatsList);
  const membershipsLoaded = useSelector(getIsMembershipsLoaded);
  const chatListLoaded = useSelector(getChatsListLoaded);
  const membershipsLastRead = useSelector(getChatListMembershipLastRead);
  const notificationsAllLoading = useSelector(getNotificationsAllLoading);
  const requestNotifications = useSelector(getNotificationsByRequestId);

  const [ isPrivate, setPrivate ] = useState(false)
  const currentLocation = location.pathname;

  const isUserDisabled = location.pathname.startsWith("/videochat") || 
                          location.pathname.startsWith("/policies") ||
                          location.pathname.startsWith("/helpcenter")
  
  if (process.env.REACT_APP_SENTRY_ENVIRONMENT == "staging" 
      || process.env.REACT_APP_SENTRY_ENVIRONMENT == "production" 
      || process.env.REACT_APP_SENTRY_ENVIRONMENT == "development" ){
    Sentry.init({
      dsn: 'https://bfe81bf9fd9e4cc28adfa3f80fc7cbc9@o1125277.ingest.sentry.io/6164622',
      environment: `${process.env.REACT_APP_SENTRY_ENVIRONMENT}`,
      integrations: [
        new Integrations.BrowserTracing({
          tracingOrigins: ['localhost:3003', 'ploverwebdev1.beforedent.com', 'plover.beforedent.com', /^\//],
        }),
      ],
  
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: parseFloat(process.env.REACT_APP_SENTRY_SAMPLE_RATE || '0.1'),
    });
  }

  // callback for FCm token found
  const setTokenFound = (newToken) => {
    const currentToken = localStorage.getItem("fireb_token");
    if (currentToken && currentToken != newToken){
      // remove ccurrent token from push notifications
      pubnub.push.removeChannels(
        {
          channels: [getNotificationChannelName(chatState.uuid), ...chatsList],
          device: currentToken,
          pushGateway: "gcm",
        },
        (status) => {
          if (status.error){
            console.log("error removing old token")
          } else {
            console.log("removed old token: ", currentToken)
          }
        }
      )
    }
    localStorage.setItem("fireb_token", newToken);
    pubnub.push.addChannels(
      {
        channels: [getNotificationChannelName(chatState.uuid), ...chatsList],
        device: newToken,
        pushGateway: "gcm",
      },
      (status) => {
        if (status.error){
          console.log("error adding old token")
        } else {
            console.log("added newtoken token", newToken)
        }
      }
    )
  }

  const setAPNTokenFound = (newToken) => {
    const currentToken = localStorage.getItem("apn_token");
    if (currentToken && currentToken != newToken){
      // remove ccurrent token from push notifications
      pubnub.push.removeChannels(
        {
          channels: [getNotificationChannelName(chatState.uuid), ...chatsList],
          device: currentToken,
          pushGateway: "apns",
        },
        (status) => {
          if (status.error){
            console.log("error removing old token")
          } else {
            console.log("removed old token: ", currentToken)
          }
        }
      )
    }
    localStorage.setItem("apn_token", newToken);
    pubnub.push.addChannels(
      {
        channels: [getNotificationChannelName(chatState.uuid), ...chatsList],
        device: newToken,
        pushGateway: "apns",
      },
      (status) => {
        if (status.error){
          console.log("error adding old token")
        } else {
            console.log("added newtoken token", newToken)
        }
      }
    )
  }

  const checkAPNPermission = (permissionData) => {
    if (permissionData.permission === 'default'){
      console.log("apnpermission: default", APPLE_WEBSERVICEURL, APPLE_WEBSITEPUSHID)
      let doNotShow = localStorage.getItem("doNotShow");
      if (!doNotShow){
        dispatch(modalActions.openModal('apnNotifications'));
      } else {
          if ((Date.now() - Number(doNotShow)) > (1000 * 60 * 60 * 24 * 7)){
            dispatch(modalActions.openModal('apnNotifications'));
          }
      }
    } else if (permissionData.permission === 'denied'){
      console.log("apnpermission: denied", permissionData)
      let doNotShow = localStorage.getItem("doNotShow");
      if (!doNotShow){
        dispatch(modalActions.openModal('apnNotifications'));
      } else {
          if ((Date.now() - Number(doNotShow)) > (1000 * 60 * 60 * 24 * 7)){
            dispatch(modalActions.openModal('apnNotifications'));
          }
      }
    } else if (permissionData.permission === 'granted'){
      console.log("apnpermission: granted with token: ", permissionData.deviceToken)
      setAPNTokenFound(permissionData.deviceToken);
    }
  }

  // handle messages received from pubnub listener
  const handleNewMessage = (msg) => {
    let { channel, timetoken, publisher, message } = msg;
    let { data: msgData, pn_gcm } = message;
    let { data, notification } = pn_gcm;
    let { title, body, click_action } = notification;

    // 1:1 patient to dentist chat message
    if (channel && channel.startsWith("PD1t1_")){
      let { pn_channel, pn_channelId, pn_type, pn_channelDentistId} = data;
      if (!checkSenderIsUser(userUuid, publisher, channel) && (channel != currentConversationId)){
        // message is from a different user (not own message)
        // send a toast and update unread messages
        dispatch(unreadMessageCountActions.addUnreadCount(channel));
        toast(
          "You have received a new message from dentist.",
          {
            toastId: channel,
            closeOnClick: false,
            onClick: () => {
              history.push(`/chats/${channel}`);
            }
          }
        )
      } else {
        // message from self so update membership to latest time token
        dispatch(chatActions.updateMembership(userUuid, channel));
      }
    // notification
    } else if (channel && channel.startsWith(`notifications.${userUuid}`)){
      let { pn_channel, pn_type, pn_reportId, pn_report_unique_id} = data;
      // NOTIFICATION FOR REPORT
      if (pn_reportId){
          dispatch(notificationActions.insertNotification(userUuid, msg))
          let toastId = `report.${pn_report_unique_id}`;
          let notifs = requestNotifications[pn_report_unique_id];
          toast("Our doctor has completed your report. Go check it out now!",
            {
              toastId,
              closeOnClick: false,
              onClick: (a) => {
                // mark the current notification read since clicked on
                // (in case this click runs before adding to reducer)
                dispatch(notificationActions.markNotificationRead(userUuid, timetoken))
                // also mark read the array currently stored in reducer
                if (notifs && notifs.length > 0){
                  dispatch(notificationActions.markNotificationArrayRead(userUuid, notifs.filter(elem=>!checkNotificationRead(elem, userUuid))))
                }
                history.push(`/requests/${pn_reportId}`)
              }
            }
          )
      }
    }
  }

  // browser check
  const browserCheck = () => {
    if (isUserDisabled) return;
    if (isSafari) {
      if (Number(browserVersion) < 12){
        dispatch(modalActions.openNotifyModal('safariVersion'));
      }
    }
  }

  useEffect(()=>{
     browserCheck();
  }, [])

  // initialize listeners to the window for app functionality
  // [loggedinFlag]
  /*useEffect(()=> {
    if (isUserDisabled) return;
    dispatch(modalActions.openLoadingModal())
    pubnub.addListener(
      createPubNubListener(store.dispatch)
    );
    window.addEventListener("beforeunload", leaveApplication);

    // fetch user information if logged in on mount
    if (!isPrivate && localStorage.getItem('user')){
      dispatch(modalActions.openLoadingModal('initprofile'))
      dispatch(actions.getProfile());
    } else {
      dispatch(modalActions.closeModal('loading'));
    }
    return () => {};
  }, [loggedinFlag, isPrivate])*/

  // load pubnub memberships, chat channels, notification channel once chat profile is received
  // [isChatLoaded]
  /*useEffect(()=> {
    if (isUserDisabled) return;
    if (isChatLoaded){
      dispatch(modalActions.openLoadingModal('initchat'));
      !chatListLoaded && dispatch(chatActions.loadChatChannels(chatState.uuid, chatState.auth_key, chatState.channel_group));
      !membershipsLoaded && dispatch(chatActions.loadMemberships(chatState.uuid, chatState.auth_key, chatState.channel_group));
      !notificationsAllLoading && dispatch(notificationActions.loadNotificationChannel(chatState.uuid, chatState.auth_key, chatState.channel_group));
    }
  }, [isChatLoaded])*/

  // check offline FCM notifications after chat list is loaded
  // [chatListLoaded]
  /*useEffect(()=>{
    if (isUserDisabled) return;
    if (chatListLoaded){
      // safari uses APN, else uses FCM (intenet explorer does not do anything)
      if (isSafari) {
        dispatch(modalActions.openNotifyModal('apnNotifications'));
      } else {
        // add FCM push notifications to browser
        getToken(setTokenFound, ()=> {
          dispatch(modalActions.openNotifyModal('firebaseNotifications'));
        })
      }
    }
  }, [chatListLoaded])*/

  // once channels and memberships in pubnub is loaded, check for unread messages
  // [chatListLoaded, membershipsLoaded, membershipsLastRead]
  /*useEffect(()=>{
    if (isUserDisabled) return;
    if (chatListLoaded && membershipsLoaded && membershipsLastRead.length > 0){
      dispatch(unreadMessageCountActions.getUnreadCounts(membershipsLastRead.map(elem => elem[0]),membershipsLastRead.map(elem => elem[1] )));
    }
  }, [chatListLoaded, membershipsLoaded, membershipsLastRead])*/

  // initialize support chat
  // [isProfileLoaded, userUuid]
  useEffect(()=>{
    dispatch(modalActions.closeModal('loading'));
    if (isUserDisabled) return;
    if (isProfileLoaded && userUuid){
      dispatch(settingsActions.setCountry(userProfile.country))
      /*ChannelService && ChannelService.shutdown();
      ChannelService.boot({
        "pluginKey": `${process.env.REACT_APP_CHANNELIO_KEY}`,
        "memberId": userUuid ,
        "profile": {
          "name": `${userProfile.first_name} ${userProfile.last_name}`,
          "mobileNumber": "YOUR_USER_MOBILE_NUMBER",
          "email": userProfile.email ? userProfile.email : null
        },
      });
      dispatch(settingsActions.setChannelIOLoaded(true))
    } else {
      ChannelService && ChannelService.shutdown();
      ChannelService.boot({
        "pluginKey": `${process.env.REACT_APP_CHANNELIO_KEY}`
      });*/
    }
  }, [isProfileLoaded, userUuid])

  // channels and memberships have been loaded, add pubnub new message listener
  // [membershipsLoaded]
  /*useEffect(() => {
    if (isUserDisabled) return;
    if (membershipsLoaded){
      dispatch(modalActions.closeModal('loading'));
      pubnub.addListener({ message: handleNewMessage });
    }
  }, [membershipsLoaded])*/

  return (
    <AppWrapper>
        <AllModals checkAPNPermission={checkAPNPermission} />
        <Switch>
          <Route path="/policies/deleteAccount">
            <MainLayoutPublic>
              <DeleteAccountPage />
            </MainLayoutPublic>
          </Route>
          <PrivateRoute exact path="/chats/:chatId"  >
            <MainLayout hasSideBar={true}>
              <Chats />
            </MainLayout>
          </PrivateRoute>
          <PrivateRoute exact path="/chats" >
            <MainLayout hasSideBar={true}>
              <Chats />
            </MainLayout>
          </PrivateRoute>
          <PrivateRoute exact path="/requests/:requestId" >
            <MainLayout hasSideBar={true}>
              <ReportDetail />
            </MainLayout>
          </PrivateRoute>
          <PrivateRoute exact path="/requests/" >
            <MainLayout hasSideBar={true}>
              <ReportDetail />
            </MainLayout>
          </PrivateRoute>
          <PrivateRoute exact path="/clinics" >
            <MainLayout hasSideBar={true}>
              <ClinicSearchPage />
            </MainLayout>
          </PrivateRoute>
          <PrivateRoute appName={['memberships', 'screenings']} path="/settings/:settingsId" >
            <MainLayout hasSideBar={true}>
              <Settings />
            </MainLayout>
          </PrivateRoute>
          <PrivateRoute appName={['memberships', 'screenings']} path="/settings">
            <MainLayout hasSideBar={true}>
              <Settings />
            </MainLayout>
          </PrivateRoute>

          <Route exact path="/register/:pageType" component={Registration} />
          <Route path="/videochat" strict={false}  component={VideoChatPage} />
          <Route exact path="/register" component={Registration} />
          <Route path="/login" strict={false} component={LoginPage}/>
          <Route exact path="/reset/:uid/:token" component={PasswordResetPage} />
          <Route path="/patientregister" strict={false} component={PasswordCreatePage}/>
          <Route path="/reset" strict={false} component={PasswordResetPage}/>
          {/*<Route path="/policies/privacy">
            <MainLayoutPublic>
              <PrivacyPage />
            </MainLayoutPublic>
          </Route>
          <Route path="/policies/terms">
            <MainLayoutPublic>
              <TermsPage />
            </MainLayoutPublic>
          </Route>
          <Route path="/webview/privacy" component={PrivacyPage} />
          <Route path="/webview/terms" component={TermsPage} />
          <Route path="/opensource/android" component={OpenSourceAndroidPage} />
          <Route path="/helpcenter" component={HelpCenterPage} />
          <Route path="/opensource/web" component={OpenSourceWebPage} />*/}{}
          <Route exact path="/testtt" component={ToothSelectChart} />
          <Route exact path="/test/compatibility" >
            <MainLayoutPublic>
              <CompatibilityPage />
            </MainLayoutPublic>
          </Route>
          <Route path="/a/*">
            <MainLayoutPublic 
              hasSideBar={false}
              noNavBar={true}
            >
              <OnboardingRoutes />
            </MainLayoutPublic>
          </Route>
          <PrivateRoute path={["/createrequest/clinics/id/:clinicId", "/createrequest/:rest"]}>
            <MainLayout hasSideBar={true}>
              <Dashboard />
            </MainLayout>
          </PrivateRoute>
          <PrivateRoute path="/membership*">
              <MainLayout hasSideBar={true}>
                <MembershipRoutes />
              </MainLayout>
          </PrivateRoute>
          <Route path={["/preonb", "/plans"]}>
            <MainLayoutPublic hasSideBar={false}>
              <GetQuoteRoutes />
            </MainLayoutPublic>
          </Route>
          <PrivateRoute path="/dashboard" appName={['screenings']}>
            <MainLayout hasSideBar={true}>
              <Dashboard />
            </MainLayout>
          </PrivateRoute>
          <Route path="/notFound">
            <MainLayoutPublic>
              <Flex justify={'center'} w={'full'} h={'full'} align={'center'}>
                <Flex align={'center'} direction={'column'}>
                  <Heading size={'lg'}>
                    Page Not Found
                  </Heading>
                  <Button mt={'20px'} variant={'outline'} size={'lg'} leftIcon={<BackChevronIcon /> } px={'30px'} onClick={() => history.replace("/")}>
                    Go Home
                  </Button>
                </Flex>
              </Flex>
            </MainLayoutPublic>
          </Route>
          <PrivateRoute exact path={"/"}>
            <HomeRedirect />
          </PrivateRoute>
          <Route path="">
            <Redirect to="/" />
          </Route>
          <Route path="*">
            <Redirect to="/notFound" />
          </Route>
        </Switch>
    </AppWrapper>
  )
}

const MemberContainer = () => {
  return (
    <AppWrapper>
      <ChakraLayout>
        <Switch>
          <Route path="/quotes">
            <MemberOnboardingRoutes />
          </Route>
        </Switch>
      </ChakraLayout>
    </AppWrapper>
  )
}

const App = () => {


  const hostname = window.location.hostname;
  const subdomain = hostname.split('.')[0]; // Get subdomain (e.g., suba.example.com)
  console.log("MEMBER_ON ", process.env.REACT_APP_MEMBER_ON)
  console.log("MEMBER_ON == true ", process.env.REACT_APP_MEMBER_ON == "true")
  const isMemberSubdomain = (process.env.REACT_APP_MEMBER_ON == "true") ? true : subdomain === 'member';

  return (
    <Provider store={store}>
      <ChakraProvider theme={theme} >
        <React.Suspense
          fallback={
            <Flex justify={'center'} w={'full'} h={'full'} align={'center'}>
              <Spinner size="xl" />
            </Flex>
          }
        >
          <IntlProvider onError={() => (void(0))}>
            <QueryClientProvider client={queryClient}>
              <ReactQueryDevtools initialIsOpen={false} />
                  <PubNubProvider client={pubnub}>
                    <ProvideAuth>
                      <BrowserRouter>
                        <ScrollToTop />
                        <AppContainer />
                      </BrowserRouter>
                    </ProvideAuth>
                  </PubNubProvider>
                  <StyledToastContainer
                    position="top-right"
                    autoClose={2000}
                    hideProgressBar={true}
                    newestOnTop={true}
                    theme={'light'}
                    closeOnClick
                    />
            </QueryClientProvider>
          </IntlProvider>
        </React.Suspense>
      </ChakraProvider>
    </Provider>
  );
}


export default App;
