import styled from 'styled-components';

export const StyledSideNav = styled.nav`
  visibility: ${props => props.show ? 'visible' : 'hidden'} ;
  display:flex;
  flex-direction:column;
  align-items:center;
  background-color:#F5F5F5;
  box-shadow:5px 0px 10px 0px rgba(0, 0, 0, 0.03);
  padding-top:80px;
  a {position:relative;display:flex;align-items:center;justify-content:center;margin-bottom:40px;width:100%;height:50px;text-align:center;}
  ${/*a.on:before{
    content:'';display:inline-block;height:50px;width:3px;background-color:#12171a;
    box-shadow:2px 3px 11px #12171a;position:absolute;left:0
  }*/''}
  a img {width:20px;height:20px;}
  .iconContainer{position:relative};
  .notificationDot{
    position:absolute;
    top: -3px;
    right: -5px;
    background-color: #367fff;
    border-radius: 10px;
    height: 10px;
    width: 10px;
  }  
    grid-row: 1; /* Default position */
    grid-column: 1; /* Keep it on the left */

  @media (max-width: 768px) {
    grid-row: 2; /* Place the SideNav in the second row on mobile */
    grid-column: 1; /* Full width on mobile */
    flex-direction:row;
    padding-top: 20px;
    padding-bottom: 20px;
    a{
      margin-bottom:0;
    }
  }

  @media (min-width: 769px) {
  }

`;
