import { store } from '../store';
import storage, { idbStorage } from "./storage";
import { actions as modalActions } from '../Modals/_actions';

export function handleResponseNoModal(response){
  if (!response.ok){
      if (response.status == 401 ){
          //sign off
          localStorage.removeItem('user')
          storage.remove('user');
          store.dispatch(modalActions.closeModal('error'))
          window.location.reload();
      } else{
          console.log("http response", response)
          return response.json().then(Promise.reject.bind(Promise));
      }
  }
  if (response.status == 299){ // custom status code in order to
    return response.json().then(Promise.reject.bind(Promise));
  }
  return response.json();
};

export function handleResponse(response){
    if (!response.ok){
        if (response.status == 401 ){
            //sign off
            storage.remove('user');
            idbStorage.remove('user');
            store.dispatch(modalActions.closeModal('error'))
            window.location.replace('/');
        } else if (response.status == 403){
            // rediect user to
            // TODO: show a pop up maybe to say no permission and redirect user to home page?
            store.dispatch(modalActions.openErrorModal('http_403'));
            return response.json().then(Promise.reject.bind(Promise));
        } else if (response.status == 404){
            // rediect user to
            // TODO: show a pop up maybe to say no permission and redirect user to home page?
            //store.dispatch(modalActions.openErrorModal('http_403'));
            return response.json().then(Promise.reject.bind(Promise));
        } else if (response.status === 500){
            store.dispatch(modalActions.openErrorModal('server_connection'));
            return response.json().then(Promise.reject.bind(Promise));
        }else{
            //store.dispatch(modalActions.openErrorModal('general'));
            return response.json().then(Promise.reject.bind(Promise));
        }
    }
    if (response.status == 299){ // custom status code in order to
      return response.json().then(Promise.reject.bind(Promise));
    }
    return response.json();
};

export function handleMiscFetchError(e){
  if (e instanceof TypeError){
    if (e.message == "Failed to fetch"){
      store.dispatch(modalActions.openErrorModal('server_connection'));
      throw 'fetch_error'
    }
  }
  store.dispatch(modalActions.openErrorModal('general'));
  throw 'fetch_error'
}
