import { useMutation } from 'react-query';
import { queryConfig, queryClient } from '../../_helpers/react-query';

import { saveQuote as save } from './services';

import { QUERY_KEY_NAMES } from './constants';

const saveQuery = async ({ data }) => {
    return save(data);
}

// eslint-disable-next-line no-unused-vars
const handleError =  ( _error, _context) => {
    queryClient.setQueryData([QUERY_KEY_NAMES.GET_QUOTE], (previousData) => {
        if (previousData === undefined) {
            return {};
        }
        return {quote: previousData.quote};
    });
}

const onSuccess = async ( data) => {
    const { phone, email } = data;
    queryClient.setQueryData([QUERY_KEY_NAMES.GET_QUOTE , { phone, email } ], (oldData) => {
        const newData =  {
            quote: {
                ...(oldData?.quote || {}),
                ...data
            }
        }
        return newData
    });
}

export const useSaveQuote = () => {
    return useMutation({
        mutationKey: QUERY_KEY_NAMES.SAVE_QUOTE,
        onSuccess: (data) => onSuccess(data),
        onError: (error, context) => handleError(error, context),
        mutationFn: (data) => {        
            return saveQuery({ data });
        },
    });
}