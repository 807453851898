import { QueryClient } from 'react-query';

export const queryConfig = {
  queries: {
    useErrorBoundary: true,
    retry: false,
  },
  staleTime: 1000 * 60 * 4, // 5 minutes
  refetchOnMount: true,
  refetchOnWindowFocus: true,
  refetchOnReconnect: true,

};

export const queryClient = new QueryClient({ defaultOptions: queryConfig });
