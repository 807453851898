
export const formatDate = (date) => {
    if (!date) return '';
    return new Intl.DateTimeFormat(navigator.language, {
        year: "numeric",
        month: "short",
        day: "numeric"
    }).format(new Date(date));
};

export const numberToFixedDecimal = (input, decimalPlaces=2) => {
    const num = typeof input === "number" ? input : parseFloat(input); // Convert the string to a number

    if (isNaN(num)) {
        return "";
    }

    // Round the number based on the required decimal places
    return decimalPlaces === 0
        ? Math.round(num).toString() // Round to 0 decimals
        : num.toFixed(2); // Round to 2 decimals
    };

export const formatCurrencyAmount = (number, locale = navigator.language) => {
    return new Intl.NumberFormat(locale).format(number);
};
      
export const getSubTotal = (treatments) => {
    return treatments.reduce((acc, item) => {
        return acc + (numberToFixedDecimal(item.quantity, 2) * numberToFixedDecimal(item?.fee_schedule?.average_price));
    }, 0);
}

export const getTotalCost = (treatments) => {
    return treatments.reduce((acc, item) => {
        return acc + (numberToFixedDecimal(item.quantity, 2) * numberToFixedDecimal(item?.cost_amount));
    }, 0);
}

export const getTotalDiscount = (currencySymbol, treatments) => {
    let subtotal = getSubTotal(treatments);
    let totalCost = getTotalCost(treatments);
    let diff = subtotal - totalCost;
    let discount = Math.round( diff / subtotal * 100);
    return `- ${currencySymbol} ${numberToFixedDecimal(diff, 2)} (${numberToFixedDecimal(discount,0)}%)`
}

export const getCurrencySymbol = (currencyCode, locale=navigator.language) => {
    const formatter = new Intl.NumberFormat(locale, {
      style: "currency",
      currency: currencyCode,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  
    // Extract the symbol by formatting a number and removing digits
    const parts = formatter.formatToParts(1);
    const currencySymbol = parts.find(part => part.type === "currency")?.value;
  
    return currencySymbol;
  };
  