

import { useQuery } from 'react-query';
import { queryConfig } from '../../_helpers/react-query';

import { fetchSubscriptionPlans as getQuery } from './services';
import { QUERY_KEY_NAMES } from './constants';

export const QUERY_KEY_NAME = QUERY_KEY_NAMES.GET_SUBSCRIPTION_PLANS;

const newQueryConfig = {
    ...queryConfig,
    queries: {
        ...queryConfig.queries,
    }
}

export const queryRequest = async (countryCode) => {
    //let paramsObj = {}
    //for (const [key, val] of Object.entries(queryParams)){
    //  if (val){
    //    paramsObj[key] = val
    //  }
    //}
    //const searchParams = new URLSearchParams(paramsObj)
    return getQuery(countryCode)
}

export const useSubscriptionPlanCollection = ({countryCode, config={}}) => {
    return useQuery({
        ...newQueryConfig,
        ...config,
        enabled: !!countryCode,
        queryKey: [QUERY_KEY_NAME, {countryCode}],
        queryFn: () => queryRequest(countryCode), // in brackets because im parsing the object in queryRequest
      });
}