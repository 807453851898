import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import { Form } from 'react-final-form';
import { useCreateMembership } from '../api/createMembership';
import { useCountry } from 'src/_libs/useCountry';
import { ApplyStepMembers } from '../components/ApplyStepMembers';
import { MemberFormTabs } from '../components/MemberFormSteps';
import { useApplyStore, STEP_PAGES } from '../state/apply';
import { convertToAPIValues } from 'src/_helpers/forms';
import { ApplyStepReview } from '../components/ApplyStepReview';
import CenteredSpinner from 'src/_components/CenteredSpinner';
import { HeadingContainer } from '../components/common';
import { CodeConfirmModal } from '../components/CodeConfirmModal';
import { nanoid } from 'nanoid';

const isIndexPage = (pageId=null) => {
    return [STEP_PAGES.PRIMARY, undefined, null, ''].includes(pageId)
}

const SubtitleText = ({pageId}) => {
    switch (pageId) {
        case STEP_PAGES.PRIMARY:
            return (
                <Text fontSize={18} fontFamily={'Proxima Nova Semibold'}>
                    <FormattedMessage 
                        id={'membershipPage.apply.init.subtitle'}
                        defaultMessage={'Just a few more details'}
                    />
                </Text>
            )
        case STEP_PAGES.REVIEW: 
            return (
                <Text fontSize={18} fontFamily={'Proxima Nova Semibold'}>
                    <FormattedMessage 
                        id={'membershipPage.apply.review.subtitle'}
                        defaultMessage={'Let\s review your information'}
                    />
                </Text>
            )
        default:
            return (
                <Text fontSize={18} fontFamily={'Proxima Nova Semibold'}>
                    <FormattedMessage 
                        id={'membershipPage.apply.init.subtitle'}
                        defaultMessage={'Just a few more details'}
                    />
                </Text>
            )
    }
}

const SubtitleDescription = ({pageId}) => {
    const TEXT_PROPS = {
        fontSize: 18,
        fontFamily: 'Proxima Nova'
    }
    switch (pageId) {
        case STEP_PAGES.PRIMARY:
            return (
                <Text {...TEXT_PROPS} >
                    <FormattedMessage 
                        id={'membershipPage.apply.init.description'}
                        defaultMessage={'Enter member information.'}
                    />
                </Text>
            )
        case STEP_PAGES.REVIEW: 
            return (
                <Text {...TEXT_PROPS} >
                    <FormattedMessage 
                        id={'membershipPage.apply.review.description'}
                        defaultMessage={'Make sure all information is correct and you check our policies.'}
                    />
                </Text>
            )
        default:
            return (
                <Text {...TEXT_PROPS} >
                    <FormattedMessage 
                        id={'membershipPage.apply.init.description'}
                        defaultMessage={'Enter member information.'}
                    />
                </Text>
            )
    }
}

const ApplicationFormPaging = () => {
    const location = useLocation();
    const { pageId } = useParams();
    const countryData = useCountry();
    const history = useHistory();
    const setQuoteDataEmail = useApplyStore(state => state.setQuoteDataEmail);
    const [quoteData, setQuoteData] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const saveMembershipMutation = useCreateMembership();


    React.useEffect(() => {
        const fetchQuoteData = async () => {
            setLoading(true);
            const data = await useApplyStore.getState().quoteData;
            setQuoteData(data);
            setLoading(false);
        };

        fetchQuoteData();
    }, []);

    const initialValues = React.useMemo(() => {
        return {
            plan_code: quoteData?.plan_code,
            country: countryData?.country,
            phone: quoteData?.phone,
            email: quoteData?.email,
        };
    }
    , [quoteData?.data]);
 
    const handleSubmit = async (values) => {
        // TODO plan data also added 
        //
        // plan_code, country, agreemetn, phone, email, data: { number of dependents, area}
        const newValues = cloneDeep(values)
        const { dependents, location, ...rest } = newValues
        const convertedValues = convertToAPIValues(rest)
        // add memebrs and location to data field
        const { email, plan_code, country, phone, ...submitRest } = convertedValues;
        const submitValues = {
            email,
            plan_code,
            country: countryData?.country,
            phone_number: phone,
            extra_data: {
                ...submitRest,
                dependents,
                location
            }
        }

        try{
            const resp = await saveMembershipMutation.mutateAsync(submitValues);
            const memberId = resp?.id
            setQuoteDataEmail(email)
            history.push(`/preonb/payment?memberQuote=${memberId}`, {from: location})
        } catch (error) {
            // depedent on error, 
            // country, phone, dependents, location, plan_code
            console.error('Error saving quote', error)
            return {
                ...error
            }
        }
    }

    const setCurrentStep = (step, dir) => {
        let url = `/preonb/apply/${step}`
        if (dir == 'back'){
            history.replace(url, {from: location})
        } else {
            history.push(url, {from: location})
        }
    }

    if (loading) {
        return (
            <Box>
                <CenteredSpinner />
            </Box>
        )
    }

    return (
        <Box>
            <MemberFormTabs />
            <Box py={['10px', '20px']} pr={[0, '40px']} pt={['20px']} minW={['100%', '150px']}>
                <SubtitleText pageId={pageId} />
                <Box py={['20px']}>
                    <SubtitleDescription pageId={pageId} />
                </Box>
            </Box>
            <Form
                onSubmit={handleSubmit}
                initialValues={initialValues}
                mutators={{ 
                    setFieldValue: ([field, value], state, { changeValue }) => {
                        changeValue(state, field, () => value)
                    }
                }}
                render = {({
                        submitting, handleSubmit, values, form
                    }) => {
                        return (
                            <Box as={'form'} id={'applicationForm'} onSubmit={handleSubmit} mt={['10px', '0px']} >
                                <ApplyStepMembers 
                                    isShowing={isIndexPage(pageId)}
                                    setCurrentStep={setCurrentStep} 
                                    values={values}
                                />
                                <ApplyStepReview
                                    form={form}
                                    setCurrentStep={setCurrentStep}
                                    isShowing={pageId == STEP_PAGES.REVIEW}
                                    values={values}
                                    isSubmitting={submitting}
                                />
                            </Box>
                        )

                    }
                } />
        </Box>
    )
}

export const ApplicationPages = () => { 
    return (
        <Box mt={['80px', '100px']} px={['20px', '50px']} pb={['50px', 0]}>
            <HeadingContainer >
                <FormattedMessage 
                    id={'membershipPage.checkout.title'}
                    defaultMessage={'Checkout'}
                />
            </HeadingContainer>
            <ApplicationFormPaging />
        </Box>
    )
}
