import React from "react";
import { Box, Progress, Text } from "@chakra-ui/react";
import { useMembershipDetails } from "../api/getUserMembership";
import CenteredSpinner from "../../_components/CenteredSpinner";
import { FormattedMessage } from "react-intl";
import { useMembershipBenefits } from "../api/getUserMembershipBenefits";
import { formatDate } from "../utils";

export const MaximumBenefit = () => {
    const { data, isLoading, isFetching } = useMembershipBenefits();

    if (isLoading || isFetching) {
        return <CenteredSpinner />
    }

    if (data?.benefits?.maximum_benefit == null){
        return (
            <Box>
                <Text color={'#7B8887'} fontStyle={'italic'}>
                    <FormattedMessage
                        id={'membershipPage.membershipDetails.maximumBenefit.noMaximumBenefit'}
                        defaultMessage={'No Annual Limit'}
                    />
                </Text>
            </Box>
        )
    }

    return (
        <Box>
            <Text>
                <FormattedMessage
                    id={'membershipPage.membershipDetails.maximumBenefit.benefitsReset'}
                    defaultMessage={'Benefits reset on '}
                />
                {formatDate(data?.benefits?.end_date)}
            </Text>
            <Box pt={['10px']} pb={['10px']}>
                <Text>
                    <FormattedMessage
                        id={'membershipPage.membershipDetails.maximumBenefit.benefitsUsed'}
                        defaultMessage={'Used: '}
                    />
                    {`${data?.benefits?.currency_symbol} ${data?.benefits?.benefit_used} / ${data?.benefits?.currency_symbol} ${data?.benefits?.maximum_benefit}`}
                </Text>
                <Box 
                    mt={['5px']}
                    pr={['10px']}
                >
                    <Progress 
                        max={1}
                        value={data?.benefits?.benefit_used/data?.benefits?.maximum_benefit}
                        size={["lg"]}
                        height={["24px"]}
                        borderRadius={["50px"]}
                        sx={{
                          "& > div": {
                            backgroundColor: "#527499", // Custom color
                          },
                        }}
                    />
                </Box>
            </Box>
        </Box>
    )
}