import { useQuery } from 'react-query';
import { queryConfig } from '../../_helpers/react-query';

import { services } from '../_services';

export const QUERY_KEY_NAME = 'AppFeatures_';

const newQueryConfig = {
    ...queryConfig,
    queries: {
        ...queryConfig.queries,
    }
}

export const queryRequest = async (appName, country) => {
    return services.fetchAppFeatures(appName, country)
}

export const useAppFeatures = ({ appName, country }) => {
    let queryName = `${QUERY_KEY_NAME}memberships_${country}`
    if( appName == "memberships"){
        queryName = `${QUERY_KEY_NAME}memberships_${country}`
    } 

    return useQuery({
        ...newQueryConfig,
        enabled: !!appName && !!country,
        queryKey: [queryName],
        queryFn: () => queryRequest(appName, country), // in brackets because im parsing the object in queryRequest
      });
}