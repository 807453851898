import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ErrorLabel } from '../_styles/forms';



export const StyledLoadingButtonLoading = styled.div`
  width: 100%;
  display:flex;
  align-items:center;
  justify-content: center;
  border-radius: 5px;
  height: 100%;
  &:hover{
    cursor:default;
  }
`;

export const StyledLoginButton = styled.button`
  background: #273238;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  position:relative;
  border-radius: 5px;
  width: 100%;
  height: 48px;
  text-transform: uppercase;
  font-family: Proxima Nova Bold;
  font-style: normal;
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.9px;
  margin-top: 40px;
  color: #FFFFFF;
  border:none;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  filter: drop-shadow(0px 2px 14px rgba(39, 50, 56, 0.5));

  &:hover{
       opacity:0.8;
       cursor:pointer;
     }

  &:disabled{
    opacity: 0.8;
    &:hover{
      cursor:default;
    }
  }

`;

export const StyledFacebookButton = styled.div`
  position:relative;
  ${StyledLoadingButtonLoading}{
    position:absolute;
  }
  .FacebookButtonLogin{
     font-family: Helvetica, Arial, sans-serif;
     display:flex;
     justify-content:center;
     align-items: center;
     height: 48px;
     padding: 0 50px;
     background-color:#3C5A99;  /* #4080ff;
     border: 1px solid #4b7bdb;*/
     box-shadow: 0 1px 1px rgb(0 0 0 / 14%);
     border-radius: 5px;
     margin-top: 10px;
     width: 100%;
     font-size: 13px;
     text-transform: uppercase;
     color: #fff;
     font-weight: 600;

     i{
       font-size: 20px;
       margin-right: 10px;
     }

     &:hover{
       opacity:0.8;
       cursor:pointer;
     }
  }

`;

export const StyledGoogleButton = styled.button`
  font-family: 'Roboto';
  display:flex;
  justify-content:center;
  position:relative;
  align-items: center;
  height: 48px;
  padding: 0 50px;
  background-color: white;
  width: 100%;
  border:none;
  border-radius: 5px;
  border: thin solid #888;
  margin-top: 60px;

  &:hover{
    opacity:0.8;
    cursor:pointer;
  }

  img{
    width:25px;
    height:25px;
  }

  span{
    font-family: Roboto;
    margin-left: 15px;
    font-size: 13px;
    line-height: 19px;
    text-transform: uppercase;
    color: #444;
  }

`;

export const StyledActivateLink = styled(Link)`
  font-family: Proxima Nova Semibold;
  font-style: normal;
  font-size: 13px;
  line-height: 19px;
  text-transform: uppercase;
  display:flex;
  justify-content:center;

  &:not(:first-child){
    margin-top: 10px;
  }

  text-align: center;
  letter-spacing: 0.9px;

  text-decoration: none;

  color: #40515980;
`;

export const StyledForgotPasswordLink = styled(Link)`
  font-family: Proxima Nova Semibold;
  font-style: normal;
  font-size: 13px;
  line-height: 19px;
  text-transform: uppercase;
  display:flex;
  justify-content:center;

  &:not(:first-child){
    margin-top: ${props => props?.marginTop ? props?.marginTop : '10px'};
  }
 
  text-align: center;
  letter-spacing: 0.9px;

  text-decoration: none;

  color: #405159;
`;

export const StyledSpacing = styled.div`
  font-family: Proxima Nova Bold;
  font-style: normal;
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.9px;
  color: #405159;
  margin: 10px 0;
  margin: 20px 0;
  font-size: 22px;
  font-family: Proxima Nova;
  padding-bottom: 20px;
`;

export const StyledCompanyLogo = styled.div`
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 3.18px;

  color: #405159;
`;


export const StyledForm = styled.div`
  width: 360px;


  @media (max-width: 768px) {
    width: 100%;
  }

  ${StyledGoogleButton} ~ ${ErrorLabel}{
    margin: 5px;
    height: unset;
    min-height:15px;
    line-height: unset;
  }
`;

export const Wrapper = styled.div`
  display:flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: white;
  margin-left:-100px;
  > div {
    width: 360px;
  }

  @media (max-width: 768px) {
    margin-left:0;
    padding-left: 40px;
    padding-right: 40px;
    > div {
      width: 100%;
    }
  }

`;
