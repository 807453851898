import { API_URLS } from "../../_config";
import { authHeader, handleResponse } from "../../_helpers";

export function fetchSubscriptionPlans(countryCode){
    const requestUrl = API_URLS.fetchSubscriptionPlans;
    const requestOptions = {
      method: requestUrl.method,
      headers: {'Content-Type': 'application/json' }
    };
    let url = requestUrl.url.replace('{country_code}', countryCode);
    return fetch(url, requestOptions).then(handleResponse);
  
  }

  
export function fetchQuotes(queryParams){
  const requestUrl = API_URLS.fetchMembershipQuote;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  };
  let url = requestUrl.url + '?'  + queryParams
  return fetch(url, requestOptions).then(handleResponse);

}

export function confirmSimpleCode(data){
  const requestUrl = API_URLS.confirmSimpleCode;
  const requestOptions = {
    method: requestUrl.method,
    headers: {'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  let url = requestUrl.url;
  return fetch(url, requestOptions).then(handleResponse);
}

export function requestSimpleCode(data){
  const requestUrl = API_URLS.requestSimpleCode;
  const requestOptions = {
    method: requestUrl.method,
    headers: {'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  let url = requestUrl.url;
  return fetch(url, requestOptions).then(handleResponse);
}

export function saveQuote(data){
    const requestUrl = API_URLS.createMembershipQuote;
    const requestOptions = {
      method: requestUrl.method,
      headers: {'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    };
    let url = requestUrl.url;
    return fetch(url, requestOptions).then(handleResponse);
  
  }

export function createMembership(data){
    const requestUrl = API_URLS.createMembership;
    const requestOptions = {
      method: requestUrl.method,
      headers: {'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    };
    let url = requestUrl.url;
    return fetch(url, requestOptions).then(handleResponse);
  
}

export function createPayment(data){
  const requestUrl = API_URLS.createMembershipPayment;
  const requestOptions = {
    method: requestUrl.method,
    headers: {'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  let url = requestUrl.url;
  return fetch(url, requestOptions).then(handleResponse);

}