import React from "react";
import { FormattedMessage } from "react-intl";
import { Box, Center, Text, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalFooter, ModalOverlay } from "@chakra-ui/react";

export const QuickModal = ({isOpen, onClose, header, desciption, onCancel=null, onConfirm=null, size=["sm", "md"]}) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size={size} isCentered >
            <ModalOverlay bg="blackAlpha.300" />
            <ModalContent bg="#f7f9fa">
                <ModalHeader>
                    <Center>
                            {header}
                    </Center>
                </ModalHeader>
                <ModalCloseButton/>
                <ModalBody>
                    <Box>
                        <Text>
                            {desciption}
                        </Text>
                    </Box>
                </ModalBody>
                <ModalFooter>
                    {onCancel && <Button onClick={onCancel} variant="outline" mr={3}>
                        <FormattedMessage 
                            id={'adminPage.clinicRoleForm.cancel'}
                            defaultMessage={'Cancel'}
                        />
                    </Button>}
                    {onConfirm && <Button onClick={onConfirm} >
                        <FormattedMessage 
                            id={'adminPage.clinicRoleForm.save'}
                            defaultMessage={'Ok'}
                        />
                    </Button>}
                </ModalFooter>
            </ModalContent>
        </Modal>

    )
}