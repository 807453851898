import React from 'react';
import { Box, Flex, HStack, VStack, Button, Input, useNumberInput, SimpleGrid, Text } from '@chakra-ui/react';
import { PlusIcon } from 'src/_images/icons/PlusIcon';
import { MinusIcon } from 'src/_images/icons/MinusIcon';
import { useIntl } from 'react-intl';
import { Field } from 'react-final-form';
import { useQuotesStore, STEP_PAGES } from '../state/quotes';
import { ContentContainer, ButtonStack } from './common';
import { FormattedMessage } from 'react-intl';

const RelationshipText = ({relationship}) => {
    const relationshipUpper = relationship.toUpperCase();
    switch (relationshipUpper) {
        case 'SPOUSE':
            return (
                <FormattedMessage 
                    id={'membershipPage.getQuote.relationship.spouse.label'}
                    defaultMessage={'Spouse'}
                />
            )
        case 'CHILDREN':
        case 'CHILD':
            return (
                <FormattedMessage 
                    id={'membershipPage.getQuote.relationship.child.label'}
                    defaultMessage={'Children'}
                />
            )
        case 'PARENT':
            return (
                <FormattedMessage 
                    id={'membershipPage.getQuote.relationship.parent.label'}
                    defaultMessage={'Parents'}
                />
            )
        case 'OTHER':
            return (
                <FormattedMessage 
                    id={'membershipPage.getQuote.relationship.other.label'}
                    defaultMessage={'Other'}
                />
            )
        default:
            return (
                <></>
            )
    }
}

const NumberInput = ({relationship, value, max, onChange}) => {
    const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
      useNumberInput({
        step: 1,
        value,
        onChange: (valString, valNumber) => {
          // valString: e.g. "2.53"
          // valNumber: e.g. 2.53
          // Convert empty string to 0 or handle as needed
          onChange(relationship, valNumber || 0);
        },
        min: 0,
        max: max,
      })
  
    const inc = getIncrementButtonProps()
    const dec = getDecrementButtonProps()
    const input = getInputProps()
  
    return (
      <HStack spacing={[4]}>
        <Button {...dec} size={'circle'} variant={'outline'}><MinusIcon height={14} width={14} /></Button>
        <Input {...input} w={['50px']} textAlign={['center']} />
        <Button {...inc}  size={'circle'} variant={'outline'}><PlusIcon height={14} width={14} /></Button>
      </HStack>
    )
  }

const MemberItem = ({relationship, value, onChange}) => {
    const getMaxValue = (relationship) => {
        switch (relationship) {
            case 'SPOUSE':
                return 1;
            case 'CHILD':
                return 5;
            default:
                return 2;
        }
    }

    const increment = () => {
        onChange(relationship, value + 1)
    }
    const decrement = () => {
        onChange(relationship, Math.max(value - 1, 0))
    }
  
    return (
        <SimpleGrid columns={[2]} spacing={[4]}>
            <Flex align={['center']}>
                <Text>
                    <RelationshipText relationship={relationship} />
                </Text>
            </Flex>
            <Flex justify={['flex-end']}>
                <HStack spacing={[4]}>
                    <NumberInput relationship={relationship} value={value} max={getMaxValue(relationship)} onChange={onChange} />
                </HStack>
            </Flex>
        </SimpleGrid>
    );
  }

const MemberField = ({inputValue, onChange}) => {

    const handleChange = (relationship, value) => {
        onChange({
            ...inputValue, 
            [relationship]: value
        })
    }

    return (
        <VStack spacing={[4]}>
            <MemberItem relationship={'SPOUSE'} value={inputValue['SPOUSE']} onChange={handleChange} />
            <MemberItem relationship={'CHILD'} value={inputValue['CHILD']} onChange={handleChange} />
            <MemberItem relationship={'PARENT'} value={inputValue['PARENT']} onChange={handleChange} />
        </VStack>
    )
}

export const QuoteStepMembers = ({setCurrentStep, isShowing}) => {
    const { formatMessage } = useIntl();

    const handlePrev = () => {
        setCurrentStep(STEP_PAGES.PRIMARY, 'back');
    }
 
    const handleNext = () => {
        setCurrentStep(STEP_PAGES.AREA);
    }


    return (
        <Box display={isShowing ? 'box' : 'none'}>
            <ContentContainer>
                <Field name="dependents">
                    {({input, meta}) => (
                        <MemberField 
                            inputValue={input.value} 
                            onChange={input.onChange}
                        />
                    )}
                </Field>
            </ContentContainer>
            <Box mt={[6]}>
                <ButtonStack
                    handleClickPrevious={handlePrev}
                    handleClickNext={handleNext}
                    isDisabledNext={false}
                    textPrevious={formatMessage({id: 'membershipPage.getQuote.members.previous', defaultMessage: 'Previous'})}
                    textNext={formatMessage({id: 'membershipPage.getQuote.members.next', defaultMessage: 'Next'})}
                />
            </Box>
        </Box>
    )
}