import { API_URLS } from 'src/_config';
import { handleResponse } from 'src/_helpers';

export function registerPassword(data){
    const requestUrl = API_URLS.registerPassword;
    const requestOptions = {
        method: requestUrl.method,
        headers: { 'Content-Type': 'application/json'},
        body: JSON.stringify(data)
    };
    const url = requestUrl.url;
    return fetch(url, requestOptions).then(handleResponse);
  }


export function requestEmailCode(data){
    const requestUrl = API_URLS.requestCodeRegisterPassword;
    const requestOptions = {
        method: requestUrl.method,
        headers: { 'Content-Type': 'application/json'},
        body: JSON.stringify(data)
    };
    const url = requestUrl.url;
    return fetch(url, requestOptions).then(handleResponse);
  }
  