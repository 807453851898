import React from "react";
import { useHistory } from "react-router-dom";
import { useReactTable, getCoreRowModel } from '@tanstack/react-table'
import { Box, Flex, Heading, Button, SimpleGrid, Text } from "@chakra-ui/react";
import { useUserMembershipTreatmentCollection } from "../api/getUserTreatmentCollection";
import { Pagination } from "./Pagination";
import { FormattedMessage } from "react-intl";
import CenteredSpinner from "../../_components/CenteredSpinner";
import { formatDate, numberToFixedDecimal, getTotalCost, getTotalDiscount } from "../utils";


const getTreatmentNames  = (treatments) => {
    if (!treatments || treatments.length == 0) return '-';
    return treatments.map(treatment => treatment.name).join(', ')
}

const StatusTag = ({status}) => {
    switch (status) {
    case "completed":
        return (
            <Text color={'#44C5A6'} textTransform={['uppercase']}>
                <FormattedMessage
                    id={'membershipPage.treatmentList.status.completed'}
                    defaultMessage={'Completed'}
                />
            </Text>
        )
    case "in_progress":
    case "planned":
        return (
            <Text color={'#FFA05C'} textTransform={['uppercase']}>
                <FormattedMessage
                    id={'membershipPage.treatmentList.status.inProgress'}
                    defaultMessage={'In Progress'}
                />
            </Text>
        )
    case "canceled":
        return (
            <Text color={'#E95252'} textTransform={['uppercase']}>
                <FormattedMessage
                    id={'membershipPage.treatmentList.status.canceled'}
                    defaultMessage={'Canceled'}
                />
            </Text>
        )
    default:
        return <></>; // Default style
    }
};


const TreatmentItem = ({row}) => {
    // row.original
    // column.columnDef
    const simpleGridProps = {mt: ['5px']}
    return (
        <Box borderBottom={'1px solid #E2E8F0'} py={['15px']} px={['10px']}>
            <Flex w={['100%']} justify={['space-between']} align={['center']}>
                <Text fontWeight={700}>
                    {formatDate(row.original.created_date)} 
                </Text>
                <StatusTag status={row.original.status} />
            </Flex>
            <SimpleGrid columns={[2]} {...simpleGridProps}>
                <Text >
                    {`${row.original.clinic}`} 
                </Text>
                <Text textAlign={['right']}>
                    <FormattedMessage 
                        id={'format.fullName'}
                        defaultMessage={'{givenName} {familyName}'}
                        values={{
                            givenName: row.original.patient?.first_name,
                            familyName: row.original.patient?.last_name
                        }}
                    />
                </Text>
            </SimpleGrid>
            <Text color={'#7B8887'} fontStyle={'italic'} {...simpleGridProps}>
                {getTreatmentNames(row.original.treatments)}
            </Text>
            <SimpleGrid columns={[2]} {...simpleGridProps}>
                <Text >
                    <FormattedMessage 
                        id={'membershipPage.treatmentList.totalDiscount'}
                        defaultMessage={'Total Discount'}
                    />
                </Text>
                <Text textAlign={['right']}>
                    <FormattedMessage 
                        id={'membershipPage.treatmentList.totalCost'}
                        defaultMessage={'Total Cost'}
                    />
                </Text>
            </SimpleGrid>
            <SimpleGrid columns={[2]} {...simpleGridProps}>
                <Text color={'#E95252'} >
                    {getTotalDiscount(row.original.currency_symbol, row.original.treatments)}
                </Text>
                <Text textAlign={['right']}>
                    {`${row.original.currency_symbol} ${numberToFixedDecimal(getTotalCost(row.original.treatments), 2)}`}
                </Text>
            </SimpleGrid>
        </Box>
    )
}

const PageTable = ({data, isLoading, pagination, setPagination, isRecentsOnly=false}) => {
    const history = useHistory();
    const columns = React.useMemo(
        () => [
            {
                header: 'Date',
                id: 'created_date',
                accessorKey: 'created_date',
            },
            {
                header: 'Total Discount',
                id: 'total_discount',
                accessorFn: row => getTotalDiscount(row?.treatments),
            },
            {
                header: 'Total Cost',
                id: 'total_cost',
                accessorFn: row => getTotalCost(row?.treatments),
            }
        ],
        []
    )
    const defaultData = React.useMemo(() => [], [])

    const table = useReactTable({
        data: data?.treatments || defaultData,
        columns,
        state: {
            pagination
        },
        onPaginationChange: setPagination,
        rowCount: data?.total,
        getCoreRowModel: getCoreRowModel(),
        manualPagination: true,
        debugTable: true
    })

    const handleAllClick = () => {
        history.push('/settings/membership_treatment_history')
    }

    return (
        <Box>
            {
                isLoading ? (
                    <CenteredSpinner />
                ) : (table.getRowModel().rows.length === 0) ? (
                    <Box pt={['10px']}>
                        <FormattedMessage 
                            id={'membershipPage.treatmentList.noData'}
                            defaultMessage={'No Treatments'}
                        />
                    </Box>
                ) : (
                    <>
                        {table.getRowModel().rows.map(row => {
                            return (
                                <Box key={row.id} >
                                    <TreatmentItem 
                                        row={row}
                                    />
                                </Box>
                            )
                        })}
                    </>
                )
            }
            <Flex w="full" mt={['30px']} justify={['center', 'flex-end']}>
                {
                    isRecentsOnly ? table.getCanNextPage() ? (
                        <Flex w={['full']} justify={['center']}>
                            <Button variant={['outline']} onClick={handleAllClick}>
                                <FormattedMessage 
                                    id={'membershipPage.treatmentList.viewAll'}
                                    defaultMessage={'View All Treatments'}
                                />
                            </Button>
                        </Flex>
                    ) : (
                        <>  </>
                    ) : (
                        <Pagination 
                            isLoading={isLoading}
                            canPreviousPage={table.getCanPreviousPage()}
                            canNextPage={table.getCanNextPage()}
                            pageIndex={table.getState().pagination.pageIndex}
                            total={table.getPageCount()}
                            nextPage={table.nextPage}
                            previousPage={table.previousPage}
                            setPageIndex={table.setPageIndex}
                        />
                    )
                }
            </Flex>
        </Box>
    )
}

export const TreatmentList = ({profileId, isRecentsOnly=false}) => {
    const PAGE_SIZE = isRecentsOnly ? 2 : 10;
    const [pagination, setPagination] = React.useState({
      pageIndex: 0,
      pageSize: PAGE_SIZE,
    })
    const { data, isLoading, isFetching } = useUserMembershipTreatmentCollection({
        profile: profileId, offset: pagination.pageIndex, pg_sz: pagination.pageSize
    })

    return (
        <Box >
            <Heading >
                <FormattedMessage 
                    id={'membershipPage.treatmentList.title'}
                    defaultMessage={'Treatment List'}
                />
            </Heading>
            <PageTable data={data} isLoading={isLoading || isFetching}  pagination={pagination} setPagination={setPagination} isRecentsOnly={isRecentsOnly} />
        </Box>
    )
}