import React from 'react';
import { Box, Flex, Spinner } from '@chakra-ui/react';

const CenteredSpinner = (props) => {
    return (
        <Box>
            <Flex w={['full']} align={'center'} justify={'center'} {...props}>
                <Spinner 
                    size='xl'
                    thickness='4px'
                    speed='0.65s' 
                    color='#44C5A6'
                />
            </Flex>
        </Box>
    )
}

export default CenteredSpinner;