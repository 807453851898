import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { MemberFormTabs } from '../components/MemberFormSteps';
import { useQuotesStore, STEP_PAGES } from '../state/quotes';
import { useSaveQuote } from '../api/saveQuote';
import { QuoteStepPhone } from '../components/QuoteStepPhone';
import { convertToAPIValues } from 'src/_helpers/forms';
import { Form } from 'react-final-form';
import { ContainerComponent, HeadingContainer } from '../components/common';
import { QuoteStepMembers } from '../components/QuoteStepMembers';
import { PlanPricingList } from '../components/PlanPricingList';
import { QuoteStepPlanPricing } from '../components/QuoteStepPlanPricing';
import { QuoteStepLocation } from '../components/QuoteStepLocation';
import { CountryModal } from '../components/CountrySelector';
import { useCountry } from 'src/_libs/useCountry';
import { useQuoteDetails } from '../api/getQuote';
import { useApplyStore } from '../state/apply';
import { useAppFeatures } from 'src/Settings/api/getAppFeatures';
import CenteredSpinner from 'src/_components/CenteredSpinner';

const isIndexPage = (pageId=null) => {
    return [STEP_PAGES.PRIMARY, undefined, null, ''].includes(pageId)
}

const SubtitleText = ({pageId, isPhoneEnabled}) => {
    switch (pageId) {
        case STEP_PAGES.PRIMARY:
            if (isPhoneEnabled) {
                return (
                    <Text fontSize={18} fontFamily={'Proxima Nova Semibold'}>
                        <FormattedMessage 
                            id={'membershipPage.getQuote.init.subtitle'}
                            defaultMessage={'First, can we have your phone number?'}
                        />
                    </Text>
                )
            } else {
                return (
                    <Text fontSize={18} fontFamily={'Proxima Nova Semibold'}>
                        <FormattedMessage 
                            id={'membershipPage.getQuote.init.contact.subtitle'}
                            defaultMessage={'First, can we have your contact information?'}
                        />
                    </Text>
                )
            }
        case STEP_PAGES.MEMBER_LIST: 
            return (
                <Text fontSize={18} fontFamily={'Proxima Nova Semibold'}>
                    <FormattedMessage 
                        id={'membershipPage.getQuote.member_list.subtitle'}
                        defaultMessage={'Who would you like to cover?'}
                    />
                </Text>
            )
        case STEP_PAGES.AREA: 
            return (
                <Text fontSize={18} fontFamily={'Proxima Nova Semibold'}>
                    <FormattedMessage 
                        id={'membershipPage.getQuote.location.subtitle'}
                        defaultMessage={'Where do you live?'}
                    />
                </Text>
            )
        case STEP_PAGES.PLAN_LIST: 
            return (
                <Text fontSize={18} fontFamily={'Proxima Nova Semibold'}>
                    <FormattedMessage 
                        id={'membershipPage.getQuote.plan_list.subtitle'}
                        defaultMessage={'Which plan fits your needs?'}
                    />
                </Text>
            )
        default:
            if (isPhoneEnabled) {
                return (
                    <Text fontSize={18} fontFamily={'Proxima Nova Semibold'}>
                        <FormattedMessage 
                            id={'membershipPage.getQuote.init.subtitle'}
                            defaultMessage={'First, can we have your phone number?'}
                        />
                    </Text>
                )
            } else {
                return (
                    <Text fontSize={18} fontFamily={'Proxima Nova Semibold'}>
                        <FormattedMessage 
                            id={'membershipPage.getQuote.init.contact.subtitle'}
                            defaultMessage={'First, can we have your contact information?'}
                        />
                    </Text>
                )
            }
    }
}

const SubtitleDescription = ({pageId}) => {
    const TEXT_PROPS = {
        fontSize: 18,
        fontFamily: 'Proxima Nova'
    }
    switch (pageId) {
        case STEP_PAGES.PRIMARY:
            return (
                <Text {...TEXT_PROPS} >
                    <FormattedMessage 
                        id={'membershipPage.getQuote.init.description'}
                        defaultMessage={'This helps save your progress, so you don\'t have to start over.'}
                    />
                </Text>
            )
        case STEP_PAGES.MEMBER_LIST: 
            return (
                <Text {...TEXT_PROPS} >
                    <FormattedMessage 
                        id={'membershipPage.getQuote.member_list.description'}
                        defaultMessage={'Select the family members that you want to cover.'}
                    />
                </Text>
            )
        case STEP_PAGES.AREA: 
            return (
                <Text {...TEXT_PROPS} >
                    <FormattedMessage 
                        id={'membershipPage.getQuote.location.description'}
                        defaultMessage={'This helps us determine the plans available in your area.'}
                    />
                </Text>
            )
        case STEP_PAGES.PLAN_LIST: 
            return (
                <Text {...TEXT_PROPS} >
                    <FormattedMessage 
                        id={'membershipPage.getQuote.plan_list.description'}
                        defaultMessage={'Select the plan you want to purchase.'}
                    />
                </Text>
            )
        default:
            return (
                <Text {...TEXT_PROPS} >
                    <FormattedMessage 
                        id={'membershipPage.getQuote.init.description'}
                        defaultMessage={'This helps save your progress, so you don\'t have to start over.'}
                    />
                </Text>
            )
    }
}

export const QuotePaging = ({}) => {
    const location = useLocation();
    const { pageId } = useParams();
    const saveQuoteMutation = useSaveQuote();
    const setQuoteData = useApplyStore(state => state.setQuoteData);
    const [formQuoteData, setFormQuoteData] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const countryData = useCountry();
    const { data: appFeatures, isLoading: isLoadingAppFeatures } = useAppFeatures({appName: 'memberships', country: countryData?.country});
    const history = useHistory();

    React.useEffect(() => {
        const fetchQuoteData = async () => {
            setLoading(true);
            const data = await useApplyStore.getState().quoteData;
            setFormQuoteData(data);
            setLoading(false);
        };

        fetchQuoteData();
    }, []);

    const initValues = React.useMemo(() => {
        return {
            phone: formQuoteData?.phone || null,
            email: formQuoteData?.email || '',
            dependents: formQuoteData?.dependents || {
                SPOUSE: 0,
                CHILD: 0,
                PARENT: 0
            },
            location: formQuoteData?.location || '',
            plan_code: formQuoteData?.plan_code || null
        }
    }, [formQuoteData?.data])
 
    const handleSubmit = async (values) => {
        // TODO plan data also added 
        //
        // plan_code, phone, email, data: { number of dependents, area}
        const newValues = cloneDeep(values)
        const { dependents, location, ...rest } = newValues
        const convertedValues = convertToAPIValues(rest)
        // add memebrs and location to data field
        convertedValues['data'] = {
            ...convertedValues['data'],
            dependents,
            location
        }
        // add country to post json
        convertedValues['country'] = countryData.country

        try{
            const resp = await saveQuoteMutation.mutateAsync(convertedValues);
            setQuoteData(resp)
            history.push('/preonb/apply', {from: location})
        } catch (error) {
            // depedent on error, 
            // country, phone, dependents, location, plan_code
            console.error('Error saving quote', error)
            return {
                ...error
            }
        }
    }

    const setCurrentStep = (step, dir) => {
        let url = `/preonb/quotes/${step}`
        if (dir == 'back'){
            history.replace(url, {from: location})
        } else {
            history.push(url, {from: location})
        }
    }

    // TODO: add vertical tabs for desktop

    if (isLoadingAppFeatures) {
        return (
            <Box >
                    <CenteredSpinner />
            </Box>
        )
    }
    return (
        <Box>
            <MemberFormTabs />
            <ContainerComponent>
                <Box py={['10px', 12]} pr={[0, '40px']} pt={['20px', 12]} minW={['100%', '150px']}>
                    <SubtitleText pageId={pageId} isPhoneEnabled={appFeatures?.features?.is_phone_enabled} />
                    <Box py={['20px']}>
                        <SubtitleDescription pageId={pageId} />
                    </Box>
                </Box>
            </ContainerComponent>
            <Form
                onSubmit={handleSubmit}
                initialValues={initValues}
                mutators={{ 
                    setFieldValue: ([field, value], state, { changeValue }) => {
                        changeValue(state, field, () => value)
                    }
                }}
                render = {({
                        submitting, handleSubmit, values, form
                    }) => {
                        return (
                            <Box as={'form'}  id={'quoteForm'} onSubmit={handleSubmit} mt={['10px', '0px']} >
                                <QuoteStepPhone 
                                    isShowing={isIndexPage(pageId)}
                                    setCurrentStep={setCurrentStep} 
                                    values={values}
                                />
                                <QuoteStepMembers 
                                    isShowing={pageId == STEP_PAGES.MEMBER_LIST} 
                                    setCurrentStep={setCurrentStep} 
                                    values={values}
                                />
                                <QuoteStepLocation 
                                    isShowing={pageId == STEP_PAGES.AREA}
                                    setCurrentStep={setCurrentStep} 
                                    values={values}
                                />
                                <QuoteStepPlanPricing 
                                    isShowing={pageId == STEP_PAGES.PLAN_LIST}
                                    setCurrentStep={setCurrentStep} 
                                    values={values}
                                    submitting={submitting}
                                    handleSubmit={handleSubmit}
                                />
                            </Box>
                        )

                    }
                } />
        </Box>
    )
}

export const GetQuotePage = () => { 
    
    return (
        <Box mt={['80px', '100px']} px={['20px', '50px']} pb={['50px', 0]}>
            <CountryModal />
            <HeadingContainer>
                    <FormattedMessage 
                        id={'membershipPage.getQuote.title'}
                        defaultMessage={'Get Quote'}
                    />
            </HeadingContainer>
            <QuotePaging />
        </Box>
    )
}
