import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';
import { useHistory, useLocation } from 'react-router-dom';
import { PAYMENT_STEP_PAGES as STEP_PAGES } from '../state/payment';
import { useApplyStore } from '../state/apply';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { MemberFormTabs } from '../components/MemberFormSteps';
import { PaymentStepPaymentMethod } from '../components/PaymentStepPaymentMethod';
import { PaymentStepReview } from '../components/PaymentStepReview';
import { useCountry } from 'src/_libs/useCountry';
import CenteredSpinner from 'src/_components/CenteredSpinner';
import { HeadingContainer } from '../components/common';
import storage from 'src/_helpers/storage';


const isIndexPage = (pageId=null) => {
    return [STEP_PAGES.PRIMARY, undefined, null, ''].includes(pageId)
}


const SubtitleText = ({pageId}) => {
    switch (pageId) {
        case STEP_PAGES.PRIMARY:
            return (
                <Text fontSize={18} fontFamily={'Proxima Nova Semibold'}>
                    <FormattedMessage 
                        id={'membershipPage.payment.init.subtitle'}
                        defaultMessage={'Select your payment method'}
                    />
                </Text>
            )
        case STEP_PAGES.BILLING_CYCLE: 
            return (
                <Text fontSize={18} fontFamily={'Proxima Nova Semibold'}>
                    <FormattedMessage 
                        id={'membershipPage.payment.billing_cycle.subtitle'}
                        defaultMessage={'Select how often you want to pay'}
                    />
                </Text>
            )
        case STEP_PAGES.REVIEW: 
            return (
                <Text fontSize={18} fontFamily={'Proxima Nova Semibold'}>
                    <FormattedMessage 
                        id={'membershipPage.payment.review.subtitle'}
                        defaultMessage={'Payment Summary'}
                    />
                </Text>
            )
        default:
            return (
                <Text fontSize={18} fontFamily={'Proxima Nova Semibold'}>
                    <FormattedMessage 
                        id={'membershipPage.payment.init.subtitle'}
                        defaultMessage={'Select your payment method'}
                    />
                </Text>
            )
    }
}

const SubtitleDescription = ({pageId}) => {
    const TEXT_PROPS = {
        fontSize: 18,
        fontFamily: 'Proxima Nova'
    }
    switch (pageId) {
        case STEP_PAGES.PRIMARY:
            return (
                <Text {...TEXT_PROPS} >
                    <FormattedMessage 
                        id={'membershipPage.payment.init.description'}
                        defaultMessage={''}
                    />
                </Text>
            )
        case STEP_PAGES.BILLING_CYCLE: 
            return (
                <Text {...TEXT_PROPS} >
                    <FormattedMessage 
                        id={'membershipPage.payment.billing_cycle.description'}
                        defaultMessage={''}
                    />
                </Text>
            )
        case STEP_PAGES.REVIEW: 
            return (
                <Text {...TEXT_PROPS} >
                    <FormattedMessage 
                        id={'membershipPage.payment.review.description'}
                        defaultMessage={''}
                    />
                </Text>
            )
        default:
            return (
                <Text {...TEXT_PROPS} >
                    <FormattedMessage 
                        id={'membershipPage.payment.init.description'}
                        defaultMessage={''}
                    />
                </Text>
            )
    }
}

const PaymentPaging = () => {
    const history = useHistory();
    const location = useLocation();
    const countryData = useCountry();
    const { pageId } = useParams();
    const [quoteData, setQuoteData] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [ paymentUrl, setPaymentUrl ] = React.useState(null);

    React.useEffect(() => {
        const fetchQuoteData = async () => {
            setLoading(true);
            const data = await useApplyStore.getState().quoteData;
            setQuoteData(data);
            setLoading(false);
        };

        fetchQuoteData();
    }, []);

    const setCurrentStep = (step, dir) => {
        let url = `/preonb/payment/${step}`
        if (dir == 'back'){
            history.replace(url, {from: location})
        } else {
            history.push(url, {from: location})
        }
    }

    const handlePaymentClick = (url) => {
        // set register email so user does not have to fill it out again
        storage.set('register_email', quoteData.email);
        let pageUrl = `/preonb/payment/${STEP_PAGES.REVIEW}`
        setPaymentUrl(url);
        history.push(pageUrl, {from: location})
    }

    if (loading) {
        return (
            <Box>
                <CenteredSpinner />
            </Box>
        )
    }

    return (
        <Box>
            <MemberFormTabs />
            <Box py={['10px', '20px']} pr={[0, '40px']} pt={['20px']} minW={['100%', '150px']}>
                <SubtitleText pageId={pageId} />
                <Box py={['20px']}>
                    {/*<SubtitleDescription pageId={pageId} />*/}
                </Box>
                <Box>
                    <PaymentStepPaymentMethod 
                        isShowing={isIndexPage(pageId)}
                        country={countryData.country}
                        setPaymentUrl={handlePaymentClick}
                    />
                    <PaymentStepReview
                        isShowing={pageId == STEP_PAGES.REVIEW}
                        paymentUrl={paymentUrl}
                        quoteData={quoteData}
                        setCurrentStep={setCurrentStep}
                    />
                </Box>
            </Box>
        </Box>
    )
}

export const PaymentPages = () => { 
    
    return (
        <Box mt={['80px', '100px']} px={['20px', '50px']} pb={['50px', 0]}>
            <HeadingContainer >
                <FormattedMessage 
                    id={'membershipPage.payment.title'}
                    defaultMessage={'Payment'}
                />
            </HeadingContainer>
            <PaymentPaging />
        </Box>
    )
}
