import { useMutation } from 'react-query';

import { requestEmailCode as save } from './services';

import { QUERY_KEY_NAMES } from './constants';

const saveQuery = async ({ data }) => {
    return save(data);
}


export const useRequestEmailCode = () => {
    return  useMutation({
        mutationKey: QUERY_KEY_NAMES.REGISTER_PASSWORD_REQUEST_CODE_EMAIL,
        mutationFn: (data) => {        
            return saveQuery({ data });
        },
    });

}