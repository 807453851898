import { API_URLS } from '../_config';
import { authHeader, handleResponse } from '../_helpers';

export const services = {
  fetchCountries,
  fetchCountryRegions,
  fetchCustomerPortal,
  fetchFavoriteDentist,
  deleteFavoriteDentist,
  fetchRelationships,
  fetchSocialApps,
  fetchBulkReports,
  fetchSelectOptions,
  fetchAppFeatures
}

function fetchAppFeatures(appName, country){
  const requestUrl = API_URLS.appFeatures;
  const requestOptions = {
      method: requestUrl.method,
      headers: { 'Content-Type': 'application/json'},
  };
  const url = requestUrl.url.replace("{appName}", appName).replace("{country}", country);
  return fetch(url, requestOptions).then(handleResponse);
}

function fetchSelectOptions(option){
  const requestUrl = API_URLS.selectOptions;
  const requestOptions = {
      method: requestUrl.method,
      headers: { 'Content-Type': 'application/json'},
  };
  const url = requestUrl.url + option;
  return fetch(url, requestOptions).then(handleResponse);
}

function fetchBulkReports(queryObject){
  const requestUrl = API_URLS.getBulkReports;
  const requestOptions = {
      method: requestUrl.method,
      headers: { 'Content-Type': 'application/json', ...authHeader()},
  };
  const query_string = new URLSearchParams(queryObject).toString();
  const url = requestUrl.url + "?" +  query_string;
  return fetch(url, requestOptions).then(handleResponse);
}

function fetchFavoriteDentist(){
  const requestUrl = API_URLS.getFavoriteDentist;
  const requestOptions = {
      method: requestUrl.method,
      headers: { 'Content-Type': 'application/json', ...authHeader()},
  };
  const url = requestUrl.url;
  return fetch(url, requestOptions).then(handleResponse);
}

function deleteFavoriteDentist(favorite_id, data){
  const requestUrl = API_URLS.removeFavoriteDentist;
  const requestOptions = {
      method: requestUrl.method,
      headers: { 'Content-Type': 'application/json', ...authHeader()},
      body: JSON.stringify(data)
  };
  const url = requestUrl.url.replace("{favorite_id}", favorite_id);
  return fetch(url, requestOptions).then(handleResponse);
}

function fetchRelationships(){
  const requestUrl = API_URLS.selectOptions;
  const requestOptions = {
      method: requestUrl.method,
      headers: { 'Content-Type': 'application/json'},
  };
  const url = requestUrl.url + 'relationships';
  return fetch(url, requestOptions).then(handleResponse);
}

function fetchCustomerPortal(data){
  const requestUrl = API_URLS.getStripePortal;
  const requestOptions = {
      method: requestUrl.method,
      headers: { 'Content-Type': 'application/json', ...authHeader()},
      body: JSON.stringify(data)
  };
  const url = requestUrl.url;
  return fetch(url, requestOptions).then(handleResponse);
}

function fetchCountries(){
  const requestUrl = API_URLS.countries;
  const requestOptions = {
      method: requestUrl.method,
      headers: { 'Content-Type': 'application/json'},
  };
  const url = requestUrl.url;
  return fetch(url, requestOptions).then(handleResponse);
}

function fetchCountryRegions(country){
  const requestUrl = API_URLS.regions;
  const requestOptions = {
      method: requestUrl.method,
      headers: { 'Content-Type': 'application/json'},
  };
  const url = requestUrl.url.replace("{country}", country);
  return fetch(url, requestOptions).then(handleResponse);
}

function fetchSocialApps(country){
  const requestUrl = API_URLS.socialApps;
  const requestOptions = {
      method: requestUrl.method,
      headers: { 'Content-Type': 'application/json'},
  };
  const url = requestUrl.url.replace("{country}", country);
  return fetch(url, requestOptions).then(handleResponse);
}

function fetchCards(){
  const requestUrl = API_URLS.fetchOmisePayments;
  const requestOptions = {
      method: requestUrl.method,
      headers: { 'Content-Type': 'application/json', ...authHeader()},
  };
  const url = requestUrl.url;
  return fetch(url, requestOptions).then(handleResponse);

}
